<!-- IDCW History Table-->
<div class="table-responsive text-center">
  <table class="table table-success table-striped stiky-head">
    <thead>
      <tr>
        <th scope="col">{{ "products.distributions-plan" | translate }}</th>
        <th scope="col">{{ "products.distributions-incm-dist-record-date" | translate }}</th>
        <th scope="col">{{ "products.distributions-incm-div-per-unit" | translate }} (₹)</th>
        <th scope="col">{{ "products.distributions-record-nav" | translate }} (₹)</th>
        <th scope="col">{{ "products.distributions-incm-ex-div-date" | translate }}</th>
        <th scope="col">{{ "products.distributions-incm-ex-div-nav" | translate }} (₹)</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr *ngFor="let rtDt of distributionTableData"> -->
      <tr *ngFor="let rtDt of distributionTableData | slice
              : (page - 1) * pageSize
              : (page - 1) * pageSize + pageSize">
        <td>{{ rtDt?.planName }}</td>
        <td>{{ rtDt?.incomeRecordDate }}</td>
        <td>{{ rtDt?.incomeDistributionAmount }}</td>
        <td>{{ rtDt?.recordDateNavVal }}</td>
        <td>{{ rtDt?.incomeExDividendDate }}</td>
        <td>{{ rtDt?.exDividendNavValue }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-pagination">
  <ngb-pagination
    class="ft-pagination margin-top"
    *ngIf="pageSize < distributionTableData?.length"
    [(page)]="page"
    [pageSize]="pageSize"
    [collectionSize]="distributionTableData?.length"
    [maxSize]="maxSize"
    [ellipses]="false"
  >
    <ng-template ngbPaginationPrevious>
      <i class="bi bi-arrow-left me-sm-1"></i> <span class="hide-in-mobile">Previous</span>
    </ng-template>
    <ng-template ngbPaginationNext
      ><span class="hide-in-mobile">Next</span> <i class="bi bi-arrow-right ms-sm-1"></i>
    </ng-template>
  </ngb-pagination>
</div>
<!-- IDCW History tab end here -->


<div *ngIf="printable" style="visibility: hidden; height: 0px">
  <table class="dividend-{{printable ? 'printTable' : ''}} table table-hover table-striped"
    id="dividend-printTable" border="1" cellpadding="15" cellspacing="0"
    bordercolor="#f7bc00" width="100%" style="font-size: 0.688rem; margin-top: 100px">
    <thead>
      <tr>
        <th scope="col">{{ "products.distributions-plan" | translate }}</th>
        <th scope="col">{{ "products.distributions-incm-dist-record-date" | translate }}</th>
        <th scope="col">{{ "products.distributions-incm-div-per-unit" | translate }} (₹)</th>
        <th scope="col">{{ "products.distributions-record-nav" | translate }} (₹)</th>
        <th scope="col">{{ "products.distributions-incm-ex-div-date" | translate }}</th>
        <th scope="col">{{ "products.distributions-incm-ex-div-nav" | translate }} (₹)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor=" let rtDt of distributionTableData">
        <td>{{ rtDt?.planName }}</td>
        <td>{{ rtDt?.incomeRecordDate }}</td>
        <td>{{ rtDt?.incomeDistributionAmount }}</td>
        <td>{{ rtDt?.recordDateNavVal }}</td>
        <td>{{ rtDt?.incomeExDividendDate }}</td>
        <td>{{ rtDt?.exDividendNavValue }}</td>
      </tr>
    </tbody>
  </table>
</div>
