import {
  DistributionState,
  PricingState,
  ToggleOptionWithPeriod,
} from '@types';

export const dateRangeToggleInitialValues: Map<
  string,
  ToggleOptionWithPeriod
> = new Map([
  [
    'ONE_MONTH',
    {
      active: true,
      value: 'ONE_MONTH',
      text: 'products.price-toggle-1-month',
      period: {
        key: 'months',
        value: 1,
      },
    },
  ],
  [
    'TWO_MONTHS',
    {
      active: true,
      value: 'TWO_MONTHS',
      text: 'products.price-toggle-2-month',
      period: {
        key: 'months',
        value: 2,
      },
    },
  ],
  [
    'THREE_MONTHS',
    {
      active: false,
      value: 'THREE_MONTHS',
      text: 'products.price-toggle-3-month',
      period: {
        key: 'months',
        value: 3,
      },
    },
  ],
  [
    'FOUR_MONTHS',
    {
      active: false,
      value: 'FOUR_MONTHS',
      text: 'products.price-toggle-4-month',
      period: {
        key: 'months',
        value: 4,
      },
    },
  ],
  [
    'CUSTOM_RANGE',
    {
      active: false,
      value: 'CUSTOM_RANGE',
      text: 'products.price-toggle-custom-range',
    },
  ],
]);

export const PRICING_INITIAL_STATE: PricingState = {
  fundId: null,
  fundName: null,
  fundInceptionDate: null,
  fundInceptionDateStd: null,
  currentDate: null,
  currentDateStd: null,
  fundShareClassData: [],
  tableConfig: {
    dateRangeEnum: dateRangeToggleInitialValues,
    selectedToggleRange: 'ONE_MONTH',
    customRange: {
      fromDateStd: null,
      fromDate: null,
      toDateStd: null,
      toDate: null,
    },
  },
  selectedShareClassData: null,
};

export const DISTRIBUTION_INITIAL_STATE: DistributionState = {
  fundName: null,
  siteConfig: null,
  latestData: [],
  historicalData: [],
  isHistoricalDataEmpty: false,
  fundInceptionDate: null,
  navAsOfdate: null,
  planList: [],
  hasLoadedHistorical: false,
};
