import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { OtherFundDetailsDisplayComponent } from "@products/other-details/other-fund-details-display-component.interface";
import { OtherFundDetailsComponentConfig } from "@products/other-details/other-fund-details-component.config";
import { Subscription, combineLatest } from "rxjs";
import { Logger } from "@utils/logger";
import { BaseProductDetailComponent } from "@products/base-product-detail.component";
import { Page, Component as BrComponent } from "@bloomreach/spa-sdk";
import {
  DropdownOption,
  PricingState,
  DistributionState,
  FundHeaderState,
  FundInformationNav,
  FundInformationOverview,
  FundInformationState,
  ProductDetailConfiguration,
  PricingStateShareClassInfo,
  ToggleOptionWithPeriod,
  PricingStateHighLowNavData,
  PricingStateSelectedTableData,
  PricingStateSelectedDataDateRange,
} from "@types";
import moment from "moment";
import { FundInformationService } from "@components/products/overview/services/fund-information.service";
import { FundDocument } from "@components/products/types/fund-document.type";
import { SiteConfigService } from "@services/site-config.service";
import { FundHeaderService } from "@components/products/overview/services/fund-header.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PricingService } from "@components/products/services/pricing.service";
import { sortShareClasses } from "@components/products/utils/shareclass-sorter";
import { TranslateService } from "@shared/translate/translate.service";
import { DistributionService } from "@components/products/pricing-distribution/services/distribution.service";
import { EnvConfigService } from "@services/env-config.service";
import { symbols } from "@utils/il8n/currency";
import cloneDeep from "lodash/cloneDeep";
import {
  API_DATE_FORMAT,
} from "@components/products/utils/constants/product.constants";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

const logger = Logger.getLogger("FundNavModalComponent");

@Component({
  selector: "ft-fund-nav-modal",
  templateUrl: "./fund-nav-modal.component.html",
  styleUrls: ["./fund-nav-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundNavModalComponent implements  OnDestroy, OnInit {
  INR_SYMBOL = symbols.get("INR");
  // pagination helpers

  /**
   * current page number of table
   */
  currentPageNumber = 1;

  /**
   * Number of rows displayed per page
   */
  pageSize = 10;

  /**
   * Total number pagination links available eg. (1 2 3 4 .... 7).
   */
  maxSize = 7;

  // Class variables.
  dataSubscription: Subscription;
  overview: FundInformationOverview;
  nav: FundInformationNav;
  isLoaded: boolean;
  fundDocument: FundDocument;
  isSegregated: boolean;
  isNfo: boolean;
  showTE: boolean;
  showTD: boolean;
  showLaunchData: boolean;
  TEData: Array<object> = [];
  TDData: Array<object> = [];
  LaunchData: Array<object> = [];
  TEAsofDate: string;
  TDAsofDate: string;
  shareClassArr: Array<string> = [""];
  isMobileView: boolean = false;

  minimalInvestment: string;
  additionalInvestment: string;
  hasProductLabel = false;

  pricingDataSubscription: Subscription[] = [];
  dailyNavDetailsData: any = [];
  navDate: any = "12/06/2023";
  historyDateForm: FormGroup = new FormGroup({});
  isHistoricalDataAvailable = false;
  fundPlans: DropdownOption[];
  timePeriods: DropdownOption[];
  fundShareClassData: PricingStateShareClassInfo[] = [];
  currentDateStd: string;
  fundInceptionDate: string;
  dateFormatForComponent = "DD/MM/YYYY";
  fundInceptionText: string;
  isCustomDateRangeSelected = false;
  invalidCalendarInputMessage: string = null;
  navAlertURL = "";
  isDailyNavHistoricalLoaded: boolean;
  fundName: string;
  selectedFundShareClassData: PricingStateShareClassInfo;
  selectedHighLowNavData: PricingStateHighLowNavData;
  selectedDataDateRange: PricingStateSelectedDataDateRange;
  pricingTableData: PricingStateSelectedTableData[];
  private accountsBaseUrl = "";
  noDataForSelectedPeriod: string;
  @Input() brConfig: any
  
  constructor(
    private fundInformationService: FundInformationService,
    private siteConfig: SiteConfigService,
    private cdr: ChangeDetectorRef,
    private fundHeaderService: FundHeaderService,
    private pricingService: PricingService,
    private distributionService: DistributionService,
    private translateService: TranslateService,
    private envConfig: EnvConfigService,
    private _fb: FormBuilder) {
    this.initForm();
  }

  ngOnInit(): void {
    if (window.innerWidth < 700) {
      this.isMobileView = true;
    }
    this.accountsBaseUrl = this.envConfig.getEnvConfig()?.ftiAccountsUrl;
    this.populate();
  }

  initForm = () => {
    this.historyDateForm = this._fb.group({
      historyDate: ["", Validators.required],
      selectedPlan: ["", Validators.required],
      selectedToDateStd: [""],
      selectedFromDateStd: [""],
    });
  };

  submitHistoricalNAVTable() {
    this.isHistoricalDataAvailable = true;
    if(this.isCustomDateRangeSelected){
      this.validateHistoryNavIDCWDates(
        this.historyDateForm?.value?.selectedFromDateStd,
        this.historyDateForm?.value?.selectedToDateStd
      );
    }
    
    // Extract selected share class data against selected plan.
    this.selectedFundShareClassData = this.fundShareClassData.find(
      (shareClassData) =>
        shareClassData.shareClassCode ===
        this.historyDateForm.controls["selectedPlan"].value
    );

    // Finally, call service and fetch data.
    this.pricingService.fetchHistoricalRecords(
        this.selectedFundShareClassData,
        this.historyDateForm.controls["historyDate"].value,
        this.historyDateForm.controls["selectedFromDateStd"].value
          ? moment(
              this.historyDateForm.controls["selectedFromDateStd"].value,
              this.dateFormatForComponent
            ).format(API_DATE_FORMAT)
          : this.historyDateForm.controls["selectedFromDateStd"].value,
        this.historyDateForm.controls["selectedToDateStd"].value
          ? moment(
              this.historyDateForm.controls["selectedToDateStd"].value,
              this.dateFormatForComponent
            ).format(API_DATE_FORMAT)
          : this.historyDateForm.controls["selectedToDateStd"].value
      );
  }

  validateHistoryNavIDCWDates(selectedFromDateStd, selectedToDateStd) {
    const dateFormat = "DD/MM/yyyy";
    if (
      // Check 1: 'selectedFromDateStd' should be less than or equal to Inception date.
      moment(selectedFromDateStd, dateFormat) <
      moment(this.fundInceptionDate, dateFormat)
    ) {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.pricing-invalid-message-from-date-greater-than-or-equal-to-fund-inception-date`
      );
      return;
    } else if (
      // Check 2: 'selectedFromDateStd' shouldn't be greater than 'selectedToDateStd'.
      moment(selectedFromDateStd, dateFormat) >
      moment(selectedToDateStd, dateFormat)
    ) {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.pricing-invalid-message-from-date-less-than-or-equal-to-date`
      );
      return;
    } else if (
      // Check 2: 'selectedFromDateStd' shouldn't be greater than 'selectedToDateStd'.
      moment(selectedToDateStd, dateFormat) <
      moment(selectedFromDateStd, dateFormat)
    ) {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `pricing-invalid-message-to-date-greater-than-or-equal-from-date`
      );
      return;
    } else if (selectedFromDateStd == "" || selectedToDateStd == "") {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.distribution-invalid-message-from-date-to-date-na`
      );
      return;
    } else {
      this.invalidCalendarInputMessage = null;
    }
  }
  
  populate(): void {
    this.cdr.detectChanges();

    if (this.brConfig && this.brConfig?.fundId) {
      this.isSegregated = this.siteConfig.isSegregatedFund(
        this.brConfig.fundId
      );
      this.isNfo = this.siteConfig.isNfoFund(this.brConfig.fundId);
      this.fundHeaderService.populate(this.brConfig);
      this.fundInformationService.populate(this.brConfig);
      this.distributionService.populate(this.brConfig);
      this.dataSubscription = combineLatest([
        this.fundHeaderService.getFundHeaderState$(),
        this.fundInformationService.getFundInformationState$(),
        this.distributionService.distributionState,
        this.pricingService.getPricingState$(),
      ]).subscribe(
        (data: any) => {
          let fundInformationState: FundInformationState;
          let fhState: FundHeaderState;
          let distService: DistributionState;
          let pricingService: PricingState;
          [fhState, fundInformationState, distService, pricingService] = data;
          this.isLoaded = fundInformationState.isLoaded;
          this.minimalInvestment = fhState.minimumInvestment;
          this.additionalInvestment = fhState.additionalInvestment;

          if (this.isLoaded) {
            this.overview = fundInformationState.overview;
            this.nav = fundInformationState.nav;
            this.navDate = this.nav.navDate;
            this.dailyNavDetailsData = this.nav?.shareClasses;
            this.historicalMapState(pricingService);

            // Condition when, pricing historical data isn't available.
            if (!this.isDailyNavHistoricalLoaded) {
              this.isDailyNavHistoricalLoaded = false;
            }

          }

          this.cdr.detectChanges();
        },
        (error) => {
          logger.error(`Error in invoking fund information service: ${error}`);

          this.isLoaded = false;
          this.cdr.detectChanges();
        }
      );
     // this.fundInformationService.populate(brConfig);
      //this.fundHeaderService.populate(brConfig);
      this.distributionService.populate(this.brConfig);
      this.pricingService.populate(this.brConfig);
    }
  }

  private historicalMapState(state: PricingState): void {
    const dateFormat = "DD/MM/YYYY";
    if (state.hasLoadedFullFeed) {
      // Assign properties only on initial load.
      if (!this.isDailyNavHistoricalLoaded) {
        this.fundName = state.fundName;
        this.fundShareClassData = state.fundShareClassData;
        this.fundShareClassData =
          this.fundShareClassData.sort(sortShareClasses);
        this.selectedFundShareClassData = this.fundShareClassData[0];
        this.fundPlans = this.constructFundPlansDropdownOptions(
          this.fundShareClassData
        );
        // Set default fund plan as 'Growth'
        this.historyDateForm
          .get("selectedPlan")
          ?.setValue(this.fundPlans[0]?.value);
        this.timePeriods = this.constructPriceRangeDropdownOptions(
          state.tableConfig.dateRangeEnum
        );
        // Set default time period as 'Last 30 Days'
        this.historyDateForm
          .get("historyDate")
          ?.setValue(this.timePeriods[0]?.value);
        this.currentDateStd = state.currentDateStd;
        this.fundInceptionDate = state.fundInceptionDate;
        this.fundInceptionText = this.translateService.instant(
          `products.fund-inception-date`
        );
        this.navAlertURL =
          this.accountsBaseUrl +
          this.translateService.instant(`products.nav-alerts-link`);
      }

     // Assign historical properties when there is a fetch call for the same.
      if (state.selectedData) {
        this.selectedHighLowNavData = cloneDeep(state.selectedHighLowNavData);
        this.selectedDataDateRange = cloneDeep(state.selectedDataDateRange);
        this.selectedDataDateRange.startDate = moment(
          state.selectedDataDateRange.startDate
        ).format(dateFormat);
        this.selectedDataDateRange.endDate = moment(
          state.selectedDataDateRange.endDate
        ).format(dateFormat);
          
        
          if(this.invalidCalendarInputMessage !== null){
            this.isHistoricalDataAvailable = false;
          }

          if(state.selectedData.length === 0 && this.invalidCalendarInputMessage === null){
            this.noDataForSelectedPeriod = this.translateService.instant(
              `products.no-data-distribution-historical-filter`
            );
          }else{
            this.noDataForSelectedPeriod = null;
          }
      }
      if (state?.pricingTableData) {
        this.pricingTableData = state?.pricingTableData;
      }
      this.currentPageNumber = 1;
      this.isDailyNavHistoricalLoaded = true;
      this.cdr.detectChanges();
    }
  }

  /**
   * Trigger on set method of date
   * @param event output event
   * @param dateField string
   */
  onFinalDate(selectedDate: string, dateField: string): void {
    if (dateField === "fromDate") {
      this.historyDateForm.get("selectedFromDateStd")?.setValue(selectedDate);
    } else {
      this.historyDateForm.get("selectedToDateStd")?.setValue(selectedDate);
    }
    this.cdr.detectChanges();
  }

  /**
   * Perform action when time period option is changed.
   */
  onTimePeriodChange(): void {
    if (this.historyDateForm.value.historyDate === "CUSTOM_RANGE") {
      this.isCustomDateRangeSelected = true;
    } else {
      this.isCustomDateRangeSelected = false;
    }
    this.invalidCalendarInputMessage = null
  }

  /**
   * Construct fund plans (share class names) dropdown options with static values.
   * @param dateRangeEnum full date range map
   */
  private constructFundPlansDropdownOptions(
    fundShareClassData: PricingStateShareClassInfo[]
  ): DropdownOption[] {
    return fundShareClassData.map((shareClass) => ({
      value: shareClass.shareClassCode,
      label: shareClass.shareClassName,
    }));
  }

  /**
   * Construct price date range dropdown options with static values.
   * @param dateRangeEnum full date range map
   */
  private constructPriceRangeDropdownOptions(
    dateRangeEnum: Map<string, ToggleOptionWithPeriod>
  ): DropdownOption[] {
    return Array.from(dateRangeEnum.values()).map(
      (dropdownOption: ToggleOptionWithPeriod) => ({
        value: dropdownOption.value,
        label: this.translateService.instant(dropdownOption.text),
        link: "#",
      })
    );
  }

  /**
   * Return the value to the appropriate box after using the return function to determine,
   * if the daily NAVs data is for a simple growth plan or a direct growth plan.
   * @param val
   * @returns
   */
  getDailyNavMenu(val: any) {
    let menuarrayvalue = [];
    let substring = "Direct";

    if (this.dailyNavDetailsData.length > 0) {
      if (val === substring) {
        for (let i = 0; i <= this.dailyNavDetailsData.length; i++) {
          if (this.dailyNavDetailsData[i] !== undefined) {
            let vanString = this.dailyNavDetailsData[i].name;
            if (vanString.includes(substring) === true) {
              let setVal = this.dailyNavDetailsData[i];
              menuarrayvalue.push(setVal);
            }
          }
        }
        return menuarrayvalue;
      } else {
        for (let i = 0; i <= this.dailyNavDetailsData.length; i++) {
          if (this.dailyNavDetailsData[i] !== undefined) {
            let vanString = this.dailyNavDetailsData[i].name;
            if (vanString.includes(substring) === false) {
              let setVal = this.dailyNavDetailsData[i];
              menuarrayvalue.push(setVal);
            }
          }
        }
        return menuarrayvalue;
      }
    }
  }

  /**
   * Perform download action when button is clicked for NAV Table records.
   */

  onDownloadNAVTable(): void {
    this.pricingService.triggerDownloadAction();
  }

  

  ngOnDestroy(): void {
    // Clean up subscription to avoid memory leaks.
    this.dataSubscription?.unsubscribe();
    this.isHistoricalDataAvailable = true;
  }

  @Output() closeButton = new EventEmitter<boolean>();
  closeModel(){
    this.closeButton.emit(false);
  }

}
