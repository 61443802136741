import { Injectable } from '@angular/core';
import { FundDetailService } from '@components/products/services/fund-detail.service';
import { FundInformationState, ProductDetailConfiguration } from '@types';
import { forkJoin, Observable, Subject } from 'rxjs';
import FundInformationQuery from '@graphql/overview/fund-information.graphql';
import FundPortfolioQuery from '@graphql/overview/fund-portfolio.graphql';
import { Logger } from '@utils/logger';
import { BMAssoc, Product, ShareClass } from '@components/products/models';
import { FundPortfolioService } from '@components/products/services/fund-portfolio.service';
import { sortShareClasses } from '@components/products/utils/shareclass-sorter';
import { FundPortfolioManagersService } from '@components/products/services/fund-portfolio-managers.service';

const logger = Logger.getLogger('FundInformationService');

const FUND_PORTFOLIO_TYPE = {
  yeildToMaturityWgtAvg: 'YIELD_TO_MATURITY_WGT_AVG',
  portfolioTurnoverRation: 'PORTFOLIO_TURNOVER_RATIO_12M',
  totalNetAssets: 'TOTAL_NET_ASSETS',
  durtnOadWgtAvg: 'DURTN_OAD_WGT_AVG',
  maturityWgtAvg: 'MATURITY_WGT_AVG',
};

@Injectable({
  providedIn: 'root',
})
export class FundInformationService {
  private state: FundInformationState;
  private fundInformationState$: Subject<FundInformationState>;

  constructor(
    private fundDetailService: FundDetailService,
    private fundPortfolioService: FundPortfolioService,
    private fundPortfolioManagerService: FundPortfolioManagersService
  ) {
    this.state = {
      overview: null,
      nav: null,
      additionalInfo: null,
    };
    this.fundInformationState$ = new Subject();
  }

  /**
   * Return fund information state as observable.
   */
  getFundInformationState$(): Observable<FundInformationState> {
    return this.fundInformationState$.asObservable();
  }

  /**
   * Fetch the fund information from PDS and initialize state.
   * @param productDetailConfiguration product detail configuration from bloomreach
   */
  populate(productDetailConfiguration: ProductDetailConfiguration): void {
    forkJoin([
      this.fundDetailService.register(FundInformationQuery, {
        fundId: productDetailConfiguration?.fundId,
      }),
      this.fundPortfolioService.register(FundPortfolioQuery, {
        fundId: productDetailConfiguration?.fundId,
      }),
      // this.fundPortfolioManagerService.register(FundPortfolioManagerQuery, {
      //   empId: "150465",//productDetailConfiguration.empId,
      // }),
    ]).subscribe(
      (mergedData: [Product, Product]) => {
        logger.debug(
          `Mapped fund information & portfolio response: ${mergedData}`
        );

        const [fundDetail, fundPortfolio] = mergedData;

        this.state.overview = {
          inceptionDate: fundDetail.fundInceptionDate,
        };

        this.state.nav = {
          currencyCode: fundDetail.currencyCode,
          navDate: fundDetail.shareClasses[0]?.nav?.navAsOfDate,
          shareClasses: fundDetail.shareClasses
            .map((shareClass) => ({
              name: shareClass?.shareClassName,
              nav: shareClass?.nav?.navValue,
              icradataservice: shareClass?.icradataservice,
              inceptionDate: shareClass?.inceptionDate,
              inceptionDateStd: shareClass?.inceptionDateStd,
            }))
            .sort(sortShareClasses),
        };

        const primaryShareClass: ShareClass = fundDetail.shareClasses.find(
          (shareClass) => shareClass.primaryShareClass
        );

        const bmAssocList: BMAssoc[] = primaryShareClass?.bmassoc;

        // fund-size,portfolio-turn-over,weighted-avg-maturity,modified-duration,yield-to-maturity,standard-deviation,
        // beta,sharpe-ratio,r-squared-ratio,risk-free-rate,expense-ratio,expense-ratio-direct,benchmark,fund-manager
        this.state.additionalInfo = {
          fundInfoDate: fundPortfolio.portfolio.portfoliochars[0]?.asOfDate,
          beta: primaryShareClass?.riskStatistics?.beta,
          rSquaredRatio: primaryShareClass?.riskStatistics?.rSquared,
          sharpeRatio: primaryShareClass?.riskStatistics?.sharpeRatio,
          standardDeviation:
            primaryShareClass?.riskStatistics?.standardDeviation,
          riskFreeRate: primaryShareClass?.riskStatistics?.riskFreeRate,
          expenseRatio: primaryShareClass?.charges?.expenseRatioGross,
          expenseRatioDirect: primaryShareClass?.charges?.expenseRatioNet,
          benchmarks: [
            bmAssocList?.find((bmAssoc) => bmAssoc.bmLabel === 'INST1')?.bmName, // Currently, only displaying 'INST1'.
          ].filter((bm) => bm !== undefined),
          fundManagers: fundDetail.investmentTeam
            .sort((first, second) => first.managerOrder - second.managerOrder)
            .map((team) => ({
              name: team.managerName,
              startDate: team.startDateFundManagement,
              order: team.managerOrder,
              empid: team.employeeId,
            }))
        };
        fundPortfolio.portfolio.portfoliochars.forEach((char) => {
          switch (char.elementName) {
            case FUND_PORTFOLIO_TYPE.maturityWgtAvg:
              this.state.additionalInfo.weightedAverageMaturity = char.elementValueLocal.replace(
                'Yrs',
                'Years'
              );
              break;
            case FUND_PORTFOLIO_TYPE.yeildToMaturityWgtAvg:
              this.state.additionalInfo.yieldToMaturity =
                char.elementValueLocal;
              break;
            case FUND_PORTFOLIO_TYPE.portfolioTurnoverRation:
              this.state.additionalInfo.portfolioTurnover =
                char.elementValueLocal;
              break;
            case FUND_PORTFOLIO_TYPE.totalNetAssets:
              this.state.additionalInfo.fundSize = char.elementValueLocal;
              break;
            case FUND_PORTFOLIO_TYPE.durtnOadWgtAvg:
              this.state.additionalInfo.modifiedDuration = char.elementValueLocal.replace(
                'Yrs',
                'Years'
              );
              break;
            default: // ignore MATURITY_WGT_AVG
          }
        });

        this.state.isLoaded = true;
        this.fundInformationState$.next(this.state);
      },
      (error) => {
        logger.error(`Error in fetching fund information: ${error}`);

        this.state.isLoaded = false;
        this.fundInformationState$.error(error);
      }
    );
  }
}
