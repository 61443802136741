<div class="modal-header">
  <h1 class="modal-title fs-5" id="IDCWModalLabel"> {{ "products.check-IDCW-history" | translate }} </h1>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModel()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="historyDateForm" class="modal-form">
    <div class="row gx-3">
      <div class="col-md col-sm-4 col-12 mb-3">
        <select class="form-select" formControlName="selectedPlan" aria-label="Default select example">
          <option *ngFor="let plan of planList; let i = index" [attr.value]="plan.value">{{plan.label}}</option>
        </select>
      </div>
      <div class="col-md col-sm-4 col-6 mb-3">
        <div class="date-group">
          <input aria-label="selectdate" class="form-control date-field" type="text" placeholder="From"
            formControlName="selectedFromDateStd" />
          <ft-date-picker [displayDate]="currentDateStd" [fundInception]="fundInceptionDate"
            [convertToFormat]="dateFormatForComponent" [sentDateFormat]="'YYYY-MM-DD'"
            [fundInceptionText]="fundInceptionText" (finalDate)="onFinalDate($event, 'fromDate')"
            [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
        </div>
      </div>
      <div class="col-md col-sm-4 col-6 mb-3">
        <div class="date-group">
          <input aria-label="selectdate" class="form-control date-field" type="text" placeholder="To Date"
            formControlName="selectedToDateStd" />
          <ft-date-picker [displayDate]="currentDateStd" [convertToFormat]="dateFormatForComponent"
            [sentDateFormat]="'YYYY-MM-DD'" (finalDate)="onFinalDate($event, 'toDate')"
            [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
        </div>
      </div>
      <div class="col-md-auto col-12 mb-3">
        <button type="button" class="btn btn-primary submit-btn" (click)="toggleIDCWTable()">{{
          "products.pricing-submit-button" | translate }}</button>
      </div>
      <!-- Invalid calendar input message -->
      <div *ngIf="invalidCalendarInputMessage" class="invalid-message">
        <span>{{ invalidCalendarInputMessage }}</span>
      </div>
    </div>
  </form>
  <div class="table-box" *ngIf="showIDCWTable">
    <div id="print-fund-details">
      <div class="title py-3">{{ "products.Selected-Fund-Details" | translate }}</div>
      <h4>{{ fundName }}<span>{{ selectedPlan }}</span></h4>
      <ul class="mb-4">
        <li>{{ "products.distributions-inception-date" | translate }} : {{ fundInceptionDate }} </li>
        <li>{{ "products.distributions-fund-base-currency" | translate }} :
          {{ distStateCurrencyCode }} (₹)</li>
        <li>{{ "products.distributions-dividend-history" | translate }}:
          {{ startDate }} {{ "products.to" | translate }} {{ endDate }} </li>
      </ul>
    </div>
    <div class="table-part mb-4">

      <div class="d-flex justify-content-between align-items-center py-4">
        <div class="text-bold">{{ "products.Distribution-Viewing-Data" | translate }}
          {{ "products.from" | translate }}
          {{ startDate }} {{ "products.to" | translate }} {{ endDate }} </div>
        <div>
          <button class="btn btn-transparent me-3" (click)="printHistory()"><img
              src="assets/images/fund/print.svg"></button>
          <a href="javascript:void(0);" (click)="downloadHistory()"><img alt="image" src="assets/images/fund/download.svg"></a>
        </div>
      </div>
      <ft-idcw-history-table [distributionTableData]="historicalDistributionList"
        [printable]="true"></ft-idcw-history-table>
    </div>
  </div>
  <div class="text-small" [innerHTML]="'products.idcw-history-caveat' | translate"></div>
</div>
