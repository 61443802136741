import { Component, Inject, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk/';
import { DebugService } from '@services/debug.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@shared/translate/translate.service';
import { ProfileService } from '@services/profile.service';
import {
  BypassRole,
  IUserProfile,
  IUserProfileInfo,
  LoginSource,
  ProfileSummary,
} from '@services/profile.interface';
import { StorageService } from '@services/storage.service';
import { SegmentId } from '@types';
import { ProfileConfig } from '@services/profile.config';
import { Logger } from '@utils/logger';
import { SegmentService } from '@services/segment.service';

const logger = Logger.getLogger('DebugComponent');

const CTELLER: ProfileConfig = {
  name: 'Cteller',
  isLoggedIn: true,
  isIdentified: true,
  bypassRoles: [BypassRole.FP],
  profileInfo: {
    userId: 'cteller',
    role: 'FP',
    webExperience: 'FA',
    displayName: 'CATHLEEN TELLER',
    firm: '',
    addressLine1: '532 EASTERN BLVD STE 203',
    city: 'BALTIMORE',
    state: 'MD',
    zip: '212216738',
    phoneNumber: '410-686-7300',
    email: 'andria.wurm@franklintempleton.com',
    businessKey: 'A50184',
    userSysNo: '56016836',
    loginName: 'cteller',
    dealerNo: '08992',
  } as IUserProfileInfo,
};
const ABCPROFILE: ProfileConfig = {
  name: 'Abcd',
  isLoggedIn: true,
  isIdentified: true,
  bypassRoles: [BypassRole.FP],
  profileInfo: {
    userId: 'abcd',
    role: 'FP',
    webExperience: 'FA',
    displayName: 'ABCD',
    firm: '',
    addressLine1: '532 EASTERN BLVD STE 203',
    city: 'BALTIMORE',
    state: 'MD',
    zip: '212216738',
    phoneNumber: '410-686-7300',
    email: 'abcd@ft.com',
    businessKey: 'A50184',
    userSysNo: '56016836',
    loginName: 'cteller',
    dealerNo: '08992',
  } as IUserProfileInfo,
};

@Component({
  selector: 'ft-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit {
  @Input() page: Page;
  isDebugWidgetEnabled = false;
  labels: any;
  showLabelKeysDisabled = false;
  showInsightsDataDisabled = false;
  showTooltipKeysDisabled = false;
  showInsightsReportDisable = false;
  storedSegmentId: SegmentId;
  storedProfileSummary: ProfileSummary;
  storedTestProfileId: string;
  testProfileId: string;
  testProfiles: ProfileConfig[];
  public segmentId$: Observable<SegmentId>;
  public isLoggedIn$: Observable<boolean>;
  public profile$: Observable<IUserProfile>;
  public unvalidatedProfile$: Observable<IUserProfile>;
  public profileKey$: Observable<string>;

  constructor(
    private debugService: DebugService,
    private router: Router,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private segmentService: SegmentService,
    private storageService: StorageService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {}

  ngOnInit(): void {
    this.segmentId$ = this.segmentService.getCurrentSegmentId$();
    this.isLoggedIn$ = this.profileService.isLoggedIn$();
    this.unvalidatedProfile$ = this.profileService.getUnvalidatedUserProfile$();
    this.profile$ = this.profileService.getUserProfile$();

    // reformats profileKey json string onto multiple lines
    this.profileKey$ = this.profileService
      .getProfileChanges$()
      ?.pipe(
        map((profileKey: string): string =>
          JSON.stringify(JSON.parse(profileKey), null, 2)
        )
      );

    this.testProfiles = ProfileConfig.allConfigs.concat(CTELLER, ABCPROFILE);

    if (this.router?.url?.includes('debug=web-platform')) {
      this.isDebugWidgetEnabled = true;
      this.storageService.store<boolean>('isDebugWidgetEnabled', true, true);
      this.debugService.debugOverride = true;
    } else {
      this.storageService
        .retrieve<boolean>('isDebugWidgetEnabled', true)
        .then((val) => {
          this.isDebugWidgetEnabled = val || this.page?.isPreview();
        });
    }
    this.refreshCookies();
  }

  public refreshCookies(): void {
    this.storageService.retrieveSegment().then((segmentId: SegmentId): void => {
      logger.debug('retrieve segment', segmentId);
      this.storedSegmentId = segmentId;
    });
    this.storageService
      .retrieveProfileSummary()
      .then((summary: ProfileSummary): void => {
        logger.debug('retrieve profile summary', summary);
        this.storedProfileSummary = summary;
      });
    this.storageService
      .retrieve<string>('testProfileId', true)
      .then((id: string): void => {
        logger.debug('retrieve testProfileId', id);
        this.storedTestProfileId = id;
        this.testProfileId = id;
      });
  }

  public clear(): void {
    this.storageService.remove('isDebugWidgetEnabled', true);
    this.isDebugWidgetEnabled = false;
    this.debugService.clearAll();
  }
  get isPdsProd$(): Observable<boolean> {
    return this.debugService.getPdsEnv$().pipe(map((env) => env === 'prod'));
  }
  get isPdsDev$(): Observable<boolean> {
    return this.debugService.getPdsEnv$().pipe(map((env) => env === 'dev'));
  }
  setPdsEnv(env: string): void {
    this.debugService.setPdsEnv(env);
    this.reloadPage();
  }

  get debugCaveats$(): Observable<boolean> {
    return this.debugService.isCaveatDebug$();
  }
  toggleCaveatDebug(): void {
    this.debugService.toggleCaveatDebug();
  }

  get showDummyCaveats$(): Observable<boolean> {
    return this.debugService.isShowDummyCaveats$();
  }
  toggleShowDummyCaveats(): void {
    this.debugService.toggleShowDummyCaveats();
  }
  get debugPortfolio$(): Observable<boolean> {
    return this.debugService.isPortfolioDebug$();
  }
  togglePortfolioDebug(): void {
    this.debugService.togglePortfolioDebug();
  }

  get showLabelKeys$(): Observable<boolean> {
    return this.debugService.isShowLabelKeys$();
  }
  toggleShowLabelKeys(): void {
    this.debugService.toggleShowLabelKeys();
    this.showLabelKeysDisabled = true;
    this.reloadPage();
  }
  showLabels(): void {
    this.labels = this.translateService.getAllLabels();
  }

  get showInsightsData$(): Observable<boolean> {
    return this.debugService.isShowInsightsData$();
  }
  toggleShowInsightsData(): void {
    this.debugService.toggleShowInsightsData$();
    this.showInsightsDataDisabled = true;
    this.reloadPage();
  }

  get showInsightsReport$(): Observable<boolean> {
    return this.debugService.isShowInsightsReport$();
  }
  toggleShowInsightsReport(): void {
    this.debugService.toggleShowInsightsReport$();
    this.showInsightsReportDisable = true;
    this.reloadPage();
  }

  get showTooltipKeys$(): Observable<boolean> {
    return this.debugService.isShowTooltipKeys$();
  }
  toggleShowTooltipKeys(): void {
    this.debugService.toggleShowTooltipKeys();
    this.showTooltipKeysDisabled = true;
    this.reloadPage();
  }
  showTooltips(): void {
    this.labels = this.translateService.getAllLabels();
  }

  get debugExpandAll$(): Observable<boolean> {
    return this.debugService.getExpandAllComponents$();
  }
  toggleExpandAll(): void {
    this.debugService.toggleExpandAll();
  }

  changeProfile(value: string): void {
    this.storageService.store<string>('testProfileId', value, true);
    if (
      this.profileService.isLoggedIn() &&
      this.profileService.getUserProfile().loginSource === LoginSource.OAUTH
    ) {
    } else {
      const profile: ProfileConfig = this.testProfiles.find(
        (p: ProfileConfig): boolean => p.name === value
      );
      if (profile) {
        this.profileService.setProfileFromDebug(profile);
      }
    }
    this.refreshCookies();
  }

  reloadPage(): void {
    setTimeout(() => this.documentRef.location.reload(), 200);
  }
}
