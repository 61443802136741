import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  OtherFundDetailsDisplayComponent
} from "@products/other-details/other-fund-details-display-component.interface";
import { OtherFundDetailsComponentConfig } from "@products/other-details/other-fund-details-component.config";
import { Subscription, combineLatest } from 'rxjs';
import { Logger } from '@utils/logger';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { Page, Component as BrComponent } from '@bloomreach/spa-sdk';
import {
  DistributionState,
  DistributionTable,
  DropdownOption,
  FundInformationState,
  HistoricalForm,
  ProductDetailConfiguration,
  ShareClassCode,
} from '@types';
import { SiteConfigService } from '@services/site-config.service';
import { DistributionService } from '@components/products/pricing-distribution/services/distribution.service';
import { FundInformationService } from '@components/products/overview/services/fund-information.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMDASH } from '@components/products/utils/constants/product.constants';
import moment from 'moment';
import { TranslateService } from '@components/shared/translate/translate.service';

const logger = Logger.getLogger('IDCWHistoryModalComponent');

@Component({
  selector: 'ft-idcw-history-modal',
  templateUrl: './idcw-history-modal.component.html',
  styleUrls: ['./idcw-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IDCWHistoryModalComponent implements  OnDestroy, OnInit {
  // Class variables.
  dataSubscription: Subscription;
  isLoaded: boolean;
  isNfo: boolean;
  isMobileView: boolean = false;
  latestDistributionList: DistributionTable[];
  historicalDistributionList: DistributionTable[];
  startDate: string;
  endDate: string;
  fundName: string;
  showIDCWTable = false;
  invalidCalendarInputMessage: string;
  isIDCWHistory: boolean;
  historyDateForm: FormGroup = new FormGroup({});
  planList: DropdownOption[];
  currentDateStd: string;
  fundInceptionDate: string;
  dateFormatForComponent = 'DD/MM/YYYY';
  fundInceptionText: string;
  distStateCurrencyCode: string;
  distributionState: DistributionState;
  firstload = false;
  selectedPlan: string;
  // currentPageNumber = 1;
  // pageSize = 10;
  // maxSize = 7;
  // printable = false;
  noTableData: DistributionTable[] = [];
  printAction = new EventEmitter();
  listOfTabs: string[] = [];
  disableTab: boolean = false;
  @Input() brConfig;

  constructor(
    private fundInformationService: FundInformationService,
    private siteConfig: SiteConfigService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private translateService: TranslateService,
    private distributionService: DistributionService) {
    this.initForm();
  }

  ngOnInit(): void {
    if (window.innerWidth < 700) {
      this.isMobileView = true;
    }
 
    if (this.brConfig) {  // Check if brConfig is defined
      const tabSet = this.siteConfig.getFundTabSet(this.brConfig.fundId);
 
      if (tabSet) {  // Check if tabSet is defined
        this.listOfTabs = tabSet.tabs;
 
        if (!this.listOfTabs.includes("idcwHistory")) {
          this.disableTab = true;
        }
      } else {
        logger.error("Tab set is undefined for fundId:", this.brConfig.fundId);
      }
    } else {
      logger.error("brConfig is undefined.");
    }
   
    if(this.brConfig){
    this.fundInformationService.populate(this.brConfig);
    this.distributionService.populate(this.brConfig);
    this.isNfo = this.siteConfig.isNfoFund(this.brConfig.fundId);
    }
    this.dataSubscription = combineLatest([
      this.fundInformationService.getFundInformationState$(),
      this.distributionService.distributionState,
    ]).subscribe(
      (data: any) => {
        let fundInformationState: FundInformationState;
        let distService: DistributionState;
        [fundInformationState, distService] = data;
        this.isLoaded = fundInformationState.isLoaded;

        if (this.isLoaded) {
          this.currentDateStd = moment(new Date()).format('YYYY-MM-DD');
          this.isIDCWHistory = false;
          this.fundInceptionText = this.translateService.instant(
            `products.fund-inception-date`
          );
          this.mapState(distService);
        }
        this.cdr.detectChanges();
      },
      (error) => {
        logger.error(
          `Error in invoking fund information service: ${error}`
        );

        this.isLoaded = false;
        this.cdr.detectChanges();
      }
    );
  }
  
  initForm = () => {
    this.historyDateForm = this._fb.group({
      selectedPlan: [''],
      selectedToDateStd: [''],
      selectedFromDateStd: ['']
    })
  }
  mapState(distributionState: DistributionState): void {
    this.latestDistributionList = distributionState.latestData;
    this.planList = distributionState.planList;
    this.historyDateForm.get('selectedPlan')?.setValue(this.planList[0]?.value);
    this.distStateCurrencyCode = distributionState.fundCurrencyCode;
    this.fundInceptionDate = distributionState.fundInceptionDate;
    this.fundName = distributionState.fundName;
    this.distributionState = distributionState;
    if (this.latestDistributionList.length <= 0) {
      this.isIDCWHistory = true;
    }
    if (distributionState?.historicalData.length > 0) {
      this.historicalDistributionList = distributionState?.historicalData;
      this.showIDCWTable = true;
      this.invalidCalendarInputMessage = null;
    } else if (this.firstload) {
      this.showIDCWTable = false;
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.no-data-distribution-historical-filter`
      );
    }
    const noDistributionTableData: DistributionTable[] = [
      {
        planName: 'IDCW',
        incomeRecordDate: '--',
        incomeExDividendDate: '--',
        incomeDistributionAmount: '--',
        exDividendNavValue: '--',
        recordDateNavVal: '--',
      },
      {
        planName: 'Direct-IDCW',
        incomeRecordDate: '--',
        incomeExDividendDate: '--',
        incomeDistributionAmount: '--',
        exDividendNavValue: '--',
        recordDateNavVal: '--',
      },
    ];
    this.noTableData = noDistributionTableData;
    this.cdr.detectChanges();
  }

  getPlanName(selected: String) {
    for (let plan of this.planList) {
      if (plan.value == selected) {
        this.selectedPlan = plan.label;
      }
    }
  }

  toggleIDCWTable() {
    this.firstload = true;
    let selectedPlan = this.historyDateForm.controls['selectedPlan'].value;
    if (this.validateHistoryNavIDCWDates(this.historyDateForm?.value?.selectedFromDateStd, this.historyDateForm?.value?.selectedToDateStd)) {
      this.getPlanName(selectedPlan);
      this.startDate = this.historyDateForm?.value?.selectedFromDateStd;
      this.endDate = this.historyDateForm?.value?.selectedToDateStd;
      this.distributionService.fetchHistoricalRecords(selectedPlan, this.startDate, this.endDate);
      this.cdr.detectChanges();
    }
  }

  validateHistoryNavIDCWDates(selectedFromDateStd, selectedToDateStd) {
    const dateFormat = 'DD/MM/yyyy';
    if (
      // Check 1: 'selectedFromDateStd' should be less than or equal to today's date.
      moment(selectedFromDateStd, dateFormat) < moment(this.fundInceptionDate, dateFormat)
    ) {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.pricing-invalid-message-from-date-greater-than-or-equal-to-fund-inception-date`
      );
      return false;
    } else if (
      // Check 2: 'selectedFromDateStd' shouldn't be greater than 'selectedToDateStd'.
      moment(selectedFromDateStd, dateFormat) >
      moment(selectedToDateStd, dateFormat)
    ) {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.pricing-invalid-message-from-date-less-than-or-equal-to-date`
      );
      return false;
    } else if (
      // Check 2: 'selectedFromDateStd' shouldn't be greater than 'selectedToDateStd'.
      moment(selectedToDateStd, dateFormat) <
      moment(selectedFromDateStd, dateFormat)
    ) {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `pricing-invalid-message-to-date-greater-than-or-equal-from-date`
      );
      return false;
    } else if (selectedFromDateStd == "" || selectedToDateStd == "") {
      this.invalidCalendarInputMessage = this.translateService.instant(
        `products.distribution-invalid-message-from-date-to-date-na`
      );
      return false;
    } else {
      this.invalidCalendarInputMessage = null;
      return true;
    }

  }

  onFinalDate(selectedDate: string, dateField: string): void {
    if (dateField === 'fromDate') {
      this.historyDateForm.get('selectedFromDateStd')?.setValue(selectedDate);
    } else {
      this.historyDateForm.get('selectedToDateStd')?.setValue(selectedDate);
    }
    this.cdr.detectChanges();
  }

  printHistory() {
    const divToPrint = document.getElementById('dividend-printTable');
    const divPrintFundDetails = document.getElementById('print-fund-details');
    const newWin = window.open('');
    newWin.document.write(divPrintFundDetails.outerHTML);
    newWin.document.write(divToPrint.outerHTML);
    if (
      (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i)) == null
    ) {
      newWin.document.close(); // Testing defect fix for 3733 Print Issue on iPhone
    }
    newWin.focus();
    newWin.print();
    if (
      (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i)) == null
    ) {
      newWin.close(); // Testing defect fix for 3733 Print Issue on iPhone
    }
  }

  downloadHistory() {
    this.distributionService.triggerDownloadAction();
  }

  ngOnDestroy(): void {
    // Clean up subscription to avoid memory leaks.
    this.dataSubscription?.unsubscribe();
  }


  @Output() closeButton = new EventEmitter<boolean>();
  closeModel(){
    this.closeButton.emit(false);
  }
}


