import { DistributionHistory } from '@products/models/distribution-history';
import {
  CurrencyCode,
  DistributionHistoryDTO,
  FundId,
  ShareClassCode,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class DistributionHistoriesMapper extends Mapper<DistributionHistory[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    distributionHistoriesDto: DistributionHistoryDTO[]
  ): DistributionHistory[] {
    const distributionHistories: DistributionHistory[] = [];

    distributionHistoriesDto.forEach(
      (distributionHistoryDto: DistributionHistoryDTO) => {
        const distributionHistory: DistributionHistory = this.createMapper(
          DistributionHistoryMapper
        ).toDomain(distributionHistoryDto);

        if (distributionHistory) {
          distributionHistories.push(distributionHistory);
        }
      }
    );

    return distributionHistories;
  }
}

export class DistributionHistoryMapper extends Mapper<DistributionHistory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    distributionHistoryDto: DistributionHistoryDTO
  ): DistributionHistory {
    return {
      fundId: distributionHistoryDto.fundid as FundId,
      shareClassCode: distributionHistoryDto.shclcode as ShareClassCode,
      shareClassCurrency: distributionHistoryDto.shclcurr,
      investmentCategory: distributionHistoryDto.invstmntcategory,
      currencyCode: distributionHistoryDto.currcode as CurrencyCode,
      distributionAsOfDate: distributionHistoryDto.asofdate,
      distributionAsOfDateStd: distributionHistoryDto.asofdatestd,
      distributionAsOfDateYear: distributionHistoryDto.asofdatestd
        ? distributionHistoryDto.asofdatestd.split('-')[0]
        : null, // exclusively added and mapped as per asOfDateStd.
      incomeDistributionExDividendDate:
        distributionHistoryDto.incmdistexdivdate,
      incomeDistributionExPayableDate:
        distributionHistoryDto.incmdistexpayabledate,
      incomeDistributionRecordDate: distributionHistoryDto.incmdistrecorddate,
      reInvestmentDate: distributionHistoryDto.reinvstmntdate,
      incomeDistributionAmount: distributionHistoryDto.incmdistamt,
      reInvestmentAmount: distributionHistoryDto.reinvstmntamt,
      exDividendNavValue: distributionHistoryDto.exdivnavval,
      recordDateNavVal: distributionHistoryDto.recdtnavval,
      totalDistributionAmountMonthly: distributionHistoryDto.totaldistamtmth,
      totalDistributionAmountDaily: distributionHistoryDto.totaldistamtdaily,
      distributionCount: distributionHistoryDto.distribcnt,
      taxExemptIncomeAmount: distributionHistoryDto.taxexemptincmamt,
      incomeDistributionAmountYtd: distributionHistoryDto.incmdistamtytd,
      shareClassDistributionCode: distributionHistoryDto.shcldistribcode,
      divDistributionIncome: distributionHistoryDto.divpaidoutnetdistincm,
    };
  }
}
