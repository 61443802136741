import { AddFundsModal } from '@products/types/add-funds.type';
import { DropdownItem, FundId } from '../../types';

export interface FundCompareState {
  fundsData: FundCompareData[];
  addFundsData: AddFundsModal;
  isLoaded?: boolean;
}

export interface FundCompareData {
  fundId: FundId;
  fundName: string;
  fundLink: string;
  fundInceptionDate: string;
  minimumInvestment: string;
  additionalInvestment: string;
  performanceData: FundComparePerformanceData;
  portfolioData: FundComparePortfolioData;
  chargesData: FundCompareChargesData;
  dividendData: FundCompareDividendData[];
  aumData: FundCompareAUMData;
  contentData: FundCompareContentData;
  performancePeriodDropdownItems: DropdownItem[];
}

export interface FundComparePerformanceData {
  asOfDate: string;
  averageAnnualReturnFor1Year: string;
  averageAnnualReturnFor3Year: string;
  averageAnnualReturnFor5Year: string;
  averageAnnualReturnFor10Year: string;
  averageAnnualReturnSinceInception: string;
}

export interface FundComparePortfolioData {
  equityExposureData?: FundComparePortfolioEquityExposure[];
  turnoverRatio?: string;
}

export interface FundComparePortfolioEquityExposure {
  name: string;
  valueAsPercentage: string;
}

export interface FundCompareChargesData {
  expenseRatio: string;
  expenseRatioDirect: string;
}

export interface FundCompareDividendData {
  planName: string;
  recordDate: string;
  distributionAmount: string;
  recordNav: string;
  exDividendDate: string;
  exDividendNav: string;
}

export interface FundCompareAUMData {
  asOfDate: string;
  totalNetAssets: string;
}

export interface FundCompareContentData {
  fundDescription: string;
  riskType: string;
  taxImplication: string;
  schemeType: string;
  entryLoad: string;
  exitLoad: string;
  suitableFor: string[];
}

export interface FundCompareContentDataDTO {
  funddescription: string;
  fundrisktype: string;
  fundtaximplication: string;
  typeofscheme: string;
  entryload: string;
  exitload: string;
  fundsuitablefor: string[];
}

export interface FundCompareDetail {
  fundId?: FundId;
  fundName: string;
  isValid?: boolean;
}

export enum StorageOperation {
  'SET',
  'REMOVE',
}

export interface FundCompareToastAction {
  type: FundCompareToastActionType;
  message?: string;
}

export enum FundCompareToastActionType {
  'ADDED',
  'ALREADY_EXIST',
  'REMOVED',
  'LIMIT_REACHED',
  'ERROR',
}
