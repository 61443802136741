import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Logger } from '@utils/logger';
import { DistributionService } from '@components/products/pricing-distribution/services/distribution.service';
import { DistributionTable } from '@types';
import { sortShareClasses } from '@components/products/utils/shareclass-sorter';

const logger = Logger.getLogger('IDCWHistoryTableComponent');

@Component({
  selector: 'ft-idcw-history-table',
  templateUrl: './idcw-history-table.component.html',
  styleUrls: ['./idcw-history-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IDCWHistoryTableComponent implements OnInit {

  @Input() distributionTableData: DistributionTable[];
  @Input() printable = false;
  pageSize = 10;
  maxSize = 7;
  page = 1;

  constructor(
    private distributionService: DistributionService) {
  }

  ngOnInit(): void {
    this.distributionTableData = this.distributionTableData?.sort(
      sortShareClasses
    );
  }
}


