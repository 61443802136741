import { PricingHistory } from '@models';
import {
  CurrencyCode,
  FundId,
  PricingHistoryDTO,
  ShareClassCode,
} from '@types';
import { EMDASH } from '../constants/product.constants';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class PricingHistoriesMapper extends Mapper<PricingHistory[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(pricingHistoriesDto: PricingHistoryDTO[]): PricingHistory[] {
    const pricingHistories: PricingHistory[] = [];

    pricingHistoriesDto.forEach((pricingHistoryDto: PricingHistoryDTO) => {
      const pricingHistory: PricingHistory = this.createMapper(
        PricingHistoryMapper
      ).toDomain(pricingHistoryDto);

      if (pricingHistory) {
        pricingHistories.push(pricingHistory);
      }
    });

    return pricingHistories;
  }
}

export class PricingHistoryMapper extends Mapper<PricingHistory> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(pricingHistoryDto: PricingHistoryDTO): PricingHistory {
    return {
      fundId: pricingHistoryDto.fundid as FundId,
      shareClassCode: pricingHistoryDto.shclcode as ShareClassCode,
      navDate: pricingHistoryDto.navdate,
      asOfDate: pricingHistoryDto.asofdate,
      asOfDateStd: pricingHistoryDto.asofdatestd,
      nav: pricingHistoryDto.nav,
      navStd: pricingHistoryDto.navstd,
      navChangeValue: pricingHistoryDto.navchngval
        ? pricingHistoryDto.navchngval
        : EMDASH,
      currencyCode: pricingHistoryDto.currcode as CurrencyCode,
      navChangePercent: pricingHistoryDto.navchngpct
        ? pricingHistoryDto.navchngpct
        : EMDASH,
      applicationPrice: pricingHistoryDto.applicationprice
        ? pricingHistoryDto.applicationprice
        : EMDASH,
      redemptionPrice: pricingHistoryDto.redemptionprice
        ? pricingHistoryDto.redemptionprice
        : EMDASH,
      reinvestedNav: pricingHistoryDto.reinvestednav
        ? pricingHistoryDto.reinvestednav
        : EMDASH,
      marketPriceValue: pricingHistoryDto.mktpriceval
        ? pricingHistoryDto.mktpriceval
        : EMDASH,
      marketPriceValueStd: pricingHistoryDto.mktpricevalstd
        ? pricingHistoryDto.mktpricevalstd
        : EMDASH,
      marketChangeValue: pricingHistoryDto.mktchngval
        ? pricingHistoryDto.mktchngval
        : EMDASH,
      marketChangePercent: pricingHistoryDto.mktchngpct
        ? pricingHistoryDto.mktchngpct
        : EMDASH,
      high52WeekRollingValue: pricingHistoryDto.high52weekrollingval,
      high52WeekRollingDate: pricingHistoryDto.high52weekrollingdate,
      low52WeekRollingValue: pricingHistoryDto.low52weekrollingval,
      low52WeekRollingDate: pricingHistoryDto.low52weekrollingdate,
      discountOrPremiumInception: pricingHistoryDto.discountorpremiumincep
        ? pricingHistoryDto.discountorpremiumincep
        : EMDASH,
      discountOrPremiumAtLastClose: pricingHistoryDto.discountorpremiumatlc
        ? pricingHistoryDto.discountorpremiumatlc
        : EMDASH,
      bidOrAskSpread: pricingHistoryDto.bidoraskspread
        ? pricingHistoryDto.bidoraskspread
        : EMDASH,
      highMarketPrice52WeekValue: pricingHistoryDto.highmktprice52weekval,
      highMarketPrice52WeekDate: pricingHistoryDto.highmktprice52weekdate,
      lowMarketPrice52WeekValue: pricingHistoryDto.lowmktprice52weekval,
      lowMarketPrice52WeekDate: pricingHistoryDto.lowmktprice52weekdate,
      masterFundNav: pricingHistoryDto.masterfundnav
        ? pricingHistoryDto.masterfundnav
        : EMDASH,
      weeklyLiquidAssetRatio: pricingHistoryDto.weeklyliquidassetratio
        ? pricingHistoryDto.weeklyliquidassetratio
        : EMDASH,
      dailyLiquidAssetRatio: pricingHistoryDto.dailyliquidassetratio
        ? pricingHistoryDto.dailyliquidassetratio
        : EMDASH,
      viiv: pricingHistoryDto.viiv ? pricingHistoryDto.viiv : EMDASH,
      netShareHolderFlow: pricingHistoryDto.netshareholderflow
        ? pricingHistoryDto.netshareholderflow
        : EMDASH,
      masterNetShareHolderFlow: pricingHistoryDto.masternetshareholderflow
        ? pricingHistoryDto.masternetshareholderflow
        : EMDASH,
    };
  }
}
