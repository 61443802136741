import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearsOld'
})
export class FundAgePipe implements PipeTransform {
  transform(inceptionDate: string): string {
    if (!inceptionDate) {
      return '';
    }
    // Parse the date using the correct format (DD/MM/YYYY)
    const [day, month, year] = inceptionDate.split('/');

    // Manually create the date object (month is zero-indexed)
    const inception = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

    const today = new Date();

    // Calculate the difference in years, months, and days
    let years = today.getFullYear() - inception.getFullYear();
    const months = today.getMonth() - inception.getMonth();
    const days = today.getDate() - inception.getDate();

    // Adjust the year count if the current month and day are before the inception month and day
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
    }
    if (years < 0) {
      years = 0;
    }
    return `${years} ${years > 1 ? 'Years' : 'Year'} Old Fund`;
  }
}
