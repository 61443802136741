import { Injectable, OnDestroy } from '@angular/core';
import { Product, ShareClass } from '@models';
import { Distribution } from '@products/models/distribution';
import { DistributionHistory } from '@products/models/distribution-history';
import { DistributionHistoryService } from '@products/services/distribution-history.service';
import { ProductTypeParameter } from '@products/services/graphql-fund-data.service';
import { SiteConfigService } from '@services/site-config.service';
import {
  ConfigurationId,
  DistributionState,
  DistributionTable,
  DownloadableTableRowData,
  FundId,
  ProductDetailConfiguration,
  ShareClassCode,
} from '@types';
import { Logger } from '@utils/logger';
import { Subject } from 'rxjs';
import distributionHistoricalData from '@graphql/pricing-distribution/distribution-history.graphql';
import distributionLatestData from '@graphql/pricing-distribution/distribution.graphql';
import { DISTRIBUTION_INITIAL_STATE } from './pricing-distribution.config';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { TranslateService } from '@shared/translate/translate.service';
import { CaveatService } from '@products/caveats/services/caveat.service';
import { take } from 'rxjs/operators';
import { DebugService } from '@services/debug.service';
import { FundDetailService } from '@components/products/services/fund-detail.service';
import { EMDASH } from '@components/products/utils/constants/product.constants';
import { sortShareClasses } from '@components/products/utils/shareclass-sorter';

const logger = Logger.getLogger('DistributionService');

@Injectable({
  providedIn: 'root',
})
export class DistributionService implements OnDestroy {
  private state: DistributionState;
  private configurationName: ConfigurationId;
  distributionState: Subject<DistributionState>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private fundId: FundId;
  private startDate: string;
  private endDate: string;

  constructor(
    private distributionHistoryService: DistributionHistoryService,
    private siteConfigService: SiteConfigService,
    private fundDetailService: FundDetailService,
    private translateService: TranslateService,
    private caveatService: CaveatService,
    private debugService: DebugService
  ) {
    this.distributionState = new Subject();
  }

  /**
   * Call register method of fund overview & distribution history service.
   * @param productDetailConfiguration the configuration received on page detail load
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.state = cloneDeep(DISTRIBUTION_INITIAL_STATE);
    this.fundId = productDetailConfiguration.fundId;
    this.fundDetailService
      .register(distributionLatestData, {
        fundId: productDetailConfiguration.fundId,
      })
      ?.subscribe((productData: Product) => {
        logger.debug(productData);
        this.mapState(productData);
      });
  }

  /**
   * Split the merged details and map the overview & distribution data to the state accordingly.
   * Map the decorated config and any other setting to the state.
   * @param mergedData merged response for product & distribution histories.
   */
  mapState(fundData: Product): void {
    this.state.fundName = fundData?.fundName;
    this.state.productType = fundData?.productType;
    this.state.fundInceptionDate = fundData?.fundInceptionDate;
    this.state.fundInceptionText = this.translateService.instant(
      `products.fund-inception-date`
    );
    this.state.fundCurrencyCode = fundData?.currencyCode;
    this.state.siteConfig = this.siteConfigService.product?.site;
    fundData.shareClasses = fundData?.shareClasses?.sort(sortShareClasses);
    const firstShareClass = fundData?.shareClasses[0];
    this.state.navAsOfdate = firstShareClass?.nav?.navAsOfDate || EMDASH;
    this.state.planList = [];
    this.state.latestData = [];
    fundData.shareClasses.forEach((shareClass: ShareClass) => {
      if (shareClass.distribution.length) {
        this.state.planList.push({
          value: shareClass.shareClassCode,
          label: shareClass.shareClassName,
        });
        const distributionDetail: Distribution = shareClass.distribution[0];
        this.state.latestData.push({
          planName: shareClass.shareClassName,
          incomeRecordDate:
            distributionDetail?.incomeDistributionRecordDate || EMDASH,
          incomeDistributionAmount:
            distributionDetail?.incomeDistributionAmount || EMDASH,
          exDividendNavValue: distributionDetail?.exDividendNavValue || EMDASH,
          recordDateNavVal: distributionDetail?.recordDateNavVal || EMDASH,
          incomeExDividendDate:
            distributionDetail?.incomeDistributionExDividendDate || EMDASH,
        });
      }
    });
    this.state.hasLoadedFullFeed = true;
    this.state.hasLoadedHistorical = true;
    this.distributionState.next(this.state);
  }

  getPlanName(planCode: string): string {
    return (
      this.state?.planList.find((plan) => plan.value === planCode)?.label ||
      EMDASH
    );
  }

  fetchHistoricalRecords(
    selectedPlan: ShareClassCode,
    selectedFromDateStd: string,
    selectedToDateStd: string
  ): void {
    this.startDate = selectedFromDateStd;
    this.endDate = selectedToDateStd;
    const dateFormat = 'DD/MM/YYYY';
    const dateFromatAsNumber = 'YYYYMMDD';
    const startDateFormatted = moment(selectedFromDateStd, dateFormat);
    const startDateAsNumber = Number(
      moment(startDateFormatted ? startDateFormatted : undefined).format(
        dateFromatAsNumber
      )
    );
    const endDateFormatted = moment(selectedToDateStd, dateFormat);
    const endDateAsNumber = Number(
      moment(endDateFormatted ? endDateFormatted : undefined).format(
        dateFromatAsNumber
      )
    );
    this.distributionHistoryService
      .register(distributionHistoricalData, {
        configurationName: this.getConfigurationName(),
        productType: ProductTypeParameter.MUTUAL_FUNDS,
        fundId: this.fundId,
        shareClassCode: selectedPlan,
        startdate: startDateAsNumber,
        enddate: endDateAsNumber,
      })
      ?.subscribe(
        (historyData: DistributionHistory[]) => {
          this.state.historicalData = [];
          historyData.forEach((histryRow) => {
            this.state.historicalData.push({
              planName: this.getPlanName(selectedPlan),
              incomeRecordDate:
                histryRow?.incomeDistributionRecordDate || EMDASH,
              incomeDistributionAmount:
                histryRow?.incomeDistributionAmount || EMDASH,
              exDividendNavValue: histryRow?.exDividendNavValue || EMDASH,
              recordDateNavVal: histryRow?.recordDateNavVal || EMDASH,
              incomeExDividendDate:
                histryRow?.incomeDistributionExDividendDate || EMDASH,
            });
          });
          if (this.state.historicalData.length) {
            this.state.isHistoricalDataEmpty = false;
          } else {
            this.state.isHistoricalDataEmpty = true;
          }
          this.state.hasLoadedFullFeed = true;
          this.distributionState.next(this.state);
        },
        (error) => {
          logger.error(error);
        }
      );
  }

  setConfigurationName(configurationName: ConfigurationId): void {
    this.configurationName = configurationName;
  }

  getConfigurationName(): ConfigurationId {
    return this.configurationName;
  }

  /**
   * Handle download action.
   * Convert table records into excel format and initiate download.
   */
  triggerDownloadAction(): void {
    const mappedHeader: Map<string, string> = this.mapDataHeader();

    const mappedBody: DownloadableTableRowData[] = this.mapDownloadData(
      this.state.historicalData
    );

    const mappedFullRecords: string[][] = [
      Object.keys(mappedBody[0]).map((data) =>
        this.translateService.instant(mappedHeader.get(data))
      ),
    ];

    mappedBody.forEach((data) => {
      mappedFullRecords.push(Object.values(data));
    });

    const topCaveat = [];
    const bottomCaveat = [];
    const fundName = [];
    const shareClassSection = [];
    const endDateStartDateSection = [];

    fundName.push([this.state.fundName]);
    shareClassSection.push([
      `${this.translateService.instant('products.distributions-plan')}`,
      this.state.historicalData[0].planName,
    ]);

    shareClassSection.push([
      `${this.translateService.instant('products.inception-date')}`,
      this.state.fundInceptionDate,
    ]);
    shareClassSection.push([
      `${this.translateService.instant('distributions-fund-base-currency')}`,
      this.state.fundCurrencyCode,
    ]);

    endDateStartDateSection.push([
      `${this.translateService.instant(
        'products.distribution-excel-download-dividends'
      )} : ${this.translateService.instant('products.calendar-from')} ${
        this.startDate
      } ${this.translateService.instant('products.calendar-to')} ${
        this.endDate
      }`,
    ]);

    this.caveatService
      .getProximals$('HistoricalDividendDataExcelTop')
      .pipe(take(1))
      ?.subscribe((topCaveatContext) => {
        const topCaveatData = topCaveatContext.map((data) => data.split('\n'));
        topCaveatData[0]?.map((data: any) => {
          const topTrimedData = data.replace(/(<([^>]+)>)/gi, '');// NOSONAR
          if (topTrimedData.length > 0) {
            topCaveat.push([topTrimedData]);
          }
        });
      });

    this.caveatService
      .getProximals$('HistoricalDividendDataExcelBottom')
      .pipe(take(1))
      ?.subscribe((bottomCaveatContext) => {
        const bottomCaveatData = bottomCaveatContext.map((data) =>
          data.split('\n')
        );
        bottomCaveatData[0]?.map((data: any) => {
          const bottomTrimedData = data.replace(/(<([^>]+)>)/gi, '');// NOSONAR
          if (bottomTrimedData.length > 0) {
            bottomCaveat.push([bottomTrimedData]);
          }
        });
      });

    this.exportToExcel(
      mappedFullRecords,
      topCaveat,
      bottomCaveat,
      fundName,
      shareClassSection,
      endDateStartDateSection
    );
  }

  /**
   * Return mapped header for corresponding column data.
   * @param isSiteIntl boolean flag for site international or US
   */
  mapDataHeader(): Map<string, string> {
    const mappedHeader = new Map<string, string>();
    mappedHeader.set(
      'incomeRecordDate',
      'products.distributions-incm-dist-record-date'
    );
    mappedHeader.set(
      'incomeDistributionAmount',
      'products.distributions-incm-div-per-unit'
    );
    mappedHeader.set('recordDateNavVal', 'products.distributions-record-nav');
    mappedHeader.set(
      'incomeExDividendDate',
      'products.distributions-incm-ex-div-date'
    );
    mappedHeader.set(
      'exDividendNavValue',
      'products.distributions-incm-ex-div-nav'
    );
    return mappedHeader;
  }

  /**
   * Return mapped data as per downloadable action.
   */
  mapDownloadData(
    totalRecords: DistributionTable[]
  ): DownloadableTableRowData[] {
    const mappedData: DownloadableTableRowData[] = [];
    totalRecords.forEach((record) => {
      mappedData.push({
        incomeRecordDate: record?.incomeRecordDate,
        incomeDistributionAmount: record?.incomeDistributionAmount,
        recordDateNavVal: record?.recordDateNavVal,
        incomeExDividendDate: record?.incomeExDividendDate,
        exDividendNavValue: record?.exDividendNavValue,
      });
    });

    return mappedData;
  }

  /**
   * Export the table data to excel and auto download.
   * @param data full record for excel
   */
  exportToExcel(
    data: string[][],
    topCaveat: string[][],
    bottomCaveat: string[][],
    fundName: string[][],
    shareClassSection: string[][],
    endDateStartDateSection: string[][]
  ): void {
    import('xlsx').then((xlsx) => {
      const workSheet = xlsx.utils.aoa_to_sheet(topCaveat);
      const workBook = xlsx.utils.book_new();

      workSheet['!cols'] = this.fitToColumn(fundName);

      xlsx.utils.sheet_add_aoa(workSheet, fundName, {
        origin: 'A' + (topCaveat.length + 2),
      });

      xlsx.utils.sheet_add_aoa(workSheet, shareClassSection, {
        origin: 'A' + (topCaveat.length + fundName.length + 3),
      });

      xlsx.utils.sheet_add_aoa(workSheet, endDateStartDateSection, {
        origin:
          'A' +
          (topCaveat.length + fundName.length + shareClassSection.length + 4),
      });

      xlsx.utils.sheet_add_aoa(workSheet, data, {
        origin:
          'A' +
          (topCaveat.length +
            fundName.length +
            shareClassSection.length +
            endDateStartDateSection.length +
            6),
      });

      xlsx.utils.sheet_add_aoa(workSheet, bottomCaveat, {
        origin:
          'A' +
          (data.length +
            8 +
            topCaveat.length +
            fundName.length +
            endDateStartDateSection.length +
            shareClassSection.length),
      });
      const fileTabName = this.translateService.instant(
        'products.distribution-history-report-tabname'
      );
      const fileName = this.translateService.instant(
        'products.distribution-history-report-filename'
      );

      xlsx.utils.book_append_sheet(
        workBook,
        workSheet,
        fileTabName.length > 31 ? undefined : fileTabName // Max. tab size allowed is 31 char.
      );

      xlsx.writeFile(
        workBook,
        // If, file name not added in BR with xls/xlsx extension, download with default hardcoded name.
        fileName.includes('xls') ? fileName : `HistoricPrices.xlsx`
      );
    });
  }

  /**
   * Get maximum character of each column.
   * @param data full record for excel
   */
  fitToColumn(data: string[][]) {
    return data[0].map((a, i) => ({
      wch: Math.max(...data.map((a2) => a2[i].toString().length)),
    }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
