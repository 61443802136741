import { Injectable } from '@angular/core';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SiteConfigService } from '@services/site-config.service';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import {
  FundDataServiceParams,
  GraphQLFundDataService,
} from './graphql-fund-data.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PricingHistoryDTO } from '@types';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { PricingHistoriesMapper } from '@products/utils/mappers/pricing-histories.type.mapper';
import { PricingHistory } from '@models';

const logger = Logger.getLogger('PricingHistoryService');

@Injectable({
  providedIn: 'root',
})
export class PricingHistoryService extends GraphQLFundDataService {
  private pricingSelectionSubject$: Subject<void> = new Subject<void>();
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private mapperFactory: MapperFactory
  ) {
    super(apollo, siteConfigService);
  }

  public register(
    query,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<PricingHistory[]> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((rawHistoricalData) => {
        const pricingHistoriesDto: PricingHistoryDTO[] = rawHistoricalData?.data
          ?.PricesHistory?.prices
          ? rawHistoricalData?.data?.PricesHistory?.prices
          : null;

        const mappperParams: MapperParams = {
          config: this.siteConfigService,
          includeSoftClosed: true,
        };

        const mappedPricingHistory: PricingHistory[] = this.mapperFactory
          .createMapper(PricingHistoriesMapper, mappperParams)
          .toDomain(pricingHistoriesDto);

        return mappedPricingHistory;
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
      shareclasscode: fundDataServiceParams.shareClassCode,
      startdate: fundDataServiceParams.startdate,
      enddate: fundDataServiceParams.enddate,
    };
  }

  public get pricingSelection$(): Observable<void> {
    return this.pricingSelectionSubject$.asObservable();
  }

  public handlePricingTabSelection() {
    this.pricingSelectionSubject$.next();
  }
}
