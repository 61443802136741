<div class="modal-header">
  <h1 class="modal-title fs-5" id="NAVModalLabel">
    {{ "products.fund-listing-button-historical-navs" | translate }}
  </h1>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModel()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="historyDateForm" class="modal-form mb-1">
    <div class="row gx-3">
      <div class="col-lg col-sm-6 col-12 mb-3">
        <select class="form-select" formControlName="selectedPlan" aria-label="Default select example">
          <option *ngFor="let plan of fundPlans; let i = index" [attr.value]="plan.value">
            {{ plan.label }}
          </option>
        </select>
      </div>
      <div class="col-lg col-sm-6 col-12 history-date mb-3">
        <select class="form-select" aria-label="Query list" formControlName="historyDate"
          (change)="onTimePeriodChange()">
          <option value="" *ngFor="let timePlan of timePeriods; let i = index" [value]="timePlan.value" [selected]="i === 0">
            {{ timePlan.label }}
          </option>
        </select>
        <div class="row date gx-3" *ngIf="isCustomDateRangeSelected">
          <!-- Calendar inputs -->
          <div class="col-6">
            <label>{{
              "products.calendar-from" | translate
              }}</label>
            &nbsp;
            <div class="date-group bg-white d-inline-flex">
              <input aria-label="selectdate" class="form-control date-field" type="text"
                formControlName="selectedFromDateStd" />
              <ft-date-picker [displayDate]="currentDateStd" [fundInception]="fundInceptionDate"
                [convertToFormat]="dateFormatForComponent" [sentDateFormat]="'YYYY-MM-DD'"
                [fundInceptionText]="fundInceptionText" (finalDate)="onFinalDate($event, 'fromDate')"
                [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
            </div>
          </div>
          <div class="col-6">
            <label>{{
              "products.calendar-to" | translate
              }}</label>
            &nbsp;
            <div class="date-group bg-white d-inline-flex">
              <input aria-label="form-date" class="form-control date-field" type="text"
                formControlName="selectedToDateStd" />
              <ft-date-picker [displayDate]="currentDateStd" [convertToFormat]="dateFormatForComponent"
                [sentDateFormat]="'YYYY-MM-DD'" (finalDate)="onFinalDate($event, 'toDate')"
                [customLeftPosition]="'calc(0% - 280px)'"></ft-date-picker>
            </div>
          </div>
          <!-- Invalid calendar input message -->
          <div *ngIf="invalidCalendarInputMessage" class="invalid-message"><span>{{ invalidCalendarInputMessage
              }}</span></div>
        </div>
        <div *ngIf="noDataForSelectedPeriod" class="invalid-message"><span>{{ noDataForSelectedPeriod }}</span></div>
      </div>
      <div class="col-lg-auto col-12 mb-3">
        <button type="button" class="btn btn-primary submit-btn" (click)="submitHistoricalNAVTable()">
          {{ "products.pricing-submit-button" | translate }}
        </button>
      </div>
    </div>
  </form>

  <!-- Fund Historical NAN Popup table start here-->
  <div class="table-box" id="print-fundNav-section" *ngIf="isHistoricalDataAvailable">
    <div class="title py-3">
      <h4>
        {{ "products.pricing-selected-fund-details" | translate }}
      </h4>
    </div>
    <div>
      <h4>
        <span style="color: #3769ff; margin-left: 0px">{{
          fundName
          }}</span><span style="font-size: large" class="shareClassPadding">{{
          selectedFundShareClassData?.shareClassName
          }}</span>
      </h4>
      <ul>
        <li>
          {{ "products.pricing-inception-date" | translate }}:
          {{ selectedFundShareClassData?.shareClassInceptionDate }}
        </li>
        <li>
          {{ "products.pricing-fund-base-currency" | translate }}:
          {{
          selectedFundShareClassData?.shareClassCurrency
          | inrSymbol : "post"
          }}
        </li>
      </ul>
      <div class="row pt-4 pb-3 text-center">
        <div class="col-md-3 col-6 mb-3 fund-info-print">
          <div style="opacity: 0.9; font-size: 0.813rem">
            {{ "products.nav" | translate }}
            {{ "products.as-of" | translate }}
            {{ selectedFundShareClassData?.nav.navDate }}:
          </div>
          <div class="text-meduim">
            {{
            selectedFundShareClassData?.nav.navValue
            | inrSymbol : "pre"
            }}
          </div>
        </div>
        <div class="col-md-3 col-6 mb-3 fund-info-print">
          <div class="" style="opacity: 0.9; font-size: 0.813rem">
            {{
            "products.pricing-historical-price-result" | translate
            }}:
          </div>
          <div class="text-meduim">
            {{ selectedDataDateRange?.startDate }}
            {{ "products.pricing-to" | translate }}
            {{ selectedDataDateRange?.endDate }}
          </div>
        </div>
        <div class="col-md-3 col-6 mb-3 fund-info-print">
          <div class="" style="opacity: 0.9; font-size: 0.813rem">
            {{ "products.pricing-highest-nav-in-range" | translate }}:
          </div>
          <div class="text-meduim" *ngIf="
                        selectedHighLowNavData?.highestNav ||
                        selectedHighLowNavData?.highestNavDate
                      ">
            {{
            selectedHighLowNavData.highestNav
            | inrSymbol : "pre"
            | ftDashIfEmpty
            }}
            {{ "products.pricing-on" | translate }}
            {{
            selectedHighLowNavData.highestNavDate | ftDashIfEmpty
            }}
          </div>
          <div *ngIf="
                        !selectedHighLowNavData?.highestNav &&
                        !selectedHighLowNavData?.highestNavDate
                      ">
            {{ "" | ftDashIfEmpty }}
          </div>
        </div>
        <div class="col-md-3 col-6 mb-3 fund-info-print">
          <div class="" style="opacity: 0.9; font-size: 0.813rem">
            {{ "products.pricing-lowest-nav-in-range" | translate }}:
          </div>
          <div class="text-meduim" *ngIf="
                        selectedHighLowNavData?.lowestNav ||
                        selectedHighLowNavData?.lowestNavDate
                      ">
            {{
            selectedHighLowNavData.lowestNav
            | inrSymbol : "pre"
            | ftDashIfEmpty
            }}
            {{ "products.pricing-on" | translate }}
            {{ selectedHighLowNavData.lowestNavDate | ftDashIfEmpty }}
          </div>

          <div *ngIf="
                        !selectedHighLowNavData?.lowestNav &&
                        !selectedHighLowNavData?.lowestNavDate
                      ">
            {{ "" | ftDashIfEmpty }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-part mb-4">
      <div class="d-flex justify-content-between align-items-center py-4">
        <div class="text-bold">
          {{ "products.pricing-viewing-data" | translate }}
          {{ "products.from" | translate }}
          {{ selectedDataDateRange?.startDate }}
          {{ "products.calendar-to" | translate }}
          {{ selectedDataDateRange?.endDate }}
        </div>
        <div class="no-print">
          <button type="button" class="btn btn-transparent me-3" [useExistingCss]="true"
            printSectionId="print-fundNav-section" ngxPrint>
            <i class="bi bi-printer fs-5"></i>
          </button>
          <!--<button class="btn btn-transparent me-3" (click)="onDownloadNAVTable()">
            <img alt="image" src="assets/images/fund/download.svg" />
          </button-->
          <a href="javascript:void(0)" (click)="onDownloadNAVTable()"><i class="bi bi-download fs-5"></i></a>
        </div>
      </div>
      <div class="table-responsive text-center">
        <table class="table table-success table-striped">
          <thead>
            <tr>
              <th scope="col">
                {{ "products.pricing-date" | translate }}
              </th>
              <th scope="col">
                {{ "products.pricing-nav" | translate }} (<span class="currency-symbol">{{ INR_SYMBOL }}</span>)
              </th>
              <th scope="col">
                {{ "products.pricing-nav-change" | translate }} (<span class="currency-symbol">{{ INR_SYMBOL }}</span>)
              </th>
            </tr>
          </thead>
          <tbody *ngIf="pricingTableData?.length">
            <tr *ngFor="
                          let record of pricingTableData
                            | slice
                              : (currentPageNumber - 1) * pageSize
                              : (currentPageNumber - 1) * pageSize + pageSize
                        ">
              <td>
                {{ record.navDate }}
              </td>
              <td>
                {{ record.navValue }}
              </td>
              <td>
                {{ record.navChange }}
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!pricingTableData?.length">
            <tr>
              <td>
                {{ "products.pricing-no-historical-records" | translate }}
              </td>
              <td>
                {{ "" | ftDashIfEmpty }}
              </td>
              <td>
                {{ "" | ftDashIfEmpty }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-pagination no-print">
        <ngb-pagination class="ft-pagination margin-top" *ngIf="pageSize < pricingTableData?.length"
          [(page)]="currentPageNumber" [pageSize]="pageSize" [collectionSize]="pricingTableData?.length"
          [maxSize]="maxSize" [ellipses]="false">
          <ng-template ngbPaginationPrevious>
            <i class="bi bi-arrow-left me-sm-1"></i> <span class="hide-in-mobile">Previous</span>
          </ng-template>
          <ng-template ngbPaginationNext><span class="hide-in-mobile">Next</span> <i
              class="bi bi-arrow-right ms-sm-1"></i>
          </ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>

  <!-- Fund Historical NAN Popup table End Here-->
  <div class="text-small">
    {{ "products.capital-withdrawal-history-heading" | translate }}
  </div>
</div>
