import { Injectable } from '@angular/core';

import { ToastrService, IndividualConfig, ActiveToast } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private readonly defaultOptions: Partial<IndividualConfig> = {
    timeOut: 2000,
    enableHtml:true,
    onActivateTick: true,
  };

  constructor(private readonly toastr: ToastrService) { }

  public success(message: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = override || this.defaultOptions;

    return this.toastr.success(message, title, override);
  }

  public error(message: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = override || this.defaultOptions;

    return this.toastr.error(message, title, override);
  }

  public info(message: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = override || this.defaultOptions;

    return this.toastr.info(message, title, override);
  }

  public warning(message: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = override || this.defaultOptions;

    return this.toastr.warning(message, title, override);
  }

  public clearAll() {
    this.toastr.clear();
  }
}
