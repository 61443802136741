import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { AnalyticsService } from "@services/analytics.service";
import { CommonService } from "@services/common.service";
import { InvestNowService } from "@services/invest-now.service";
import { TranslateService } from "@shared/translate/translate.service";
import { Logger } from "@utils/logger";
import moment from "moment/moment";
import {
  FundCompareItem
} from "@products/types/fund-compare.type";
import { FundCompareService } from "@products/fund-listing/services/fund-compare.service";
import { WatchListService } from "../../services/watch-list.service";
import { Subscription } from "rxjs";
import { WatchListItem } from "@components/products/types/watch-list.type";
import { FundListingBRConfiguration, FundListingComponentType } from "@types";
import { ToasterService } from "@utils/toaster/toaster-service/toaster.service";
import { take } from "rxjs/operators";

const logger = Logger.getLogger("HomeFundCardComponent");

@Component({
  selector: "ft-home-fund-card",
  templateUrl: "./home-fund-card.component.html",
  styleUrls: ["./home-fund-card.component.scss"],
})
export class HomeFundCardComponent implements OnInit, OnChanges {
  @Input() fund: any;
  @Input() fundCompareIds: any;
  makeToggle: boolean = true;
  showSaveDialoge = false;
  showRemoveDialoge: boolean = false;
  showCartSaveDialoge: boolean = false;
  showCopyDialoge = false;
  linkToCopy: string;
  @Input() brConfiguration: FundListingBRConfiguration;

  @Input() cardType: string;
  @Input() hasThreeDotOptions: boolean;

  @Input() makeCartToggle: boolean = true;
  @Input() hasAddRemoveButton: boolean = false;
  @Input() investmentCartAddForm: boolean = false;

  showShareDialoge: boolean = false;
  showInvestmentCart: boolean = false;
  getFundsData: any = [];
  makeSaveCancelBtn: boolean = false;
  @Output() addToCartOutput: EventEmitter<any> = new EventEmitter();
  selectedCartDetails: any;
  @Input() hasFundBtn: boolean = true;
  @Output() makeOffCanvasOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  compareFull: boolean = false;
  addComparedCardAlert: boolean = false;
  showComparedDialoge: boolean = false;
  removeCompareDialoge: boolean = false;
  isFundInWatchlist: boolean = false;
  isFundInComparelist: boolean = false;
  dataSubscription: Subscription;
  watchListFull: boolean = false;
  watchListCount: number = 0;
  AddFundOnWatchList: boolean = false;
  compareListFundsCount: number = 0;
  watchListSuccessMsg = '';
  watchListRemoveMsg = '';
  compareSuccessMsg = '';
  compareRemoveMsg = '';
  gotoWatchList = '';
  gotoCompareList = '';
  comparePageUrl = '';
  watchListPageUrl = '';
  componentType: FundListingComponentType;
  hisNavButtonLabel: string;
  hisIDCWButtonLabel: string;
  viewToggle: boolean = false;
  showSecondRow: boolean = false;
  naLabel: string = 'NA';
  idcwModal: boolean = false;
  navModal: boolean = false;
  idcwConfig: any;
  navConfig: any;
  items = ["IDCW", "Direct-IDCW"];
  selectedShareClass: string = '';
  nfoFundList: string;
  nfoToMicrosite: string = 'false';
  nfoToMicrositeURL: string;
  displayBlockedFunds: string;
  blockedFund: boolean = false;
  isNFO: boolean = false;

  slideConfig = {
    infinite: false,
    dots: false,
    arrows: true,
    prevArrow: "<button type='button' class='btn btn-outline-secondary fund-card1-icon left-arrow' ><i class='bi bi-arrow-left-short card1-arrow'  ></i></button>",
    nextArrow: "<button type='button' class='btn btn-outline-secondary fund-card2-icon right-arrow'><i class='bi-arrow-right-short'></i></button>",

    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 768,

        settings: {
          arrows: false,
          slidesToShow: 3.75,
          slidesToScroll: 1
        }
      }
      ,
      {
        breakpoint: 576,

        settings: {
          arrows: false,
          slidesToShow: 2.3,
          slidesToScroll: 1
        }
      }
    ]
  };
  // ------------------------------------------------
  distributionDataAsLists: [[string, string][]] = [[]];
  defaultOptionForShareClassName = 0;
  buttonStatus: boolean = true;
  compareButtonStatus: boolean = true;

  constructor(
    private _router: Router,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private investNowService: InvestNowService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private fundCompareService: FundCompareService,
    private watchListService: WatchListService,
    private readonly toasterService: ToasterService,
  ) {
    let currentUrl = this._router.url;
    if (currentUrl === '/funds-and-solutions/tools/funds-watchlist') {
      this.buttonStatus = false;
    }
    if (currentUrl === '/funds-and-solutions/tools/fund-comparator') {
      this.compareButtonStatus = false;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
    this.isFundInWatchlist = this.watchListService.isFundInWatchlist(this.fund);
    this.componentType = this.brConfiguration?.config.componentType;

    if (this.componentType === FundListingComponentType.NAV) {
      if (this.fund?.shareClassesChunksData?.length > 1) {
        this.fund.combinedShareClasses =
          this.fund.shareClassesChunksData.reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
      } else {
        this.fund.combinedShareClasses =
          this.fund.shareClassesChunksData[0] || [];
      }
    } else if (this.componentType === FundListingComponentType.IDCW) {
      this.selectedShareClass = this.fund?.shareClassesData?.[0]?.shareClassName;
      // Delete the first empty element created during initialisation.
      this.distributionDataAsLists.shift();

      this.fund?.shareClassesData.forEach((shareClass) => {
        const distributionDataAsList: [string, string][] = Object.entries(
          shareClass.distributionData
        );

        this.distributionDataAsLists.push(distributionDataAsList);
      });
    }
  }

  @HostListener("click", ["$event"])
  onClickDateRangePicker(event: Event) {
    if (this.showInvestmentCart) {
      if ((event.target as Element).className == "bi bi-x-circle")
        this.showInvestmentCart = false;
    }

    if (
      (event.target as Element).className ==
      "btn btn-outline-primary cancelForm"
    )
      this.showInvestmentCart = false;

    if (
      (event.target as Element).className ==
      "btn btn-primary makeDataCartToggle"
    )
      this.showInvestmentCart = false;
  }

  toggleWatchListDialoge(type: string, fund?, event?: any) {
    const fundData: WatchListItem = {
      fundId: fund?.fundId,
      fundName: fund?.fundName,
      assetClass: fund?.assetClass,
    };
    if (fund && type === "remove") {
      this.watchListService.addToWatchlist(fundData);
      this.addToaster(type);
      this.commonService.updateWatchList.emit(true);
    }

    if (this.watchListCount < 9) {
      if (type === "add" && fund) {
        this.watchListService.addToWatchlist(fundData);
        if (this.cardType === 'EMPTY_WATCHLIST') {
          this.AddFundOnWatchList = true;
        }
        this.addToaster(type);
        if (event) {
          this.commonService.is_watchList_fund_added.emit(true);
          this.renderer.addClass(event.target, 'disabled');
          event.target.textContent = 'Added';
          if (window.innerWidth < 768) {
            this.commonService.mobileAddFund.emit(true);
          }
        }
      } else {
        this.AddFundOnWatchList = false;
      }
      this.isFundInWatchlist = !this.isFundInWatchlist;
    } else {
      this.watchListFull = !this.watchListFull;
    }

    this.cdr.detectChanges();
    if (type === "add" && fund) {
      this.analyticsService.trackEvent({
        event: 'Event Fund Explorer',
        category: 'Fund Explorer',
        action: 'Add to Watchlist',
        label: this.fund?.fundName,
      });
      this.analyticsService.trackEvent({
        event: 'Event Fund Explorer',
        ft_event: 'ft_Watchlist_Clicked',
        category: 'Fund Explorer',
        action: 'Watchlist',
        label: this.fund?.fundName,
      });
    }
  }

  addFundsToWatchList(fund: any, event?: any) {
    this.toggleWatchListDialoge("add", fund, event);
  }

  toggleShowSavDialoge1() {
    this.showCartSaveDialoge = !this.showCartSaveDialoge;
  }

  async toggleShowCopyDialoge(check): Promise<void> {
    if (check) {
      const baseUrl = this.commonService.getBaseUrl();
      this.linkToCopy = `${baseUrl}${this.fund.fundLink.overview}`;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info("Link copied to clipboard:", this.linkToCopy);
      } catch (err) {
        logger.error("Unable to copy to clipboard", err);
      }
    }
    this.showCopyDialoge = check;
  }

  public getEndOfLastQuarter(): string {
    const quarterAdjustment = (moment().month() % 3) + 1;
    const lastQuarterEndDate = moment()
      .subtract({months: quarterAdjustment})
      .endOf("month");

    return lastQuarterEndDate.format("L");
  }

  getRisk(productLabel?: string) {
    if (productLabel) return this.commonService.getRisk(productLabel);
  }

  routeModuleCompareFund() {
    this._router.navigate([this.comparePageUrl]);
  }

  routeModuleWatchList() {
    this._router.navigate([this.watchListPageUrl]);
  }

  contentOnHoverCLose(param: any) {
    document.getElementById(param)?.click();
  }

  fundDetails(param: any) {
    if (this.isNFO && this.nfoToMicrosite === 'true') {
      window.location.href = this.nfoToMicrositeURL;
    } else {
      this._router.navigateByUrl(param?.fundLink?.overview, {
        state: {...param},
      });
    }
    this.analyticsService.trackEvent({
      event: 'Event Fund Explorer',
      category: 'Fund Explorer',
      action: 'Fund details',
      label: this.fund?.fundName,
    });
    this.analyticsService.trackEvent({
      event: "Event Fund Explorer",
      ft_event: 'ft_KnowMore_Clicked',
      category: "Homepage",
      action: this.fund?.fundName,
      label: 'KnowMore',
    });
  }

  routeToFund() {
    this.analyticsService.trackEvent({
      event: 'Event Fund Explorer',
      category: 'Fund Explorer',
      action: 'click',
      label: this.fund?.fundName,
    });
  }

  scrollToPerformance(param: any) {
    // Navigate to the fund overview page with additional parameters
    this._router.navigate([param?.fundLink?.overview], {
      queryParams: {scrolltoperf: true},
    });
  }

  updateSelectedDropdown(dropdown: any): void {
    this.fund.selectedDropdown = dropdown;
    this.analyticsService.trackEvent({
      event: 'Event fundexplorer',
      category: 'Fund Explorer',
      action: 'Returns Period',
      label: this.fund?.selectedDropdown?.label,
    });
  }

  investNow(fund) {
    if (fund.enableInvestNow) {
      this.analyticsService.trackEvent({
        event: "Event fundexplorer",
        ft_event: 'ft_CTA_clicked',
        category: "Fund Explorer",
        action: "Invest Now",
        label: fund.fundName,
      });

      this.investNowService.investNow(this.fund?.fundId, this.fund?.fundName);
    } else {
      return;
    }
  }

  shareOnApp(appName, fund): void {
    this.commonService.shareOn(appName, fund?.fundLink?.overview);
  }

  addToCart(param: any) {
    this.getFundsData = param;
    this.commonService.cartdetails.push(param);
    const cartObj = [param];
    sessionStorage.setItem("cart", JSON.stringify(cartObj));
    this.selectedCartDetails = cartObj;
    this.showInvestmentCart = true;
    if (this.hasFundBtn == true) {
      const makeOffcanvasToggle = true;
      this.makeOffCanvasOutput.emit(makeOffcanvasToggle);
      this.selectedCartDetails = param;
    }
  }

  addToOutput(param: any) {
    this.addToCartOutput.emit(param);
  }

  ngOnInit() {
    this.isFundInWatchlist = this.watchListService.isFundInWatchlist(this.fund); // To Check whether this fund is already in watchlist or not
    this.isFundInComparelist = this.fundCompareService.isFundInCompareList(this.fund); // To Check whether this fund is already in compare card or not
    this.fundCompareService.compareListCount$.subscribe((count) => {
      this.compareListFundsCount = count; // Dynamically getting the count of Compare list funds count
    });
    this.watchListService.watchlistCount$.subscribe((count) => {
      this.watchListCount = count; // Dynamically getting the count of watchlist funds count
    });

    this.comparePageUrl = this.translateService.instant(
      "products.fund-listing-compare-url"
    );
    this.watchListPageUrl = this.translateService.instant("products.watch-list-url");
    this.hisNavButtonLabel = this.translateService.instant("products.fund-listing-button-historical-navs");
    this.hisIDCWButtonLabel = this.translateService.instant("products.fund-listing-button-historical-idcw");
    this.naLabel = this.translateService.instant("products.fund-header-na");
    this.watchListSuccessMsg = this.translateService.instant("products.funds-home-card-watchlist-success");
    this.watchListRemoveMsg = this.translateService.instant("products.funds-home-card-watchlist-remove");
    this.compareSuccessMsg = this.translateService.instant("products.funds-home-card-compare-success");
    this.compareRemoveMsg = this.translateService.instant("products.funds-home-card-compare-remove");
    this.gotoWatchList = this.translateService.instant("products.funds-home-card-go-watchlist-btn");
    this.gotoCompareList = this.translateService.instant("products.funds-home-card-goto-compare");
    this.nfoFundList = this.translateService.instant('products.nfo-fund-code-list');
    this.displayBlockedFunds = this.translateService.instant('products.blocked-fund-code-list');
    this.nfoToMicrosite = this.translateService.instant('products.nfo-to-micro-site');
    this.nfoToMicrositeURL = this.translateService.instant('products.nfo-micro-site-url');
    if (this.nfoFundList.indexOf(this.fund.fundId) !== -1) {
      this.isNFO = true;
    }
    if (this.displayBlockedFunds.indexOf(this.fund.fundId) !== -1) {
      this.blockedFund = true;
    }
    this.idcwConfig = {
      "componentType": "IDCW History",
      "fundId": this.fund?.fundId
    }
    this.navConfig = {
      "componentType": "Daily Nav",
      "fundId": this.fund?.fundId
    }
  }


  addToCompareList(fund: any, event?: any) {
    // Add funds and set localStorage value
    let detStorage = JSON.parse(localStorage.getItem("compare-funds"));
    let funds: FundCompareItem = {fundId: fund?.fundId, fundName: fund.fundName};

    if (detStorage === null) {
      // localStorage.setItem("compare-funds", JSON.stringify([funds]));
      this.fundCompareService.addToCompareList(funds);
      this.commonService.send_fund_data.emit(funds);
    } else {
      if (detStorage.length < 3) {
        if (detStorage.length === 0) {
          detStorage.push(funds);
          detStorage.isTrue = true;
        } else {
          detStorage.push(funds);
          detStorage.isTrue = true;
        }

        // localStorage.setItem("compare-funds", JSON.stringify(detStorage));
        this.fundCompareService.addToCompareList(funds);
        this.commonService.send_fund_data.emit(detStorage);
        if (window.innerWidth < 768) {
          this.commonService.mobileAddFund.emit(true);
        }
        this.renderer.addClass(event.target, "disabled");
        event.target.textContent = "Added";
        // this.addComparedCardAlert = !this.addComparedCardAlert; // Code for old model
        this.addCompareToaster('add');
      } else {
        this.compareFull = !this.compareFull;
        //alert("hello")
      }
    }
  }

  toggleShowComparedCardDialoge() {
    this.addComparedCardAlert = !this.addComparedCardAlert;
  }

  // Solution page
  addFundsToSolution(fund: any) {
  }

  removeFundsFromSolution(fund: any) {
  }

  scrollToRisks(fund) {
    // Navigate to the fund overview page with additional parameters
    this._router.navigate([fund?.fundLink?.overview], {
      queryParams: {scrolltorisks: true},
    });
  }

  toggleShowShareDialoge() {
    this.showShareDialoge = false;
  }

  toggleNewShareDataModal() {
    this.showShareDialoge = !this.showShareDialoge;
    const baseUrl = this.commonService.getBaseUrl();
    this.linkToCopy = `${baseUrl}${this.fund.fundLink.overview}`;
    navigator.clipboard.writeText(this.linkToCopy);
  }

  toggleShowComparedDialoge(type: string, fund?) {
    if (type === "add" && this.compareListFundsCount === 3) {
      this.compareFull = !this.compareFull;
      return
    }
    if (fund) {
      this.fundCompareService.addToCompareList({fundId: fund?.fundId, fundName: fund?.fundName});
    }
    this.isFundInComparelist = !this.isFundInComparelist;
    if (type === "add") {
      this.addCompareToaster('add');
    } else if (type === 'remove') {
      this.addCompareToaster('remove');
    }
    if (type === "add") {
      this.analyticsService.trackEvent({
        event: 'Event fundexplorer',
        ft_event: 'ft_compare_clicked',
        category: 'Fund Explorer',
        action: 'Compare',
        label: this.fund?.fundName,
      });
    }
  }

  closeAddWatchListDialoge(type: string) {
    if (type === "close") {
      this.showSaveDialoge = !this.showSaveDialoge;
    }
  }

  closeRemoveWatchListDialoge(type: string) {
    if (type === "close") {
      this.showRemoveDialoge = !this.showRemoveDialoge;
    }
  }

  closeRemoveCompareDialoge(type: string) {
    if (type === "close") {
      this.removeCompareDialoge = !this.removeCompareDialoge;
    }
  }

  closeAddCompareDialoge(type: string) {
    if (type === "close") {
      this.showComparedDialoge = !this.showComparedDialoge;
    }
  }

  closeCompareOrWatchlistFull(type: string) {
    if (type === "compare") {
      this.compareFull = !this.compareFull;
    }
    if (type === "watchlist") {
      this.watchListFull = !this.watchListFull;
    }
  }

  protected readonly location = location.href;

  translateKey(key: string) {
    let finalKey = `products.idcw-card-row-${key}`;
    return this.translateService.instant(finalKey);
  }

  openIDCWModal() {
    this.idcwModal = true;
  }

  openNAVModal() {
    this.navModal = true;
  }

  receiveDataFromIDCW(data: boolean) {
    this.idcwModal = data;
  }

  receiveDataFromNAV(data: boolean) {
    this.navModal = data;
  }

  changeSelection(event: any) {
    this.selectedShareClass = event?.target?.value;
    this.defaultOptionForShareClassName = this.fund.shareClassesData.findIndex(
      (shareClass) => shareClass.shareClassName === this.selectedShareClass
    );
    this.cdr.detectChanges();
  }

  trackByFundId(index: number, shareClass: any): string {
    return shareClass.fundId;
  }

  trackByIndexId(index: number, item: any): number {
    return index;
  }

  addToaster(type: string) {
    // Define message templates
    const successMsgTemplate = (msg: string) => `
        <div class="content">
    <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img"><br>
    <span>${msg}</span></div>
    `;

    const successMsgWithButtonTemplate = (msg: string) => `
    <div class="content">
      <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
      <span>${msg}</span>
      <a class="btn btn-primary">${this.gotoWatchList}</a>
    </div>
    `;

    // Define message content based on type
    let msg: string;
    if (this.buttonStatus) {
      msg =
        type === "add"
          ? successMsgWithButtonTemplate(this.watchListSuccessMsg)
          : successMsgWithButtonTemplate(this.watchListRemoveMsg);
    } else {
      msg =
        type === "add"
          ? successMsgTemplate(this.watchListSuccessMsg)
          : successMsgTemplate(this.watchListRemoveMsg);
    }

    // Show the toast notification
    const toast = this.toasterService.success(msg);

    // Get the toast container
    const toastContainer = document.querySelector('.toast-container');

    // Attach click event listener to the toast container
    toastContainer.addEventListener('click', (event) => {
      const target = event.target as HTMLElement;
      const button = target.closest('.btn.btn-primary') as HTMLButtonElement;
      if (button) {
        this.routeModuleWatchList();
      }
    });
  }

  removeFundString(value: string) {
    value = value?.replace(/funds|fund/gi, "");
    return value;
  }

  addCompareToaster(type: string) {
    // Define message templates
    const successMsgTemplate = (msg: string) => `
        <div class="content">
    <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img"><br>
    <span>${msg}</span></div>
    `;

    const successMsgWithButtonTemplate = (msg: string) => `
    <div class="content">
      <img alt="image" src="assets/images/general/check_circle.svg" class="checked-img">
      <span>${msg}</span>
      <a class="btn btn-primary">${this.gotoCompareList}</a>
    </div>
    `;

    // Define message content based on type
    let msg: string;
    if (this.compareButtonStatus) {
      msg =
        type === "add"
          ? successMsgWithButtonTemplate(this.compareSuccessMsg)
          : successMsgWithButtonTemplate(this.compareRemoveMsg);
    } else {
      msg =
        type === "add"
          ? successMsgTemplate(this.compareSuccessMsg)
          : successMsgTemplate(this.compareRemoveMsg);
    }

    // Show the toast notification
    const toast = this.toasterService.success(msg);

    // Get the toast container
    const toastContainer = document.querySelector('.toast-container');

    // Attach click event listener to the toast container
    toastContainer.addEventListener('click', (event) => {
      const target = event.target as HTMLElement;
      const button = target.closest('.btn.btn-primary') as HTMLButtonElement;
      if (button) {
        this.routeModuleCompareFund();
      }
    });
  }

}
