<!-- start category filter-->
<form [formGroup]="cartDetailsForm">
    <div class="FT-general-filter cart-aside show">
        <div class="filter-box">
            <div class="filter-content">
                <div class="d-flex justify-content-between mb-3 align-items-start">
                    <h3 class="mb-0"><span class="forTitle">{{ "products.add-cart-title" | translate }}</span> <span
                            class="text-blue">{{getTitile}}</span></h3>
                    <button class="btn btn-transparent" (click)="toggleCart()"><i class="bi bi-x-circle"></i></button>
                </div>
                <div>
                    <span class="text-bold">{{ "products.add-cart-heading" | translate }}</span>
                    <div class="form-check form-check-inline ms-4">
                        <input class="form-check-input" type="radio"  id="flexRadioDefault1" value="growth"
                            formControlName="planOption" checked>
                        <label class="form-check-label" for="flexRadioDefault1">
                            {{ "products.add-cart-heading-growth" | translate }}
                        </label>
                    </div>
                    <div class="form-check form-check-inline ms-4">
                        <input class="form-check-input" type="radio" id="flexRadioDefault2" value="Direct"
                            formControlName="planOption">
                        <label class="form-check-label" for="flexRadioDefault2">
                            {{ "products.add-cart-heading-direct" | translate }}
                        </label>
                    </div>
                </div>
                <div class="text-center">
                    <ul class="nav nav-tabs graph-table-btns mb-3" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                                aria-selected="true">{{ "products.add-cart-sip-investment" | translate }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile-tab-pane" type="button" role="tab"
                                aria-controls="profile-tab-pane" aria-selected="false">
                                {{ "products.add-cart-lump-investment" | translate }}</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                        tabindex="0">
                        <div class="cart-box mb-3">
                            <div class="text-big mb-3"> {{ "products.add-cart-investment-amt" | translate }}</div>
                            <div class="form-group price-input mb-1">
                                <img alt="image" src="assets/images/fund/price-icon.svg"><input type="text" class="form-control"
                                    formControlName="amount">
                            </div>
                            <div class="text-small mb-3">
                                {{ "products.add-cart-investment-amt-para" | translate }}
                            </div>
                            <div class="custom-check-box">
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price1">
                                    <label class="form-check-label" for="price1">
                                        20,000
                                    </label>
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price2">
                                    <label class="form-check-label" for="price2">
                                        80,000
                                    </label>
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price3">
                                    <label class="form-check-label" for="price3">
                                        1,20,000
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="cart-box mb-3">
                            <div class="text-big mb-3">
                                {{ "products.add-cart-heading2" | translate }}   <span class="img-with-tooltip">
                                    <img alt="image" src="assets/images/home/info-icon2.svg"  style="padding-bottom: 3px;">
                                    <!-- <img alt="image" src="assets/images/fund/dark-information-icon.png" class="dark-mode-icon"> -->
                                    <span class="tooltip-block">
                                        {{ "products.add-cart-heading2-para" | translate }}
                                    </span>
                                </span>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group date-input mb-3">
                                        <input type="date" class="form-control" value="28/06/2023">
                                    </div>
                                </div>
                            </div>

                            <div class="custom-check-box mb-3">
                                <div class="text-bold mb-2">{{ "products.add-cart-frequency-heading" | translate }}</div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" id="Monthly" formControlName="sipfrequency" value="monthly">
                                    <label class="form-check-label" for="Monthly">
                                        {{ "products.add-cart-frequency-monthly" | translate }}
                                    </label>
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" id="Daily" formControlName="sipfrequency" value="daily">
                                    <label class="form-check-label" for="Daily">
                                        {{ "products.add-cart-frequency-daily" | translate }}
                                    </label>
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" id="Weekly" formControlName="sipfrequency" value="weekly">
                                    <label class="form-check-label" for="Weekly">
                                        {{ "products.add-cart-frequency-weekly" | translate }}
                                    </label>
                                    
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" id="Quarterly" formControlName="sipfrequency" value="quarterly">
                                    <label class="form-check-label" for="Quarterly">
                                        {{ "products.add-cart-frequency-quarterly" | translate }}
                                    </label>
                                </div>
                            </div>
                            <div class="text-small">
                                {{ "products.add-cart-heading3" | translate }}
                                
                            </div>
                        </div>
                        <div class="cart-box mb-4">
                            {{ "products.add-cart-sip-Until" | translate }} <div class="text-big mb-3">
                                <span class="img-with-tooltip">
                                    <img alt="image" src="assets/images/home/info-icon2.svg" style="padding-bottom: 3px;">
                                    <!-- <img alt="image" src="assets/images/fund/dark-information-icon.png" class="dark-mode-icon"> -->
                                    <span class="tooltip-block" style="margin-left: 62px;">
                                        {{ "products.add-cart-sip-Until-para1" | translate }}
                                    </span>
                                </span>
                            </div>

                            <div class="mb-3 d-flex flex-row justify-content-between flex-wrap">
                                <div class="form-check form-check-inline mr-0">
                                    <input class="form-check-input" type="radio" formControlName="sipUntil" id="cancelled"
                                        value="untilCancelled" (click)="timeState='none'">
                                    <label class="form-check-label" for="cancelled">
                                        {{ "products.add-cart-sip-Until-option1" | translate }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline mr-0">
                                    <input class="form-check-input" type="radio" formControlName="sipUntil"
                                        value="Installment" id="Installment" (click)="timeState='installment'">
                                    <label class="form-check-label" for="Installment">
                                        {{ "products.add-cart-sip-Until-option2" | translate }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline mr-0">
                                    <input class="form-check-input" type="radio" id="Years" formControlName="sipUntil"
                                        value="Years" (click)="timeState='years'">
                                    <label class="form-check-label" for="Years">
                                        {{ "products.add-cart-sip-Until-option3" | translate }}
                                    </label>
                                </div>
                                <div class="form-check form-check-inline mr-0">
                                    <input class="form-check-input" type="radio" id="date" formControlName="sipUntil"
                                        value="date" (click)="timeState='date'">
                                    <label class="form-check-label" for="date">
                                        {{ "products.add-cart-sip-Until-option4" | translate }}
                                    </label>
                                </div>
                                <input *ngIf="timeState ==='years' || timeState === 'installment'" type="text" pattern="[0-9]+" formControlName="sipTimeFrame"
                                    class="form-control" placeholder="{{'Enter no of ' +  timeState}}">
                                <input *ngIf="timeState === 'date'" [type]="defaultText" (focus)="onInputFocus()" placeholder="Enter Date" class="form-control">
                            </div>
                            <div class="text-small">
                                {{ "products.add-cart-sip-Until-para2" | translate }}
                            </div>
                        </div>
                        <div class="form-check d-flex">
                            <div>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="getCheckBoxValue($event)">
                                <label class="form-check-label" for="flexCheckDefault">
                                    {{ "products.add-cart-sip-check-box" | translate }}
                                </label>
                            </div>
                            <div *ngIf="getPercentageByCheckox">
                                <!-- <div class="custom-toggle">
                                    <label class="toggle-label" [ngClass]="toggleState ? 'active' : 'inactive'">
                                      <input type="checkbox" id="toggleSwitch" (change)="changeState()" [(ngModel)]="toggleState" name="toggleSwitch">
                                      <span class="slider"></span>
                                      <span class="toggle-text">{{ toggleState ? 'A' : 'I' }}</span>
                                    </label>
                                  </div>                                   -->
                                  <div class="text-center ms-1 me-1">
                                    <ul class="nav nav-tabs graph-table-btns mb-3" id="myTab" role="tablist" style="margin-top: 11px;border-radius: 20px;width: 92px;">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" (click)="inputState = 'percentage'" id="home-tab1" data-bs-toggle="tab"
                                                data-bs-target="#home-tab-pane2" type="button" role="tab" aria-controls="home-tab-pane" style="border-radius: 20px;"
                                                aria-selected="true">%</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" (click)="inputState = 'Rupay'" id="profile-tab1" data-bs-toggle="tab"
                                                data-bs-target="#profile-tab-pane1" type="button" role="tab"
                                                aria-controls="profile-tab-pane" style="border-radius: 20px;" aria-selected="false">
                                                <img alt="image" src="assets/images/fund/price-icon.svg"></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="getPercentageByCheckox" class="cart-box mb-3" style="background: none; box-shadow: none; padding: 1px;">
                                <div class="form-group price-input" *ngIf="inputState === 'percentage'">
                                    <input type="text" class="form-control" value="15" style="width: 29%;" max="100">
                                    %
                                </div>
                                <div class="form-group price-input" *ngIf="inputState === 'Rupay'">
                                    <img alt="image" src="assets/images/fund/price-icon.svg">
                                    <input type="text" class="form-control" value="20,000">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                        tabindex="0">

                        <!-- <div class="cart-box mb-3">
                            <div class="text-big mb-3">Investment Amount</div>
                            <div class="form-group price-input">
                                <input type="text" class="form-control" value="20,000">
                                <img alt="image" src="assets/images/fund/price-icon.svg">
                            </div>
                            <div class="text-small mb-3">
                                Will this investment meet your financial goal?
                            </div>
                            <div class="custom-check-box">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price1">
                                    <label class="form-check-label" for="price1">
                                        20,000
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price2">
                                    <label class="form-check-label" for="price2">
                                        80,000
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price3">
                                    <label class="form-check-label" for="price3">
                                        1,20,000
                                    </label>
                                </div>
                            </div>
                        </div> -->

                        <div class="cart-box mb-3">
                            <div class="text-big mb-3">{{ "products.add-cart-investment-amt" | translate }}</div>
                            <div class="form-group price-input mb-1">
                                <img alt="image" src="assets/images/fund/price-icon.svg"><input type="text" class="form-control"
                                    formControlName="amount">
                            </div>
                            <div class="text-small mb-3">
                                {{ "products.add-cart-investment-amt-para" | translate }}
                            </div>
                            <div class="custom-check-box">
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price1">
                                    <label class="form-check-label" for="price1">
                                        20,000
                                    </label>
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price2">
                                    <label class="form-check-label" for="price2">
                                        80,000
                                    </label>
                                </div>
                                <div class="form-check custom-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="price3">
                                    <label class="form-check-label" for="price3">
                                        1,20,000
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="filter-footer">
                <button class="btn btn-outline-primary" *ngIf="!hasCancelSaveBtn">{{ "products.funds-home-invest-now" | translate }}</button>
                <button class="btn btn-outline-primary cancelForm" *ngIf="hasCancelSaveBtn" (click)="cancelInvestForm()" >{{ "products.all-funds-cancel-btn" | translate }}</button>
                     <ng-template *ngIf="hasInvestmentBtn === false; then FUND_CARD1_BTN else INVESTMENT_CARD_BTN"></ng-template>
                <ng-template #FUND_CARD1_BTN>
                    <a class="btn btn-primary"  (click)="addCartDetails($event)">{{ "products.home-fund-card-add-to-cart-btn" | translate }}</a>
                </ng-template>
                <ng-template #INVESTMENT_CARD_BTN>
                    <a class="btn btn-primary makeDataCartToggle" *ngIf="!hasCancelSaveBtn && !investmentCartAddForm" (click)="addInvestmentDetails($event)" >{{ "products.home-fund-card-add-to-cart-btn" | translate }}</a>
                    <a class="btn btn-primary makeDataCartToggle" *ngIf="!hasCancelSaveBtn && investmentCartAddForm" [routerLink]="['/investment-cart']" (click)="addInvestmentDetails2($event)" >{{ "products.home-fund-card-add-to-cart-btn" | translate }}</a>
                    <a class="btn btn-primary" *ngIf="hasCancelSaveBtn" (click)="addInvestmentDetails($event)" >{{ "products.add-cart-save-button" | translate }}</a>
                </ng-template>
                <!-- <button class="btn btn-primary" (click)="addCartDetails($event)">Add to Cart</button> -->
            </div>
        </div>
    </div>
</form>
