<div class="fund-card-wrapper" *ngIf="!blockedFund">
  <div class="fund-block position-relative" [ngStyle]="{'padding-top': (fund?.badge)? '40px': '20px'}">
    <span *ngIf="fund?.badge" class="recommend-tag">{{ fund?.badge }}</span>
    <div class="d-flex justify-content-between fund-head" style="margin-top: 5px;">
      <!-- Card Top content wrap :Web -->
      <div class="fund-card-head">
        <div class="fund-card-rist-title">
          <h3 class="fund-card-title mb-0">
            <!-- Card Title -->
            <a class="forfunddcardtitle">
              <a class="title-text" data-bs-dismiss="offcanvas"
                 (click)="fundDetails(fund)">{{ fund?.fundName }}</a>&nbsp;&nbsp;
            </a>
          </h3>
          <!-- Risk Meter -->
          <span class="img-with-tooltip makeColorForTitle" *ngIf="getRisk(fund?.productLabel)">
            <img alt="riskometer" (click)="scrollToRisks(fund)" src="assets/images/general/very-high.webp"
                 style="width: 33px;margin-left: 5px;">
            <span class="tooltip-block makeTootipFundVisibleCard">
              <img alt="image" class="risk-meter" src="assets/images/general/very-high.webp">
              {{ getRisk(fund?.productLabel) }}
            </span>
          </span>
        </div>
        <!-- Fund config -->
        <div class="row">
          <div [ngClass]="componentType === 'IDCW'?'col-md-9 col-12':'col-auto'">
            <div class="tags mx-2">
              <ul>
                <li style="white-space: nowrap;">{{ removeFundString(fund?.assetClass) }}</li>
                <li style="white-space: nowrap;">{{ removeFundString(fund?.invfocus) }}</li>
                <li style="white-space: nowrap;">{{ fund?.fundInceptionDate | yearsOld }}</li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-12" *ngIf="componentType === 'IDCW'">
            <div class="">
              <div class="form-group" *ngIf="fund?.shareClassesData.length">
                <select class="form-select fund-drop-down" aria-label="Default select example"
                        (change)="changeSelection($event)">
                  <option *ngFor="let item of fund?.shareClassesData"
                          [value]="item?.shareClassName">{{ item?.shareClassName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="icons" *ngIf="hasThreeDotOptions">
        <!-- Action: Web -->
        <div class="d-none d-lg-inline-block">
          <button (click)="toggleWatchListDialoge('add', fund)" *ngIf="!isFundInWatchlist">
            <img alt='image' src="assets/images/general/mark-icon.webp">
            <span class="tooltip-box">{{ "products.home-fund-card-add-to-wlist-btn" | translate }}</span>
          </button>
          <button (click)="toggleWatchListDialoge('remove',fund)" *ngIf="isFundInWatchlist">
            <img alt='image' src="assets/images/general/Frame1000006555.svg">
            <span class="tooltip-box">{{ "products.home-fund-card-added-to-wlist-btn" | translate }}</span>
          </button>

          <button *ngIf="cardType !== 'SOLUTION' && !isFundInComparelist"
                  (click)="toggleShowComparedDialoge('add', fund)">
            <img alt='image' src="assets/images/general/change-icon.svg">
            <span class="tooltip-box">{{ "products.home-fund-card-compare-btn" | translate }}</span>
          </button>

          <button *ngIf="cardType !== 'SOLUTION' && isFundInComparelist"
                  (click)="toggleShowComparedDialoge('remove', fund)">
            <img alt='image' src="assets/images/general/_Checkboxbase.svg">
            <span class="tooltip-box">{{ "products.home-fund-card-added-compare-btn" | translate }}</span>
          </button>

          <!--<button (click)="addToCart(fund)">
            <img alt='image' src="assets/images/general/cart-icon.svg"><span
              class="new-badge">{{ "products.home-fund-card-new-badge" | translate }}</span>
            <span class="tooltip-box">{{ "products.home-fund-card-add-to-cart-btn" | translate }}</span>
          </button>-->
          <button>
            <div class="dropdown share-menu">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="{{'share-button'+fund?.fundId}}"
                      data-bs-toggle="dropdown" aria-expanded="false">
                <!-- <img alt='image' src="assets/images/general/share-icon.webp"><span
                   class="tooltip-box" style="left:-45px">{{ "products.home-fund-card-share-btn" | translate }}</span>-->

                <img alt='image' src="assets/images/general/share-icon.webp"
                     class="d-inline-block mobile-icons light-mode-icon" style="width: 22px;">
                <img alt='image' src="assets/images/dark/dark-share-icon.png"
                     class="mobile-icons dark-mode-icon" style="width: 19px;">
                <span class="tooltip-box"
                      style="left:-45px">{{ "products.home-fund-card-share-btn" | translate }}</span>

              </button>
              <ul class="dropdown-menu" style="z-index: 1026;" [hidden]=""
                  (mouseleave)="contentOnHoverCLose('share-button'+fund)">
                <li><a class="dropdown-item fund-light-mode-icon" (click)="toggleShowCopyDialoge(true)">
                  <img alt="copy"
                       src="assets/images/general/Link.webp">{{ "products.home-fund-card-share-copy-btn" | translate }}</a>
                  <a class="dropdown-item fund-dark-mode-icon" (click)="toggleShowCopyDialoge(true)">
                    <img alt="share"
                         src="assets/images/dark/link-dark.png">{{ "products.home-fund-card-share-copy-btn" | translate }}</a>
                </li>

                <li><a class="dropdown-item fund-light-mode-icon" (click)="shareOnApp('TWITTER',fund)"><img
                    alt="twitter" src="assets/images/general/twitter-new-icon.webp"
                    style="width: 22px;">{{ "products.home-fund-card-share-twitter-btn" | translate }}</a>
                  <a class="dropdown-item fund-dark-mode-icon" (click)="shareOnApp('TWITTER',fund)"><img alt="twitter"
                                                                                                         src="assets/images/general/twitter-new-icon.webp"
                                                                                                         class="x-logo-dark">{{ "products.home-fund-card-share-twitter-btn" | translate }}</a>
                </li>
                <li><a class="dropdown-item fund-light-mode-icon" (click)="shareOnApp('FACEBOOK',fund)"><img
                    alt="facebook"
                    src="assets/images/general/FacebookLogo.webp">{{ "products.home-fund-card-share-fb-btn" | translate }}</a>
                  <a class="dropdown-item fund-dark-mode-icon" (click)="shareOnApp('FACEBOOK',fund)"><img alt="facebook"
                                                                                                          src="assets/images/general/footer-facebook.webp">{{ "products.home-fund-card-share-fb-btn" | translate }}</a>

                </li>
                <li><a class="dropdown-item fund-light-mode-icon" (click)="shareOnApp('LINKEDIN',fund)"><img
                    alt="linkedin"
                    src="assets/images/general/LinkedinLogo.webp">{{ "products.home-fund-card-share-linkedin-btn" | translate }}</a>
                  <a class="dropdown-item fund-dark-mode-icon" (click)="shareOnApp('LINKEDIN',fund)"><img alt="linkedin"
                                                                                                          src="assets/images/general/footer-linkedin.webp">{{ "products.home-fund-card-share-linkedin-btn" | translate }}</a>
                </li>
                <li><a class="dropdown-item fund-light-mode-icon" (click)="shareOnApp('WHATAPP',fund)"><img
                    alt="whatsapp"
                    src="assets/images/general/whatsapps.webp">{{ "products.home-fund-card-share-whatsapp-btn" | translate }}</a>
                  <a class="dropdown-item fund-dark-mode-icon" (click)="shareOnApp('WHATAPP',fund)"><img alt="whatsapp"
                                                                                                         src="assets/images/general/ri_whatsapp-fill.webp">{{ "products.home-fund-card-share-whatsapp-btn" | translate }}</a>
                </li>
                <!--
                <li><a class="dropdown-item" (click)="shareOnApp('TWITTER',fund)">
                  <img
                      src="assets/images/general/TwitterLogo.svg">{{ "products.home-fund-card-share-twitter-btn" | translate }}</a>
                </li>
                <li><a class="dropdown-item" (click)="shareOnApp('FACEBOOK',fund)">
                  <img
                      src="assets/images/general/FacebookLogo.webp">{{ "products.home-fund-card-share-fb-btn" | translate }}</a>
                </li>
                <li><a class="dropdown-item" (click)="shareOnApp('LINKEDIN',fund)">
                  <img
                      src="assets/images/general/LinkedinLogo.webp">{{ "products.home-fund-card-share-linkedin-btn" | translate }}</a>
                </li>
                <li><a class="dropdown-item" (click)="shareOnApp('WHATAPP',fund)">
                  <img
                      src="assets/images/general/whatsapps.webp">{{ "products.home-fund-card-share-whatsapp-btn" | translate }}</a>
                </li>-->
              </ul>
            </div>
          </button>
        </div>

        <!-- Action: Mob -->
        <div class="dropdown d-inline-block d-lg-none action-menu-mob">
          <button class=" dropdown-toggle me-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img alt='image' src="assets/images/general/dots.webp">
          </button>
          <ul class="dropdown-menu">
            <!-- <li class="d-none d-md-block">
               <a class="dropdown-item">
                 <i class="bi bi-cart2"></i>{{ "products.home-fund-card-add-to-cart-btn" | translate }}
               </a>
             </li>-->
            <li>
              <a class="dropdown-item" *ngIf="!isFundInComparelist" (click)="toggleShowComparedDialoge('add', fund)">
                <img alt="Compare"
                     src="assets/images/general/compare-icon.webp">{{ "products.home-fund-card-compare-btn" | translate }}
              </a>
              <a class="dropdown-item remove-from-watchlist" *ngIf="isFundInComparelist"
                 (click)="toggleShowComparedDialoge('remove', fund)">
                <img alt="Compare"
                     src="assets/images/general/_Checkboxbase.svg">{{ "products.home-fund-card-added-compare-btn" | translate }}
              </a>
            </li>
            <li>
              <span class="dropdown-item d-block" (click)="toggleWatchListDialoge('add', fund)"
                    *ngIf="!isFundInWatchlist">
                <img alt="Watchlist"
                     src="assets/images/general/watchlist-icon.webp">{{ "products.home-fund-card-add-to-wlist-btn" | translate }}
              </span>

              <span class="dropdown-item d-block remove-from-watchlist" (click)="toggleWatchListDialoge('remove',fund)"
                    *ngIf="isFundInWatchlist">
                <img alt="Watchlist"
                     src="assets/images/general/Frame1000006555.svg">{{ "products.home-fund-card-added-to-wlist-btn" | translate }}
              </span>
            </li>
            <li>
              <a class="dropdown-item fund-light-mode-icon" (click)="toggleNewShareDataModal()">
                <img alt='image'
                     src="assets/images/general/share-icon-sm.webp">{{ "products.home-fund-card-share-btn" | translate }}
              </a>
              <a class="dropdown-item fund-dark-mode-icon" (click)="toggleNewShareDataModal()">
                <img alt='image'
                     src="assets/images/dark/dark-share-icon.png">{{ "products.home-fund-card-share-btn" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <ng-container *ngIf="componentType === 'PPSS'; else NAVSData">
      <div class="row info justify-content-between">
        <!-- Funds Details: Web -->
        <ul class="col-auto d-none d-md-block mt-2">
          <li>
            <div class="mb-2">{{ "products.funds-home-card-unique-investors" | translate }}
              <span class="img-with-tooltip" *ngIf="fund?.uniqueInvestors !== ''">
                <img alt='image' src="assets/images/home/info-icon2.svg"
                     class="d-inline-block mobile-icons light-mode-icon"
                     style="width: 18px;">
                <img alt='image' src="assets/images/fund/dark-information-icon.png" class="mobile-icons dark-mode-icon"
                     style="width: 14px; top: 8px;">
                <span class="tooltip-block">
                  {{ "products.as-of-capitalize" | translate }}&nbsp;{{ getEndOfLastQuarter() }}
                </span>
              </span>
            </div>
            <div class="text-big">
              <span *ngIf="fund?.uniqueInvestors !== ''" class="d-inline-block d-md-none"><img alt="Unique investors"
                                                                                               src="assets/images/fund/investor-icon.svg"></span>
              {{ fund?.uniqueInvestors !== null && fund?.uniqueInvestors !== undefined && fund?.uniqueInvestors !== '' ? ((fund?.uniqueInvestors).replace(',', '') | INRCurrencyNumberInt) : '-' }}
              <span
                  class="d-inline-block d-md-none">{{ "products.funds-home-card-unique-investors" | translate }}</span>
            </div>
          </li>
          <li>
            <div class="mb-2">{{ "products.funds-home-card-fund-size" | translate }}
              <span class="img-with-tooltip" *ngIf="fund?.aum?.totalNetAssets">
                <img alt='image' src="assets/images/home/info-icon2.svg"
                     class="d-inline-block mobile-icons light-mode-icon"
                     style="width: 18px;">
                <img alt='image' src="assets/images/fund/dark-information-icon.png" class="mobile-icons dark-mode-icon"
                     style="width: 14px; top: 8px;">
                <span class="tooltip-block">
                  {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.aum?.asOfDate }}
                </span>
              </span></div>
            <div class="text-big">
              <span class="d-inline-block d-md-none" *ngIf="fund?.aum?.totalNetAssets">
                <img alt='image' src="assets/images/fund/money-sign.svg">
              </span>
              {{ fund?.aum?.totalNetAssets !== null && fund?.aum?.totalNetAssets !== undefined ? (fund?.aum?.totalNetAssets |INRCurrencyNumber) + ' Cr' : '-' }}
              <span class="d-inline-block d-md-none">{{ "products.funds-home-card-fund-size" | translate }}
                <span class="img-with-tooltip">
                  <img alt='image' src="assets/images/home/info-icon2.svg">
                  <span class="tooltip-block">
                    {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.aum?.asOfDate }}
                  </span>
                </span></span>
            </div>
          </li>
          <li class="last-item">
            <div class="mb-2"
                 style="vertical-align: super;">{{ "products.funds-home-card-annualized-returns" | translate }}
              <span class="img-with-tooltip" *ngIf="fund?.shareClassData?.performanceData?.asOfDate">
                <img alt='image' src="assets/images/home/info-icon2.svg"
                     class="d-inline-block mobile-icons light-mode-icon"
                     style="width: 18px;">
                <img alt='image' src="assets/images/fund/dark-information-icon.png" class="mobile-icons dark-mode-icon"
                     style="width: 14px; top: 8px;">
                <span class="tooltip-block">
                  {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.shareClassData?.performanceData?.asOfDate }}
                </span>
              </span>
            </div>
            <div *ngIf="fund?.selectedDropdown?.assetValue else perfElse">
              <span
                  class="text-big">{{ fund?.selectedDropdown?.assetValue ? fund?.selectedDropdown?.assetValue + '%' : naLabel }}</span>
              <div class="dropdown d-none d-md-inline-block">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  {{ fund?.selectedDropdown?.label }}
                </button>
                <ul class="dropdown-menu cust-dd" aria-labelledby="dropdownMenuButton1">
                  <li *ngFor="let dropdown of fund?.ppssPeriodDropdownItems" [class.disabled]="dropdown?.disabled">
                    <a class="dropdown-item" [class]="dropdown?.disabled?'disabled':'cur-pointer'"
                       (click)="updateSelectedDropdown(dropdown)">{{ dropdown?.label }}</a>
                  </li>
                </ul>
              </div>
              <!--<div class="dropdown Inception-dropdown d-md-none d-inline-block">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  {{ fund?.selectedDropdown?.label }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <li *ngFor="let dropdown of fund?.ppssPeriodDropdownItems">
                    <a class="dropdown-item" (click)="updateSelectedDropdown(dropdown)">{{ dropdown?.label }}</a>
                  </li>
                </ul>
              </div>-->
            </div>
            <ng-template #perfElse>
              <span class="text-big">{{ "products.fund-header-na" | translate }}</span>
            </ng-template>
          </li>
        </ul>
        <!-- Funds Details: Mob -->
        <ul class="col-auto d-md-none">
          <li class="investor" style="vertical-align:sub;padding-right:0; width: 50% !important;">
            <div class="mb-2">
              <img alt='image' src="assets/images/home/info-icon2.svg" class="d-inline-block">
            </div>
            <div class="text-small d-flex" style="align-items: start;flex-flow: column;">
              <span style="white-space: nowrap;">
                <span
                    class="d-inline-block d-md-none">{{ "products.funds-home-card-unique-investors" | translate }}</span>
                <span class="img-with-tooltip" *ngIf="fund?.uniqueInvestors !== ''">
                  <img alt='image' src="assets/images/home/info-icon2.svg"
                       class="d-inline-block mobile-icons light-mode-icon"
                       style="margin-left: 3px; width: 16px;">
                  <img alt='image' src="assets/images/fund/dark-information-icon.png"
                       class="mobile-icons dark-mode-icon"
                       style="margin-left: 5px; width: 13px; top: 8px;">
                  <span class="tooltip-block tooltipblockfortitile">
                    {{ "products.as-of-capitalize" | translate }}&nbsp;{{ getEndOfLastQuarter() }}
                  </span>
                </span>
              </span>
              <span class="d-md-none investor-img" style="position:relative;bottom:3px;">
                <img alt="UI" *ngIf="fund?.uniqueInvestors !== ''" src="assets/images/fund/investor-icon.svg"
                     style="transform: translateY(-3px);">
                {{ fund?.uniqueInvestors !== null && fund?.uniqueInvestors !== undefined && fund?.uniqueInvestors !== '' ? ((fund?.uniqueInvestors).replace(',', '') | INRCurrencyNumberInt) : '-' }}
              </span>
            </div>
          </li>
          <li style="padding-left:10px;width:auto; position: absolute;">
            <div class="mb-2">{{ "products.funds-home-card-fund-size" | translate }}
              <span class="img-with-tooltip">
                <img alt='image' src="assets/images/home/info-icon2.svg"
                     class="d-inline-block mobile-icons light-mode-icon"
                     style="margin-left: 3px; width: 16px;">
                <img alt='image' src="assets/images/fund/dark-information-icon.png"
                     class="mobile-icons dark-mode-icon">
                <span class="tooltip-block">
                  {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.aum?.asOfDate }}
                </span>
              </span>
            </div>

            <div class="text-small d-flex" style="align-items: start;flex-flow: column;">
              <span style="white-space: nowrap;">
                <span
                    class="d-inline-block d-md-none">{{ "products.funds-home-card-fund-size" | translate }}</span>
                <span class="img-with-tooltip" *ngIf="fund?.aum?.totalNetAssets">
                  <img alt='image' src="assets/images/home/info-icon2.svg"
                       class="d-inline-block mobile-icons light-mode-icon"
                       style="margin-left: 3px; width: 16px;">
                  <img alt='image' src="assets/images/fund/dark-information-icon.png"
                       class="mobile-icons dark-mode-icon"
                       style="margin-left: 5px; width: 13px; top: 8px;">
                  <span class="tooltip-block tooltipblockfortitile">
                    {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.aum?.asOfDate }}
                  </span>
                </span>
              </span>
              <span class="d-md-none" style="position:relative;top:2px;">
                <img alt="Rs" *ngIf="fund?.aum?.totalNetAssets" src="assets/images/fund/money-sign.svg">
                {{ fund?.aum?.totalNetAssets !== null && fund?.aum?.totalNetAssets !== undefined ? (fund?.aum?.totalNetAssets | INRCurrencyNumber) + ' Cr' : '-' }}
              </span>
            </div>
          </li>
          <li class="last-item annual-rtn">
            <div class="mb-2 d-flex sx-black-color"
                 style="gap:3px;">{{ "products.funds-home-card-annualized-returns" | translate }}
              <span class="img-with-tooltip" *ngIf="fund?.shareClassData?.performanceData?.asOfDate">
                <img alt='image' src="assets/images/home/info-icon2.svg"
                     class="d-inline-block mobile-icons light-mode-icon"
                     style="margin-left: 3px; width: 16px;">
                <img alt='image' src="assets/images/fund/dark-information-icon.png"
                     class="d-inline-block mobile-icons dark-mode-icon"
                     style="margin-left: 5px; width: 13px; top: 1px;">
                <span class="tooltip-block tooltipblockfortitile">
                  {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.shareClassData?.performanceData?.asOfDate }}
                </span>
              </span>
            </div>
            <div *ngIf="fund?.selectedDropdown?.assetValue else perfElse">
              <span
                  class="text-small">{{ fund?.selectedDropdown?.assetValue ? fund?.selectedDropdown?.assetValue + '%' : naLabel }}</span>
              <!--<div class="dropdown d-none d-md-inline-block">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  {{ fund?.selectedDropdown?.label }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li *ngFor="let dropdown of fund?.ppssPeriodDropdownItems">
                    <a class="dropdown-item" style="cursor: pointer;"
                       (click)="updateSelectedDropdown(dropdown)">{{ dropdown?.label }}</a>
                  </li>
                </ul>
              </div>-->
              <div class="dropdown Inception-dropdown d-md-none d-inline-block">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  {{ fund?.selectedDropdown?.label }}
                </button>
                <ul class="dropdown-menu cust-dd" aria-labelledby="dropdownMenuButton2">
                  <li *ngFor="let dropdown of fund?.ppssPeriodDropdownItems" [class.disabled]="dropdown?.disabled">
                    <a class="dropdown-item" [class]="dropdown?.disabled?'disabled':'cur-pointer'"
                       (click)="updateSelectedDropdown(dropdown)">{{ dropdown?.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <ng-template #perfElse>
              <span class="text-big">{{ "products.fund-header-na" | translate }}</span>
            </ng-template>
          </li>
        </ul>

        <!-- Add to Card: Mob -->
        <!--<div class="d-block d-md-none">
          <button class="btn btn-light-blue">
            <span class="new-badge">{{ "products.home-fund-card-new-badge" | translate }}</span>&nbsp;
            <i class="bi bi-cart3"></i>{{ "products.home-fund-card-add-to-cart-btn" | translate }}
          </button>
        </div>-->

        <!-- Action - 1: Know more & view details -->
        <div class="col-auto block-btns"
             *ngIf="!(cardType === 'EMPTY_COMPARE' || cardType === 'EMPTY_WATCHLIST' || cardType === 'CALCULATOR')">
          <a (click)="fundDetails(fund)" class="btn btn-outline-primary me-2">
            <span class="d-none d-md-block">{{ "products.funds-home-know-more" | translate }}</span>
            <span class="d-md-none">{{ "products.funds-home-know-more" | translate }}</span>
          </a>
          <button class="btn btn-primary" (click)="investNow(fund)" [disabled]="!fund?.enableInvestNow">
            {{ "products.funds-home-invest-now" | translate }}
          </button>
        </div>

        <!-- Action - 2: Add, remove to build plan in solution-->
        <div class="col-auto block-btns" *ngIf="hasAddRemoveButton">
          <a *ngIf="fund.isAdded" (click)="removeFundsFromSolution(fund)" class="btn btn-outline-primary me-2">
            {{ "products.remove-button" | translate }}
          </a>
          <a *ngIf="!fund.isAdded" (click)="addFundsToSolution(fund)" class="btn btn-primary">
            {{ "products.add-button" | translate }}
          </a>
        </div>     <!-- Action: Know more & view details -->
        <div class="col-auto block-btns" *ngIf="cardType === 'EMPTY_COMPARE'" style="margin-left: auto;">
          <a class="btn btn-primary" [class]="!isFundInComparelist === true ? '' : 'disabled'"
             (click)="addToCompareList(fund,$event)">
            {{ !isFundInComparelist === true ? 'Add Now' : 'Added' }}
          </a>
        </div>
        <!-- Watch list add button -->
        <div class="col-auto block-btns" *ngIf="cardType === 'EMPTY_WATCHLIST'">
          <a class="btn btn-primary" [class]="!isFundInWatchlist === true ? '' : 'disabled'"
             (click)="addFundsToWatchList(fund,$event)">
            {{ !isFundInWatchlist === true ? 'Add Now' : 'Added' }}
          </a> <!--[class]="fund?.addBtnStatus === true ? '' : 'disabled'"-->
        </div>
        <!-- Calculator page buttons-->
        <div class="col-auto block-btns" *ngIf="cardType === 'CALCULATOR'">
          <!--<span class="btn btn-outline-primary me-2">Invest Now</span>-->
          <button class="btn btn-outline-primary me-2" (click)="investNow(fund)" [disabled]="!fund?.enableInvestNow">
            {{ "products.funds-home-invest-now" | translate }}
          </button>
          <a (click)="scrollToPerformance(fund)" data-bs-dismiss="offcanvas" class="btn btn-primary">
            <span> {{ "products.check-performance" | translate }}</span>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-template #NAVSData>
      <ng-container *ngIf="componentType === 'LatestNavs' else IDCW">
        <!-- NAVs Data Mobile -->
        <div class="row info justify-content-between">
          <div class="">
            <ul class="col-auto d-md-none fund-detail-mob">
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <ng-container
                    *ngIf="fund.combinedShareClasses && fund.combinedShareClasses.length > 0; else noNAVDataMobile">
                  <ng-container *ngFor="let shareClass of fund.combinedShareClasses; let lastItem = last;
                let i = index; trackBy: trackByFundId">
                    <div ngxSlickItem class="slide">
                      <div>
                        <div class="mb-2">{{ shareClass?.shareClassName }}
                          <span class="img-with-tooltip">
                            <img alt='image' src="assets/images/home/info-icon2.svg"
                                 class="d-inline-block mobile-icons light-mode-icon"
                                 style="width: 18px;">
                            <img alt='image' src="assets/images/fund/dark-information-icon.png"
                                 class="d-inline-block mobile-icons dark-mode-icon mob-dark-icon"
                                 style="width: 14px; top: 8px;">
                            <span class="tooltip-block" [ngClass]="{'cust-left':i===0}">
                              {{ "products.as-of-capitalize" | translate }}&nbsp;{{ shareClass?.navData?.navDate }}
                            </span>
                          </span>
                        </div>
                        <div class="text-big">
                          <span class="d-inline-block d-md-none"></span>
                          ₹{{ shareClass?.navData?.navValue }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noNAVDataMobile>
                  <div style="margin: 20px; padding: 10px;"><h4>{{ "products.fund-header-na" | translate }}</h4></div>
                </ng-template>
              </ngx-slick-carousel>
            </ul>
          </div>
          <!-- Add to Card: Mob -->
          <ng-container>
            <!--<div class="d-block d-md-none">
              <button class="btn btn-light-blue">
                <span class="new-badge">{{ "products.home-fund-card-new-badge" | translate }}</span>&nbsp;
                <i class="bi bi-cart3"></i>{{ "products.home-fund-card-add-to-cart-btn" | translate }}
              </button>
            </div>-->
          </ng-container>

          <!-- Action - 1: Know more & view details -->
          <ng-container>
            <div class="col-auto block-btns d-md-none">
              <a class="btn btn-outline-primary me-2" (click)="fundDetails(fund)">
                <span>{{ "products.navs-idcw-view-details" | translate }}</span>
              </a>
              <a class="btn btn-primary" data-bs-toggle="modal" [attr.data-bs-target]="'#NAVModal' + fund?.fundId"
                 (click)="openNAVModal()">
                {{ componentType === 'LatestNavs' ? hisNavButtonLabel : hisIDCWButtonLabel }}</a>
            </div>
          </ng-container>

        </div>
        <!-- NAVs Data Web -->
        <div class="row info mt-2">
          <div class="col-8">
            <div class="row d-none d-md-block">
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <ng-container *ngIf="fund.combinedShareClasses && fund.combinedShareClasses.length > 0; else noNAVData">
                  <ng-container
                      *ngFor="let shareClass of fund.combinedShareClasses; let lastItem = last; let i = index; trackBy: trackByFundId">
                    <div ngxSlickItem class="slide">
                      <div [ngStyle]="{'border-right': lastItem ? 'none' : '1px solid #7F869C'}">
                        <div class="mb-2">{{ shareClass?.shareClassName }}
                          <span class="img-with-tooltip">
                            <img alt='image' src="assets/images/home/info-icon2.svg"
                                 class="d-inline-block mobile-icons light-mode-icon"
                                 style="width: 18px;">
                            <img alt='image' src="assets/images/fund/dark-information-icon.png"
                                 class="d-inline-block mobile-icons dark-mode-icon"
                                 style="width: 14px; top: 8px;">
                            <span class="tooltip-block" [ngClass]="{'cust-left':i===0}">
                              {{ "products.as-of-capitalize" | translate }}&nbsp;{{ shareClass?.navData?.navDate }}
                            </span>
                          </span>
                        </div>
                        <div class="text-big">
                          <span class="d-inline-block d-md-none"><img alt="Investors"
                                                                      src="assets/images/fund/investor-icon.svg"></span>
                          ₹{{ shareClass?.navData?.navValue }}
                          <span class="d-inline-block d-md-none">{{ shareClass?.shareClassName }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noNAVData>
                  <div style="margin: 20px; padding: 10px;"><h4>{{ "products.fund-header-na" | translate }}</h4></div>
                </ng-template>
              </ngx-slick-carousel>
            </div>
          </div>
          <div class="col-4">
            <!-- Action - 1: Know more & view details -->
            <div class="col-auto block-btns d-none d-md-block text-end">
              <a class="btn btn-outline-primary me-2 " (click)="fundDetails(fund)">
                <span>{{ "products.navs-idcw-view-details" | translate }}</span>
              </a>
              <a class="btn btn-primary " data-bs-toggle="modal" [attr.data-bs-target]="'#NAVModal' + fund?.fundId"
                 (click)="openNAVModal()">
                {{ componentType === 'LatestNavs' ? hisNavButtonLabel : hisIDCWButtonLabel }}</a>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #IDCW>
        <ng-container *ngIf="fund?.shareClassesData && fund?.shareClassesData.length>0 else IDCWNoData">
          <!-- IDCW Data Mobile -->
          <div class="row info justify-content-between">
            <div class="">
              <ul class="col-auto d-md-none fund-detail-mob">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                  <ng-container
                      *ngFor="let key of distributionDataAsLists[defaultOptionForShareClassName] let lastItem = last; let i = index;trackBy:trackByIndexId">
                    <div ngxSlickItem class="slide">
                      <div>
                        <div class="mb-2">{{ translateKey(key[0]) }}
                          <span class="img-with-tooltip">
                            <img alt='image' src="assets/images/home/info-icon2.svg"
                                 class="d-inline-block mobile-icons light-mode-icon"
                                 style="width: 18px;">
                            <img alt='image' src="assets/images/fund/dark-information-icon.png"
                                 class="d-inline-block mobile-icons dark-mode-icon" style="width: 14px; top: 8px;">
                            <span class="tooltip-block">
                              {{ selectedShareClass }}
                            </span>
                          </span>
                        </div>
                        <div class="text-big">
                          <span class="d-inline-block d-md-none"></span>
                          {{ key[1] ? key[1] : naLabel }}
                        </div>
                      </div>

                    </div>
                  </ng-container>
                </ngx-slick-carousel>
              </ul>
            </div>


            <!-- Add to Card: Mob -->
            <ng-container>
              <!--<div class="d-block d-md-none">
                <button class="btn btn-light-blue">
                  <span class="new-badge">{{ "products.home-fund-card-new-badge" | translate }}</span>&nbsp;
                  <i class="bi bi-cart3"></i>{{ "products.home-fund-card-add-to-cart-btn" | translate }}
                </button>
              </div>-->
            </ng-container>

            <!-- Action - 1: Know more & view details -->
            <ng-container>
              <div class="col-auto block-btns d-md-none">
                <a class="btn btn-outline-primary me-2" (click)="fundDetails(fund)">
                  <span>{{ "products.navs-idcw-view-details" | translate }}</span>
                </a>
                <a class="btn btn-primary" (click)="openIDCWModal()" data-bs-toggle="modal"
                   [attr.data-bs-target]="'#IDCWModal' + fund?.fundId">
                  {{ componentType === 'LatestNavs' ? hisNavButtonLabel : hisIDCWButtonLabel }}</a>
              </div>
            </ng-container>

          </div>

          <!-- IDCW Data Web -->
          <div class="row info mt-2">
            <div class="col-8">
              <div class="row d-none d-md-block">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                  <ng-container
                      *ngFor="let key of distributionDataAsLists[defaultOptionForShareClassName] let lastItem = last; let i = index;trackBy:trackByIndexId">
                    <div ngxSlickItem class="slide">
                      <div [ngStyle]="{'border-right': lastItem ? 'none' : '1px solid #7F869C'}">
                        <div class="mb-2">{{ translateKey(key[0]) }}
                          <span class="img-with-tooltip">
                            <img alt='image' src="assets/images/home/info-icon2.svg"
                                 class="d-inline-block mobile-icons light-mode-icon"
                                 style="width: 18px;">
                            <img alt='image' src="assets/images/fund/dark-information-icon.png"
                                 class="d-inline-block mobile-icons dark-mode-icon"
                                 style="width: 14px; top: 8px;">
                            <span class="tooltip-block">
                              {{ selectedShareClass }}
                            </span>
                          </span>
                        </div>
                        <div class="text-big">
                          <span class="d-inline-block d-md-none"><img alt="Investors"
                                                                      src="assets/images/fund/investor-icon.svg"></span>
                          {{ key[1] ? key[1] : naLabel }}
                          <span class="d-inline-block d-md-none">{{ translateKey(key[0]) }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ngx-slick-carousel>

              </div>
            </div>
            <div class="col-4">
              <!-- Action - 1: Know more & view details -->
              <div class="col-auto block-btns d-none d-md-block text-end">
                <a class="btn btn-outline-primary me-2" (click)="fundDetails(fund)">
                  <span>{{ "products.navs-idcw-view-details" | translate }}</span>
                </a>
                <a class="btn btn-primary" (click)="openIDCWModal()" data-bs-toggle="modal"
                   [attr.data-bs-target]="'#IDCWModal' + fund?.fundId">
                  {{ componentType === 'LatestNavs' ? hisNavButtonLabel : hisIDCWButtonLabel }}</a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #IDCWNoData>
          <div style="margin: 20px; padding: 10px;"><h4>{{ "products.fund-header-na" | translate }}</h4></div>
        </ng-template>
      </ng-template>
    </ng-template>
  </div>
</div><!-- Fund Card End's here  -->
<!-- Dialoge: save to watchlist alert -->
<div class="save-alert" style="z-index:10000" *ngIf="showSaveDialoge">

  <div class="content">
    <span class="close-btn" (click)="closeAddWatchListDialoge('close')">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <span>{{ "products.funds-home-card-watchlist-success" | translate }}</span>
    <a class="btn btn-primary" *ngIf="!AddFundOnWatchList"
       (click)="routeModuleWatchList()">{{ "products.funds-home-card-go-watchlist-btn" | translate }}</a>
  </div>
</div>

<!-- Dialoge: Remove from watchlist alert -->
<div class="save-alert" style="z-index:10000" *ngIf="showRemoveDialoge">

  <div class="content">
    <span class="close-btn" (click)="closeRemoveWatchListDialoge('close')">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <span>{{ "products.funds-home-card-watchlist-remove" | translate }}</span>
    <a class="btn btn-primary"
       (click)="routeModuleWatchList()">{{ "products.funds-home-card-go-watchlist-btn" | translate }}</a>
  </div>
</div>


<!-- Dialoge: save to cart -->
<div class="save-alert" style="z-index:10000" *ngIf="showCartSaveDialoge">

  <div class="content">
    <span class="close-btn" (click)="toggleShowSavDialoge1()">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <span>{{ "products.funds-home-card-cart-success" | translate }}</span>
    <a class="btn btn-primary">{{ "products.funds-home-card-goto-cart-btn" | translate }}</a>
  </div>

</div>
<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false)">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>
</div>
<!-- save to compare card for add alert -->
<div class="save-alert" style="z-index:10000" *ngIf="addComparedCardAlert">

  <div class="content">
    <span class="close-btn" (click)="toggleShowComparedCardDialoge()">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <span>Successfully added to Compare Card.</span>
  </div>
</div>

<!-- Dialoge: share alert -->
<div class="save-alert dialoadForCard" style="z-index:10000;" *ngIf="showShareDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowShareDialoge()">
      <img alt='image' src="assets/images/general/close.webp">
    </span>

    <div>
      <div class="modal-title share-tital" id="callBackLabel1" style="font-size:28px;margin-left:auto">
        {{ "products.home-fund-card-share-others" | translate }}
      </div>
      <div class="d-flex gap-3 justify-content-center mb-4 mt-4">
        <div>
          <a (click)="shareOnApp('FACEBOOK',fund)" class="share-btn">
            <img alt='image' src="assets/images/home/Framefacebook.png" style="margin: 8px; width: 46px;">
          </a>
        </div>
        <div>
          <a (click)="shareOnApp('TWITTER',fund)" class="share-btn fund-light-mode-icon">
            <img alt='image' src="assets/images/home/Frame-x-icon.png" style="    margin: 8px; width: 46px;">
          </a>
          <a (click)="shareOnApp('TWITTER',fund)" class="share-btn fund-dark-mode-icon">
            <img alt='image' src="assets/images/home/Frame-x-icon.png"
                 style="    margin: 8px; width: 46px; filter: invert(1);">
          </a>
        </div>
        <div>
          <a (click)="shareOnApp('WHATAPP',fund)" class="share-btn">
            <img alt='image' src="assets/images/home/Framewhatsapp.png" style="    margin: 8px; width: 46px;">
          </a>
        </div>
        <div>
          <a (click)="shareOnApp('LINKEDIN',fund)" class="share-btn">
            <img alt='image' src="assets/images/home/Framelinkedin.png" style="    margin: 8px; width: 46px;">
          </a>
        </div>
      </div>
      <div class="link-block">
        <div class="link" style="border: 1px solid #D2D8EA;padding: 12px; font-size: 0.75rem;word-wrap: break-word;">
          {{ linkToCopy }}
        </div>
      </div>
      <div class="mt-2">
        <a class="btn btn-primary" style="margin: auto;">Copy Link</a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showInvestmentCart">
  <ft-cart [hasInvestmentBtn]="true" [getFundsData]="getFundsData" [investmentCartAddForm]="investmentCartAddForm"
           [hasCancelSaveBtn]="makeSaveCancelBtn" (addToDataOutput)="addToOutput($event)"></ft-cart>
</div>


<!-- Dialoge: save to Compared Card alert -->
<div class="save-alert" style="z-index:10000" *ngIf="showComparedDialoge">
  <div class="content">
    <span class="close-btn" (click)="closeAddCompareDialoge('close')">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <span>{{ "products.funds-home-card-compare-success" | translate }}</span>
    <a class="btn btn-primary"
       (click)="routeModuleCompareFund()">{{ "products.funds-home-card-goto-compare" | translate }}</a>
  </div>
</div>

<!-- Dialoge: Remove from Compared Card alert -->
<div class="save-alert" style="z-index:10000" *ngIf="removeCompareDialoge">
  <div class="content">
    <span class="close-btn" (click)="closeRemoveCompareDialoge('close')">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <span>{{ "products.funds-home-card-compare-remove" | translate }}</span>
    <a class="btn btn-primary"
       (click)="routeModuleCompareFund()">{{ "products.funds-home-card-goto-compare" | translate }}</a>
  </div>
</div>

<!-- Dialoge: Max allowed alert -->
<div class="save-alert" style="z-index:10000" *ngIf="compareFull || watchListFull">
  <div class="content">
    <ng-container *ngIf="compareFull">
      <span class="close-btn" (click)="closeCompareOrWatchlistFull('compare')">
        <img alt='image' src="assets/images/general/close.webp">
      </span>
      <span style="font-size: 1.063rem;">{{ "products.funds-home-card-compare-error" | translate }}</span>
      <a *ngIf="location.indexOf('compar') <= 0" class="btn btn-primary"
         (click)="routeModuleCompareFund()">{{ "products.funds-home-card-goto-compare" | translate }}</a>
    </ng-container>
    <ng-container *ngIf="watchListFull">
      <span class="close-btn" (click)="closeCompareOrWatchlistFull('watchlist')">
        <img alt='image' src="assets/images/general/close.webp">
      </span>
      <span style="font-size: 1.063rem;"> {{ "products.add-fund-watch-list-max-limit-message" | translate }} </span>
      <a *ngIf="location.indexOf('watchlist') <= 0" class="btn btn-primary"
         (click)="routeModuleWatchList()">{{ "products.funds-home-card-go-watchlist-btn" | translate }}
      </a>
    </ng-container>
  </div>
</div>

<div class="modal fade large-modal" [attr.id]="'IDCWModal'+ fund?.fundId" tabindex="-1" aria-labelledby="IDCWModalLabel"
     aria-hidden="true" style="z-index: 1000000;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" *ngIf="idcwModal">
      <ft-idcw-history-modal [brConfig]="idcwConfig"
                             (closeButton)="receiveDataFromIDCW($event)"></ft-idcw-history-modal>
    </div>
  </div>
</div>

<div class="modal fade large-modal" [attr.id]="'NAVModal'+ fund?.fundId" tabindex="-1" aria-labelledby="NAVModalLabel"
     aria-hidden="true" style="z-index: 1000000;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" *ngIf="navModal">
      <ft-fund-nav-modal [brConfig]="navConfig" (closeButton)="receiveDataFromNAV($event)"></ft-fund-nav-modal>
    </div>
  </div>
</div>
