import { Injectable } from '@angular/core';
import { FundDetailService } from '@components/products/services/fund-detail.service';
import { PricingHistoryService } from '@components/products/services/pricing-history.service';
import {
  FundId,
  PricingState,
  PricingStateSelectedTableData,
  PricingStateShareClassInfo,
  ProductDetailConfiguration,
} from '@types';
import { Logger } from '@utils/logger';
import FundDetailQuery from '@graphql/pricing-distribution/pricing.graphql';
import PricingHistoricalQuery from '@graphql/pricing-distribution/pricing-historical.graphql';
import { Product, PricingHistory } from '@components/products/models';
import { Observable, Subject } from 'rxjs';
import { PRICING_INITIAL_STATE } from './pricing-distribution.config';
import moment, { Moment } from 'moment';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import { TranslateService } from '@components/shared/translate/translate.service';
import { WorkSheet } from 'xlsx';
import { InrSymbolPipe } from '@components/shared/pipes/inr-symbol.pipe';
import { DashIfEmptyPipe } from '@components/shared/pipes/dash-if-empty.pipe';
import { EMDASH } from '@components/products/utils/constants/product.constants';
import { sortShareClasses } from '@components/products/utils/shareclass-sorter';

const logger = Logger.getLogger('PricingService');

interface DateFormat {
  dateAsString: string;
  dateAsNumber: number;
}

const dateFormatAsString = 'YYYY-MM-DD';
const internationalDateFormat = 'DD/MM/YYYY';
const dateFormatAsNumber = 'YYYYMMDD';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private state: PricingState;
  private pricingState$: Subject<PricingState>;
  private fundId: FundId;

  constructor(
    private fundDetailService: FundDetailService,
    private pricingHistoryService: PricingHistoryService,
    private translateService: TranslateService,
    private inrSymbolPipe: InrSymbolPipe,
    private dashIfEmptyPipe: DashIfEmptyPipe
  ) {
    this.state = PRICING_INITIAL_STATE;
    this.pricingState$ = new Subject();
  }

  /**
   * Returns pricing state as observable.
   */
  getPricingState$(): Observable<PricingState> {
    return this.pricingState$.asObservable();
  }

  /**
   * Call register method of fund detail service.
   * @param productDetailConfiguration the configuration received on page detail load
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.fundId = productDetailConfiguration.fundId;

    this.fundDetailService
      .register(FundDetailQuery, {
        fundId: productDetailConfiguration.fundId,
      })
      .subscribe(
        (fundDetail: Product) => {
          logger.debug(`Fund detail response: ${fundDetail}`);

          // Assign fund name & its inception date.
          this.state.fundId = fundDetail.fundId;
          this.state.fundName = fundDetail.fundName;
          this.state.fundInceptionDate = fundDetail.fundInceptionDate;

          // Traverse share classes and assign share class & nav state accordingly.
          this.state.fundShareClassData = fundDetail.shareClasses
            .map((shareClass) => {
              return {
                shareClassCode: shareClass.shareClassCode,
                shareClassName: shareClass.shareClassName,
                shareClassCurrency: shareClass.shareClassCurrency,
                shareClassInceptionDate: shareClass.inceptionDate,
                nav: {
                  navDate: shareClass.nav?.navAsOfDate,
                  navValue: shareClass.nav?.navValue,
                },
              };
            })
            .filter((shareClass) => shareClass.nav.navDate !== undefined)
            .sort(sortShareClasses);
          this.state.selectedShareClassData = this.state.fundShareClassData[0];

          // Save dates accordingly.
          this.state.fundInceptionDate = fundDetail.fundInceptionDate;
          (this.state.currentDate = moment().format(internationalDateFormat)), // Intentionally, in dd/mm/yyyy format, i.e., for India.
            (this.state.currentDateStd = moment().format(dateFormatAsString)),
            (this.state.tableConfig.customRange.fromDate =
              fundDetail.fundInceptionDate);
          this.state.tableConfig.customRange.fromDateStd =
            fundDetail.fundInceptionDateStd;
          this.state.tableConfig.customRange = {
            fromDate: fundDetail.fundInceptionDate,
            fromDateStd: fundDetail.fundInceptionDateStd,
            toDate: this.state.currentDate,
            toDateStd: this.state.currentDateStd,
          };

          // For now, basic fund info has been loaded and return the state.
          // Historical data will be fetch and populated once user selects the necessary options.
          this.state.hasLoadedFullFeed = true;
          this.pricingState$.next(this.state);
        },
        (error) => {
          logger.error(`Error in fetching fund detail from PDS: ${error}`);

          this.state.hasLoadedFullFeed = false;
          this.pricingState$.error(error);
        }
      );
  }

  /**
   * Fetch the historical pricing records against selected share class code and time period.
   * @param shareClassCode selected share class for a fund
   * @param selectedTimePeriod selected range for historical records
   * @param selectedFromDateStd selected date in calendar-from input in standard format
   * @param selectedToDateStd selected date in calendar-to input in standard format
   */
  fetchHistoricalRecords(
    selectedPlan: PricingStateShareClassInfo,
    selectedTimePeriod: string,
    selectedFromDateStd: string,
    selectedToDateStd: string
  ): void {
    // Assign modified values to the state.
    this.state.selectedShareClassData = selectedPlan;
    this.state.tableConfig.selectedToggleRange = selectedTimePeriod;

    let startDate: DateFormat;
    let endDate: DateFormat;

    if (selectedTimePeriod === 'CUSTOM_RANGE') {
      startDate = this.getDateBySelectedTimePeriod(
        selectedTimePeriod,
        selectedFromDateStd
      );
      endDate = this.getDateBySelectedTimePeriod(
        selectedTimePeriod,
        selectedToDateStd
      );
    } else {
      startDate = this.getDateBySelectedTimePeriod(selectedTimePeriod);
      endDate = this.getDateBySelectedTimePeriod('TODAYS_DATE');
    }

    this.pricingHistoryService
      .register(PricingHistoricalQuery, {
        fundId: this.fundId,
        shareClassCode: selectedPlan.shareClassCode,
        startdate: startDate.dateAsNumber,
        enddate: endDate.dateAsNumber,
      })
      .subscribe(
        (pricingHistories: PricingHistory[]) => {
          logger.debug(`Fetched pricing histories: ${pricingHistories}`);

          this.state.selectedData = []; // Initialize the list with empty array.

          if (pricingHistories.length) {
            // Traverse the historical list and save it in state in grid format.
            pricingHistories.forEach((pricingHistory) => {
              this.state.selectedData.push({
                navDate: pricingHistory.asOfDate,
                navValue: pricingHistory.nav,
                navChange: pricingHistory.navChangeValue,
              });
            });

            // Assign highest/lowest nav data/date in full historical list.
            const highestNavPricingHistory: PricingHistory = maxBy(
              pricingHistories,
              'navStd'
            );

            const lowestNavPricingHistory: PricingHistory = minBy(
              pricingHistories,
              'navStd'
            );
            this.state.selectedHighLowNavData = {
              highestNav: highestNavPricingHistory?.nav,
              lowestNav: lowestNavPricingHistory?.nav,
              highestNavDate: highestNavPricingHistory?.asOfDate,
              lowestNavDate: lowestNavPricingHistory?.asOfDate,
            };
          } else {
            // Assign null values to highest/lowest nav data.
            this.state.selectedHighLowNavData = {
              highestNav: null,
              lowestNav: null,
              highestNavDate: null,
              lowestNavDate: null,
            };
          }

          // Assign from/to date value as per selected date range.
          this.state.selectedDataDateRange = {
            startDate: startDate.dateAsString,
            endDate: endDate.dateAsString,
          };

          // Configure pagination.
          this.state.pricingTableData = this.state.selectedData;

          this.pricingState$.next(this.state);
        },
        (error) => {
          logger.error(`Error in fetching pricing histories: ${error}`);

          this.pricingState$.error(error);
        }
      );
  }

  /**
   * Return date in string & number against the selected time period or custom date.
   * @param selectedTimePeriod selected time period from options
   * @param dateStd custom date selected from calendar input in standard format
   */
  getDateBySelectedTimePeriod(
    selectedTimePeriod: string,
    dateStd?: string
  ): DateFormat {
    let calculatedDate: Moment;
    switch (selectedTimePeriod) {
      case 'ONE_MONTH':
        calculatedDate = moment().subtract(30, 'days');
        break;
      case 'TWO_MONTHS':
        calculatedDate = moment().subtract(60, 'days');
        break;
      case 'THREE_MONTHS':
        calculatedDate = moment().subtract(90, 'days');
        break;
      case 'FOUR_MONTHS':
        calculatedDate = moment().subtract(120, 'days');
        break;
      default:
        calculatedDate = moment(dateStd ? dateStd : undefined);
    }

    return {
      dateAsString: calculatedDate.format(dateFormatAsString),
      dateAsNumber: Number(calculatedDate.format(dateFormatAsNumber)),
    };
  }

  /**
   * Handle download action.
   * Convert table records into excel format and initiate download.
   */
  triggerDownloadAction(): void {
    // Step 1: Prepare table headers.
    const mappedHeader = new Map<string, string>();

    mappedHeader.set('navDate', 'products.pricing-date');
    mappedHeader.set('navValue', 'products.pricing-nav');
    mappedHeader.set('navChange', 'products.pricing-nav-change');

    // Step 2: Prepare table body. Handle empty list as well.
    const mappedBody: PricingStateSelectedTableData[] = this.state.selectedData;

    if (!mappedBody.length) {
      mappedBody.push({
        navDate: this.translateService.instant(
          'products.pricing-no-historical-records'
        ),
        navValue: EMDASH,
        navChange: EMDASH,
      });
    }

    // Step 3: Add heading as the first row.
    const mappedFullRecords: string[][] = [
      Object.keys(mappedBody[0]).map((data) =>
        data === 'navDate'
          ? this.translateService.instant(mappedHeader.get(data))
          : this.inrSymbolPipe.transform(
              this.translateService.instant(mappedHeader.get(data)),
              'post'
            )
      ),
    ];

    // Step 4: Push all records after heading row.
    mappedBody.forEach((data) => {
      mappedFullRecords.push(Object.values(data));
    });

    // Step 5: Prepare all necessary contents for the excel as different sections.
    //         Each section is a 2-D array.
    //         For now, it contains - fund name, fund details, date range and highest/lowest nav data.
    const fundNameSection: string[][] = [[this.state.fundName]];

    const fundDetailSection: string[][] = [
      [
        this.translateService.instant(`products.pricing-plan`),
        this.state.selectedShareClassData.shareClassName,
      ],
      [
        this.translateService.instant(`products.pricing-fund-number`),
        this.state.fundId,
      ],
      [
        this.translateService.instant(`products.pricing-inception-date`),
        this.state.fundInceptionDate,
      ],
      [
        this.translateService.instant(`products.pricing-fund-base-currency`),
        `${this.inrSymbolPipe.transform(
          this.state.selectedShareClassData.shareClassCurrency,
          'post'
        )}`,
      ],
      [
        `${this.translateService.instant(
          'products.pricing-nav'
        )} ${this.translateService.instant('products.as-of')} ${
          this.state.selectedShareClassData.nav.navDate
        }`,
        this.inrSymbolPipe.transform(
          this.state.selectedShareClassData.nav.navValue,
          'pre'
        ),
      ],
    ];

    const pricingDateRangeSection: string[][] = [
      [
        this.translateService.instant(
          'products.pricing-historical-price-result'
        ),
        `${
          this.state.selectedDataDateRange.startDate
        } ${this.translateService.instant('products.pricing-to')} ${
          this.state.selectedDataDateRange.endDate
        }`,
      ],
    ];

    const pricingHighestLowestNavSection: string[][] = [
      [
        this.translateService.instant('products.pricing-highest-nav-in-range'),
        `${this.inrSymbolPipe.transform(
          this.dashIfEmptyPipe.transform(
            this.state.selectedHighLowNavData.highestNav
          ),
          'pre'
        )} ${this.translateService.instant(
          'products.pricing-on'
        )} ${this.dashIfEmptyPipe.transform(
          this.state.selectedHighLowNavData.highestNavDate
        )}`,
      ],
      [
        this.translateService.instant('products.pricing-lowest-nav-in-range'),
        `${this.inrSymbolPipe.transform(
          this.dashIfEmptyPipe.transform(
            this.state.selectedHighLowNavData.lowestNav
          ),
          'pre'
        )} ${this.translateService.instant(
          'products.pricing-on'
        )} ${this.dashIfEmptyPipe.transform(
          this.state.selectedHighLowNavData.lowestNavDate
        )}`,
      ],
    ];

    // Step 6: Dynamically import xlsx and create workbook and worksheet object.
    //         Add all sections one by one to the worksheet with proper line spacing.
    //         Max width for any row for the 1st column, most probably, can be of either fund name or highest/lowest nav section.
    //         Further, add table contents and add worksheet to workbook.
    //         Finally, writeFile() will download the excel on client.
    import('xlsx').then((xlsx) => {
      const workBook = xlsx.utils.book_new();
      const workSheet: WorkSheet = xlsx.utils.aoa_to_sheet(fundNameSection);

      workSheet['!cols'] = this.fitToColumn(fundNameSection); // Adjust row width for 1st column to have max content.

      xlsx.utils.sheet_add_aoa(workSheet, fundDetailSection, {
        origin: 'A' + (fundNameSection.length + 2),
      });

      xlsx.utils.sheet_add_aoa(workSheet, pricingDateRangeSection, {
        origin: 'A' + (fundNameSection.length + fundDetailSection.length + 3),
      });

      xlsx.utils.sheet_add_aoa(workSheet, pricingHighestLowestNavSection, {
        origin:
          'A' +
          (fundNameSection.length +
            fundDetailSection.length +
            pricingDateRangeSection.length +
            4),
      });

      workSheet['!cols'] = this.fitToColumn(pricingHighestLowestNavSection); // Adjust row width for 1st column to have max content.

      xlsx.utils.sheet_add_aoa(workSheet, mappedFullRecords, {
        origin:
          'A' +
          (fundNameSection.length +
            fundDetailSection.length +
            pricingDateRangeSection.length +
            pricingHighestLowestNavSection.length +
            5),
      });

      const fileTabName = this.translateService.instant(
        'products.pricing-excel-tabname'
      );
      const fileName = this.translateService.instant(
        'products.pricing-excel-filename'
      );

      xlsx.utils.book_append_sheet(
        workBook,
        workSheet,
        fileTabName.length > 31 ? undefined : fileTabName // Max. tab size allowed is 31 char.
      );

      xlsx.writeFile(
        workBook,
        // If, file name not added in BR with xls/xlsx extension, download with default hardcoded name.
        fileName.includes('xls') ? fileName : `HistoricPrices.xlsx`
      );
    });
  }

  /**
   * Get maximum character of each column.
   * @param data full record for excel
   */
  fitToColumn(data: string[][]) {
    return data[0].map((a, i) => ({
      wch: Math.max(...data.map((a2) => a2[i].toString().length)),
    }));
  }
}
