import { Component, Input, OnInit } from '@angular/core';
import { FundId } from '@types';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('BarChartComponent');

/**
 * Interface defines a chart data point and optional nested child points
 */
export interface ChartDataPoint {
  /**
   * e.g. 'Fixed Income'
   */
  label: string;

  /**
   * Formatted Breakdown percent string e.g. '89.24%'
   */
  breakdown?: string;

  /**
   * Full Breakdown percent number value for Highcharts to plot with e.g. 89.24
   */
  breakdownStd?: number;

  /**
   * Formatted Benchmark percent string e.g. '89.24%'
   */
  benchmark?: string;

  /**
   * Full Benchmark percent number value for Highcharts to plot with e.g. 89.24
   */
  benchmarkStd?: number;

  /**
   * Formatted Gross Exposure percent string e.g. '89.24%'
   */
  grossExposure?: string;

  /**
   * Full Gross Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  grossExposureStd?: number;

  /**
   * Formatted Long Exposure percent string e.g. '89.24%'
   */
  longExposure?: string;

  /**
   * Full Long Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  longExposureStd?: number;

  /**
   * Formatted Net Exposure percent string e.g. '89.24%'
   */
  netExposure?: string;

  /**
   * Full Net Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  netExposureStd?: number;

  /**
   * Formatted Short Exposure percent string e.g. '89.24%'
   */
  shortExposure?: string;

  /**
   * Full Short Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  shortExposureStd?: number;

  /**
   * Formatted Target Allocation percent range string e.g. '30% - 70%'
   */
  targetAllocation?: string;

  /**
   * Full Target Allocation percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  targetAllocationStd?: number;

  /**
   * Formatted Actual Allocation percent string e.g. '89.24%'
   */
  actualAllocation?: string;

  /**
   * Full Actual Allocation percent number value for Highcharts to plot with e.g. 89.24
   */
  actualAllocationStd?: number;

  /**
   * Formatted Risk Contribution Allocation percent string e.g. '89.24%'
   */
  riskContributionAllocation?: string;

  /**
   * Formatted Performance Contribution Allocation percent string e.g. '89.24%'
   */
  performanceContributionAllocation?: string;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: ChartDataPoint[];
}

/**
 * Interface defines a table data point and optional nested child points
 */
export interface ChartTableDataPoint extends ChartDataPoint {
  /**
   * color for the legend box. Root nodes only
   */
  pointColor?: string;

  /**
   * indicates if this node has nested child nodes
   */
  hasChildren?: boolean;

  /**
   * indicates if this node is expaned i.e. child nodes are visible
   * Note: only applies to nodes with children
   */
  isExpanded?: boolean;

  /**
   * indicates if this table node is visible i.e. parent node is expanded
   * note: doesn't apply to root nodes
   */
  isHidden?: boolean;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: ChartTableDataPoint[];
}

export interface ChartData {
  /**
   * fund id
   */
  fundId: FundId;

  // SectionHeader config ----------------------------------------

  /**
   * Title
   */
  chartTitle: string;

  /**
   * Footnote placement id
   */
  caveatPlacement?: string;

  /**
   * FIMES Footnote placement id
   */
  secondaryCaveatPlacement?: string;

  /**
   * Text or html content for a tooltip
   */
  tooltip?: string;

  /**
   * e.g. `'As of'`
   */
  asOfLabel?: string;

  /**
   * Preformatted date string e.g. `'12 March 2020'`
   */
  asOfDate?: string;

  /**
   * e.g. `'(Market Value)'`
   */
  calculationBasis?: string;

  /**
   * e.g. `'(% of Total)'`
   */
  calculationType?: string;

  /**
   * e.g. `'(updated monthly)'`
   */
  updateFrequency?: string;

  // TODO: is this still used?
  /**
   * Formatted Performance Contribution date range string e.g. '830/11/2020 - 31/12/2020'
   */
  contributionDateRange?: string;

  /**
   * determines if Y axis shows % instead of just value
   */
  isPercent?: boolean;

  // Show/hide config ----------------------------------------

  /**
   * Use to hide chart
   */
  hideChart?: boolean;

  /**
   * Use to hide table
   */
  hideTable?: boolean;

  /**
   * Use to show Exposure type chart (i.e. 2 data series) instead of default options
   */
  showExposureChart?: boolean;

  /**
   * Use to show the percentage bar component
   */
  showTotalPercentageBar?: boolean;

  /**
   * Use to hide the default legend colored squares in the table
   */
  hideTableLegend?: boolean;

  /**
   * Use to hide the default % breakdown column in table
   */
  hideBreakdownCol?: boolean;

  /**
   * Use to show the benchmark column in table
   */
  showBenchmarkCol?: boolean;

  /**
   * Use to show the Duration column in table
   */
  showDurationCol?: boolean;

  /**
   * Use to show the Gross Exposure column in table
   */
  showGrossExposureCol?: boolean;

  /**
   * Use to show the Long Exposure column in table
   */
  showLongExposureCol?: boolean;

  /**
   * Use to show the Net Exposure column in table
   */
  showNetExposureCol?: boolean;

  /**
   * Use to show the Short Exposure column in table
   */
  showShortExposureCol?: boolean;

  /**
   * Use to show the Target Allocation column in table
   */
  showTargetAllocationCol?: boolean;

  /**
   * Use to show the ActualAllocation column in table
   */
  showActualAllocationCol?: boolean;
  /**
   * Use to show the Risk Contributio column in table
   */
  showRiskContributionAllocationCol?: boolean;
  /**
   * Use to show the Performance Contribution column in table
   */
  showPerformanceContributionAllocationCol?: boolean;

  /**
   * Use to set a title on the label column in table
   */
  labelColumnTitle?: string;

  /**
   * Use override the default title ("Fund") on the breakdown column in table
   */
  breakdownColumnTitle?: string;

  // Caveat config ----------------------------------------

  /**
   * Top proximal placement id
   */
  proximalTopPlacement?: string;

  /**
   * Bottom proximal placement id
   */
  proximalBottomPlacement?: string;

  /**
   * Top secondary proximal placement id (for derivatives proximals etc)
   */
  secondaryProximalTopPlacement?: string;

  /**
   * Bottom secondary proximal placement id (for derivatives proximals etc)
   */
  secondaryProximalBottomPlacement?: string;

  // Chart + table data ----------------------------------------

  /**
   * Data to be used in chart and table
   */
  dataPoints: ChartDataPoint[];

  /**
   * Data to be used for percentage bar on top 10 charts
   */
  totalPercent?: number;

  highestPercent?: number;

  isPiChart?: boolean;
}

export const hasChildren = (point: ChartDataPoint): boolean =>
  point.children?.length > 0;

export const expandNode = (
  node: ChartTableDataPoint,
  recursive = false
): void => {
  // expanding a node sets isExpanded true, and isHidden to false on child nodes
  node.isExpanded = true;
  node.children?.forEach((childNode: ChartTableDataPoint): void => {
    childNode.isHidden = false;
    if (recursive) {
      expandNode(childNode, true);
    }
  });
};

export const collapseNode = (
  node: ChartTableDataPoint,
  recursive = false
): void => {
  // collapsing a node sets isExpanded false, and isHidden to true on child nodes
  node.isExpanded = false;
  node.children?.forEach((childNode: ChartTableDataPoint): void => {
    childNode.isHidden = true;
    if (recursive) {
      collapseNode(childNode, true);
    }
  });
};

export const hasExpandedNodes = (node: ChartTableDataPoint): boolean =>
  node.hasChildren &&
  (node.isExpanded || node.children?.some(hasExpandedNodes));

export const hasCollapsedNodes = (node: ChartTableDataPoint): boolean =>
  node.hasChildren &&
  (!node.isExpanded || node.children?.some(hasCollapsedNodes));
@Component({
  selector: 'ft-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  highcharts;

  @Input() data: ChartData;

  constructor() {
  }

  ngOnInit(): void {
  }
}
