/**
 * This file declares all of the common Bloomreach
 * Documents and Compounds
 */


/**
 * Widen Document Compound
 */
export interface WidenDocument {
  name: string;
  displayName: string;
  widenDocument: string;
}

/**
 * Link Compound
 */
export interface Link {
  literatureCode?: string;
  name?: string;
  displayName?: string;
  document?: string;
  external?: boolean;
  externalSuffix?: string;
  url: string;
  theme?: string;
  title: string;
  displayText: string;
  linkText?: string;
  documentOrAssetLink?: string;
  target: string;
  date?: string | number;
  widenAssetCompound?: WidenDocument;
  linkCollection?: Array<LinkCollection>;
}

/**
 * Link Collection
 */
export interface LinkCollection {
  name: string;
  displayName: string;
  document: string;
  external: boolean;
  externalSuffix?: string;
  url: string;
  theme: string;
  title: string;
  displayText: string;
  documentOrAssetLink: string;
  target: string;
  widenAssetCompound: WidenDocument;
}

/**
 * Interface matching with service response
 * https://dev-gateway.ftsites.com/services/literature/staging/v1/documents?channel=en-in&contentGrouping=UPDATES&limitIndex=10
 */
export interface LiteratureDTO {
  document: [
    {
      livesiteId: string;
      dctermsTitle: string;
      fundName: string;
      publicationDate: string;
      frkReferenceDate: string;
      downloadUrl: string;
      dctermsLanguage: string;
      literatureHref: string;
      customFilePath: string;
      contentGrouping: string;
    }
  ];
}
