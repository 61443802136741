import { Injectable } from '@angular/core';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SiteConfigService } from '@services/site-config.service';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import {
  FundDataServiceParams,
  GraphQLFundDataService,
} from './graphql-fund-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DistributionHistoryDTO } from '@types';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { DistributionHistory } from '@products/models/distribution-history';
import { DistributionHistoriesMapper } from '@products/utils/mappers/distribution-histories.type.mapper';

const logger = Logger.getLogger('PricingHistoryService');

@Injectable({
  providedIn: 'root',
})
export class DistributionHistoryService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private mapperFactory: MapperFactory
  ) {
    super(apollo, siteConfigService);
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<any> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((rawHistoricalData) => {
        const distributionHistoriesDto: DistributionHistoryDTO[] = rawHistoricalData
          ?.data?.DistributionHistory?.distribution
          ? rawHistoricalData?.data?.DistributionHistory?.distribution
          : null;
        const mappperParams: MapperParams = {
          config: this.siteConfigService,
        };
        const mappedDistributionHistory: DistributionHistory[] = this.mapperFactory
          .createMapper(DistributionHistoriesMapper, mappperParams)
          .toDomain(distributionHistoriesDto);

        return mappedDistributionHistory;
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
      shareclasscode: fundDataServiceParams.shareClassCode,
      startdate: fundDataServiceParams.startdate,
      enddate: fundDataServiceParams.enddate,
    };
  }
}
