<div *ngIf="isPopulated" class="pi-chart">
  <div class="graph-box">
    <highcharts-chart
        [Highcharts]="highcharts"
        [options]="chartOptions"
        [(update)]="updateChart"
        [callbackFunction]="chartCallback"
        style="width: 100%; display: block">
    </highcharts-chart>
    <div class="graph-stat">
      <span *ngFor="let point of tablePoints">
        <span class="circle" [style.background]="point.pointColor"></span>
        {{ point.label }} ({{point.actualAllocation || point.breakdown}})</span>
    </div>
  </div>
  <!-- </div>
 </div>
</div>-->
</div>
