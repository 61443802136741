import { Injectable } from '@angular/core';
import { SiteConfigService } from '@services/site-config.service';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { map } from 'rxjs/operators';
import { ProductDTO } from '../types';
import { MapperFactory } from '../utils/mappers/mapper-factory';
import { MapperParams } from '../utils/mappers/type.mapper';
import {
  FundDataServiceParams,
  GraphQLFundDataService,
} from './graphql-fund-data.service';

@Injectable({
  providedIn: 'root',
})
export class FundPortfolioManagersService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private productMapperFactory: MapperFactory
  ) {
    super(apollo, siteConfigService);
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ){//}: Observable<Product> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((fundPortfolio) => {
        const productDto: ProductDTO = fundPortfolio?.data?.PortfolioManagers
          ? fundPortfolio.data.PortfolioManagers
          : null;

        const mappperParams: MapperParams = {
          config: this.siteConfigService,
        };

        return productDto
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      mgrempid: fundDataServiceParams.empId,
    };
  }
}
