<ng-container *ngIf="fund">
  <div class="card compareCardBox mb-4">
    <div class="fund-heading mb-3">
      <div class="d-flex justify-content-between gap-1 forLabelCard">
        <div class="focus-title-custom">
          <a [routerLink]="[fund?.fundLink?.overview]" class="title-text fund-tit-focus">{{ fund?.fundName }}</a>&nbsp;&nbsp;
          <span class="img-with-tooltip" *ngIf="getRisk(fund?.productLabel)">
            <img alt="risk-meter" src="assets/images/general/very-high.webp" style="width: 33px;max-width: 33px;">
            <span class="tooltip-block">
              <img alt="risk-meter" class="risk-meter" src="assets/images/general/very-high.webp" style="width: 32px;">
              {{ getRisk(fund?.productLabel) }}
            </span>
          </span>
        </div>
        <span class="dropdown cust-dd">
          <span type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots-vertical" style="max-width: 92%;"></i>
          </span>
          <ul class="dropdown-menu">
            <!--<li class="d-none d-md-block">
              <a class="dropdown-item fontResize fund-light-mode-icon" style="cursor: pointer;" data-bs-toggle="modal"
                 data-bs-target="#goToWatchList">
                <img class="" src="assets/images/general/ph_share-fat-light.webp">Save to Watchlist

              </a>
              <a class="dropdown-item fontResize fund-dark-mode-icon" style="cursor: pointer;" data-bs-toggle="modal"
                 data-bs-target="#goToWatchList">
                <img class="" src=" ../../../../assets/images/dark/dark-Save-icon.png">Save to Watchlist

              </a>
            </li>-->

            <li><span style="cursor:pointer;" class="dropdown-item fontResize fund-light-mode-icon"
                      (click)="toggleShowCopyDialoge(true)">
              <img
                  src="assets/images/general/Link.webp">{{ "products.home-fund-card-share-copy-btn" | translate }}</span>
            <li><span style="cursor:pointer;" class="dropdown-item fontResize fund-dark-mode-icon"
                      (click)="toggleShowCopyDialoge(true)">
              <img
                  src="assets/images/dark/link-dark.png">{{ "products.home-fund-card-share-copy-btn" | translate }}</span>

            </li>
            <li><a style="cursor:pointer;" class="dropdown-item fontResize fund-light-mode-icon"
                   (click)="shareOnApp('TWITTER',fund)">
              <img
                  src="assets/images/general/TwitterLogo.svg">{{ "products.home-fund-card-share-twitter-btn" | translate }}</a>
            <li><a style="cursor:pointer;" class="dropdown-item fontResize fund-dark-mode-icon"
                   (click)="shareOnApp('TWITTER',fund)">
              <img
                  src="assets/images/general/brandico_twitter-bird.png">{{ "products.home-fund-card-share-twitter-btn" | translate }}</a>

            </li>
            <li><a style="cursor:pointer;" class="dropdown-item fontResize fund-light-mode-icon"
                   (click)="shareOnApp('FACEBOOK',fund)">
              <img alt='image' src="assets/images/general/FacebookLogo.webp">{{ "products.home-fund-card-share-fb-btn" | translate }}</a>
            <li><a style="cursor:pointer;" class="dropdown-item fontResize fund-dark-mode-icon"
                   (click)="shareOnApp('FACEBOOK',fund)">
              <img
                  src="assets/images/general/footer-facebook.webp">{{ "products.home-fund-card-share-fb-btn" | translate }}</a>

            </li>
            <li><a style="cursor:pointer;" class="dropdown-item fontResize fund-light-mode-icon"
                   (click)="shareOnApp('LINKEDIN',fund)">
              <img
                  src="assets/images/general/LinkedinLogo.webp">{{ "products.home-fund-card-share-linkedin-btn" | translate }}</a>
              <a style="cursor:pointer;" class="dropdown-item fontResize fund-dark-mode-icon"
                 (click)="shareOnApp('LINKEDIN',fund)">
                <img
                    src="assets/images/general/footer-linkedin.webp">{{ "products.home-fund-card-share-linkedin-btn" | translate }}</a>
            </li>
            <li><a style="cursor:pointer;" class="dropdown-item fontResize fund-light-mode-icon"
                   (click)="shareOnApp('WHATAPP',fund)">
              <img
                  src="assets/images/general/whatsapps.webp">{{ "products.home-fund-card-share-whatsapp-btn" | translate }}</a>
              <a style="cursor:pointer;" class="dropdown-item fontResize fund-dark-mode-icon"
                 (click)="shareOnApp('WHATAPP',fund)">
                <img
                    src="assets/images/general/ri_whatsapp-fill.webp">{{ "products.home-fund-card-share-whatsapp-btn" | translate }}</a>
            </li>

            <!--<li><span style="cursor:pointer;" class="dropdown-item" (click)="toggleShowCopyDialoge(true)">
              <img alt="Copy"
                   src="assets/images/general/Link.webp">{{ "products.home-fund-card-share-copy-btn" | translate }}
            </span>
            </li>
            <li><a style="cursor:pointer;" class="dropdown-item" (click)="shareOnApp('TWITTER',fund)">
              <img alt="share"
                   src="assets/images/general/TwitterLogo.svg">{{ "products.home-fund-card-share-twitter-btn" | translate }}
            </a>
            </li>
            <li><a style="cursor:pointer;" href="" class="dropdown-item" (click)="shareOnApp('FACEBOOK',fund)">
              <img alt="share"
                   src="assets/images/general/FacebookLogo.webp">{{ "products.home-fund-card-share-fb-btn" | translate }}
            </a>
            </li>
            <li><a style="cursor:pointer;" class="dropdown-item" (click)="shareOnApp('LINKEDIN',fund)">
              <img alt="share"
                   src="assets/images/general/LinkedinLogo.webp">{{ "products.home-fund-card-share-linkedin-btn" | translate }}
            </a>
            </li>
            <li><a style="cursor:pointer;" class="dropdown-item" (click)="shareOnApp('WHATAPP',fund)">
              <img alt="share"
                   src="assets/images/general/whatsapps.webp">{{ "products.home-fund-card-share-whatsapp-btn" | translate }}
            </a>
            </li>-->
          </ul>
        </span>
      </div>
    </div>
    <div class="midCard mb-3">
      <div class="midCardValue">
        <div *ngIf="fund?.aum?.totalNetAssets">
          <div class="d-flex">
            <div class="valuMidCardData">{{ "products.funds-home-card-fund-size" | translate }}</div>&nbsp;&nbsp;
            <div class="img-with-tooltip">
              <img alt='image' src="assets/images/home/info-icon2.svg"
                   class="d-inline-block mobile-icons light-mode-icon"
                   style="width: 18px;">
              <img alt='image' src="assets/images/fund/dark-information-icon.png" class="mobile-icons dark-mode-icon"
                   style="width: 14px; top: 4px;">
              <span class="tooltip-block" style="left: -80px;">
                {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.aum?.asOfDate }}
              </span>
            </div>
          </div>
          <div class="valueMidCard">{{ true ? (fund?.aum?.totalNetAssets |INRCurrencyNumber) + ' Cr' : '-' }}</div>
        </div>
        <div class="lineImage"><img alt='image' src="./assets/images/comparefund/Line 987.png"></div>
        <ng-container *ngIf="fund?.selectedDropdown?.assetValue else perfElse">
          <div>
            <div class="d-flex">
              <div class="valuMidCardData">{{ "products.funds-home-card-annualized-returns" | translate }}</div>&nbsp;&nbsp;
              <div class="img-with-tooltip">
                <img alt='image' src="assets/images/home/info-icon2.svg" class="d-inline-block mobile-icons light-mode-icon"
                     style="width: 18px;">
                <img alt='image' src="assets/images/fund/dark-information-icon.png" class="mobile-icons dark-mode-icon"
                     style="width: 14px; top: 4px;">
                <span class="tooltip-block">
                  {{ "products.as-of-capitalize" | translate }}&nbsp;{{ fund?.aum?.asOfDate }}
                </span>
              </div>
            </div>
            <div
                class="valueMidCard ml-3 mr-3">{{ fund?.selectedDropdown?.assetValue ? fund?.selectedDropdown?.assetValue + '%' : 'NA' }}
            </div>
          </div>
        </ng-container>
        <ng-template #perfElse>
          <span class="text-big">NA</span>
        </ng-template>
      </div>
      <div class="midCardValue" *ngIf="fund?.uniqueInvestors !== ''">
        <span class="totalInvesterUserImage"><img alt='image' src="assets/images/comparefund/Vectorcard.png"></span>
        <span
            class="valueMidCard mt-1">{{ fund?.uniqueInvestors !== null && fund?.uniqueInvestors !== undefined && fund?.uniqueInvestors !== '' ? ((fund?.uniqueInvestors).replace(',', '') | INRCurrencyNumberInt) : '-' }}</span>
        <span class="valuMidCardData mt-1"> {{ "products.existing-investors" | translate }}</span>
        <span class="img-with-tooltip for_add_data_card">
          <img alt='image' src="assets/images/home/info-icon2.svg" class="d-inline-block mobile-icons light-mode-icon"
               style="width: 19px;">
          <img alt='image' src="assets/images/fund/dark-information-icon.png" class="mobile-icons dark-mode-icon"
               style="width: 14px; top: 4px;">
          <span class="tooltip-block">
            {{ "products.as-of-capitalize" | translate }}&nbsp;{{ getEndOfLastQuarter() }}
          </span>
        </span>
      </div>
    </div>
    <div class="buttonCard">
      <div class="makeGap">
       <!-- <button type="button" class="btn btn-outline-primary customButtonFirst" (click)="goToCart()">
          <img class="share-icon2" src="assets/images/comparefund/shopping-cart.png"></button>-->
        <button type="button" class="btn btn-primary customButtonSecond" style="width:100%" (click)="investNow(fund)"
                [disabled]="!fund?.enableInvestNow">
          {{ "products.funds-home-invest-now" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false)">
      <img alt='image' src="assets/images/general/close.webp">
    </span>
    <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>
</div>
