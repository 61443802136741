/**
 *
 * @param a sorting parameter 1st
 * @param b sorting parameter 2nd
 * @returns manually sorted shareClasses according to order defined
 */
export function sortShareClasses(a, b) {
  const order = [
    '20s Plan-Growth',
    '20s Plan-IDCW',
    'Growth',
    'IDCW',
    'Quarterly',
    'Monthly - IDCW',
    'Quarterly - IDCW',
    'Direct-Growth',
    '50s Floating-IDCW',
    'Direct-IDCW',
    'Direct-Annual - IDCW',
    'Direct-Half yearly - IDCW',
    'Direct-Monthly - IDCW',
    'Direct-Quarterly - IDCW',
    'Direct-Weekly - IDCW',
    'Institutional-Growth',
    'Institutional-Daily - IDCW',
    'Plan A-Growth',
    'Plan A-Annual - IDCW',
    'Plan A-Half yearly - IDCW',
    'Plan A-Monthly - IDCW',
    'Plan A-Quarterly - IDCW',
    'Retail-Growth',
    'Retail-Daily - IDCW',
    'Retail-Weekly - IDCW',
    'Retail-Monthly - IDCW',
    'Retail-Quarterly - IDCW',
    'Super Institutional-Growth',
    'Super Institutional-Daily - IDCW',
    'Super Institutional-Weekly - IDCW',
    'Super institutional - Direct-Growth',
    'Super institutional - Direct-Daily - IDCW',
    'Super institutional - Direct-Weekly - IDCW',
  ];
  let indexA;
  let indexB;
  // 1 - fund information service
  // 2 - idcw history/distribution-table
  // 3 - Pricing history / Historical NAVs
  if (a.name !== undefined) {
    indexA = order.indexOf(a.name);
    indexB = order.indexOf(b.name);
  } else if (a.planName !== undefined) {
    indexA = order.indexOf(a.planName);
    indexB = order.indexOf(b.planName);
  } else {
    indexA = order.indexOf(a.shareClassName);
    indexB = order.indexOf(b.shareClassName);
  }

  if (indexA === -1) {
    indexA = 999;
  }
  if (indexB === -1) {
    indexB = 999;
  }
  return indexA - indexB;
}
