import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from "@shared/translate/translate.service";
import { CommonService } from "@services/common.service";
import { Router } from "@angular/router";
import { InvestNowService } from "@services/invest-now.service";
import { AnalyticsService } from "@services/analytics.service";
import { Logger } from "@utils/logger";
import moment from "moment";
import { Location } from "@angular/common";

const logger = Logger.getLogger('FocusFundCardComponent');

@Component({
  selector: 'ft-focus-fund-card',
  templateUrl: './focus-fund-card.component.html',
  styleUrls: ['./focus-fund-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FocusFundCardComponent implements OnInit {
  @Input() fund: any;
  showCopyDialoge = false;
  linkToCopy: string;

  constructor(
    private location: Location,
    private cdr: ChangeDetectorRef,
    private investNowService: InvestNowService,
    private analyticsService: AnalyticsService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
  }

  getRisk(productLabel?: string) {
    if (productLabel)
      return this.commonService.getRisk(productLabel);
  }

  investNow(fund) {
    if (fund.enableInvestNow) {
      this.analyticsService.trackEvent({
        event: 'Event Fund Explorer',
        category: 'Fund Explorer',
        action: 'Invest Now',
        label: fund.fundName,
      });

      let gaCat = 'Knowledge Centre';
      if (this.location.path().indexOf('tools/funds-watchlist') > 0) {
        gaCat = 'Watchlist';
      } else if (this.location.path().indexOf('tools/fund-comparator') > 0) {
        gaCat = 'Compare';
      }

      this.analyticsService.trackEvent({
        event: 'Event Fund Explorer',
        ft_event: 'ft_InvestNow_Clicked',
        category: gaCat,
        action: 'InvestNow',
        label: fund.fundName,
      });

      this.investNowService.investNow(
        this.fund?.fundId,
        this.fund?.fundName
      );
    } else {
      return
    }
  }

  shareOnApp(appName, fund): void {
    this.commonService.shareOn(appName, fund?.fundLink?.overview);
  }

  async toggleShowCopyDialoge(check): Promise<void> {
    if (check) {
      const baseUrl = this.commonService.getBaseUrl();
      this.linkToCopy = `${baseUrl}${this.fund.fundLink.overview}`;

      try {
        await navigator.clipboard.writeText(this.linkToCopy);
        logger.info('Link copied to clipboard:', this.linkToCopy);
      } catch (err) {
        logger.error('Unable to copy to clipboard', err);
      }
    }
    this.showCopyDialoge = check;
    this.cdr.detectChanges();
  }

  public getEndOfLastQuarter(): string {
    const quarterAdjustment = (moment().month() % 3) + 1;
    const lastQuarterEndDate = moment()
      .subtract({months: quarterAdjustment})
      .endOf('month');

    return lastQuarterEndDate.format('L');
  }
}
