import { Injectable } from '@angular/core';
import { FundDetailService } from '@components/products/services/fund-detail.service';
import { StorageService } from '@services/storage.service';
import { DropdownItem, FundId, PortfolioComponentId, ProductDTO, SuppressionSet, TabName } from '@types';
import { Logger } from '@utils/logger';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject, zip } from 'rxjs';
import {
  FundCompareToastActionType,
  FundCompareContentDataDTO,
  FundCompareDetail,
  FundCompareState,
  FundCompareToastAction,
  StorageOperation,
  FundCompareItem,
} from '@components/products/types/fund-compare.type';
import FundCompareProductDetailsQuery from '@graphql/fund-compare/fund-compare-product-details.graphql';
import FundComparePortfolioQuery from '@graphql/fund-compare/fund-compare-portfolio.graphql';
import FundComparePPSSQuery from '@graphql/fund-compare/fund-compare-ppss.graphql';
import { FundPortfolioService } from '@components/products/services/fund-portfolio.service';
import {
  AssetAllocation,
  FundListing,
  PerformanceDetail,
  PortfolioSectorAllocationTable,
  PortfolioTopHoldingTable,
  PortfolioAllocation,
  Product,
  ShareClass, PortfolioAllocationTableData,
} from '@components/products/models';
import { SiteConfigService } from '@services/site-config.service';
import {
  Configuration,
  initialize,
  Page,
  PageModel,
} from '@bloomreach/spa-sdk';
import { FundListingService } from '@components/products/fund-listing/services/fund-listing.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { PPSSFundDataService } from '@components/products/services/ppss-fund-data.service';
import { ProductTypeParameter } from '@components/products/services/graphql-fund-data.service';
import { map } from 'rxjs/internal/operators/map';
import {
  AddFundsList,
  AddFundsModal,
} from '@products/types/add-funds.type';
import uniq from 'lodash/uniq';
import { HttpClient } from '@angular/common/http';
import { AppStateService } from '@services/app-state.service';
import { MapperParams } from "@products/utils/mappers/type.mapper";
import { ProductMapper } from "@products/utils/mappers/product.type.mapper";
import { floatComparator } from "@utils/sort-utils";
import { FundCompareData } from '../fund-compare/fund-compare.type';

const logger = Logger.getLogger('FundCompareService');
const FUNDS_COMPARE_LOCAL_STORAGE_KEY = 'compare-funds';

type PortfolioAllocationTable =
  | PortfolioTopHoldingTable
  | PortfolioSectorAllocationTable;

@Injectable({
  providedIn: 'root',
})
export class FundCompareService {
  // Sticky footer related declarations.
  private isResetActionApplied$: Subject<FundId>;
  private isComparisonLimitReached$: Subject<boolean>;
  private ppssData: Product[];

  // Compare state related declarations.
  private state: FundCompareState;
  private fundCompareState$: Subject<FundCompareState>;
  private fundManagerState$: Subject<FundCompareData[]>;

  // Fund detail toast message related declaration(s).
  private compareToastMessage$: Subject<string>;

  private compareList: any[] = [];
  private compareListCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  compareListCount$: Observable<number> = this.compareListCountSubject.asObservable();

  constructor(
    private storageService: StorageService,
    private fundDetailService: FundDetailService,
    private fundPortfolioService: FundPortfolioService,
    private siteConfigService: SiteConfigService,
    private fundListingService: FundListingService,
    private ppssFundDataService: PPSSFundDataService,
    private translateService: TranslateService,
    private appState: AppStateService
  ) {
    this.state = {
      fundsData: [],
      addFundsData: null,
    };

    this.isResetActionApplied$ = new Subject();
    this.isComparisonLimitReached$ = new Subject();
    this.fundCompareState$ = new Subject();
    this.fundManagerState$ = new Subject();
    this.compareToastMessage$ = new Subject();
    this.loadComparelistFromLocalStorage();
  }

  // ******************** Sticky footer related implementations ********************

  /**
   * Return the reset action update as observable.
   */
  getResetActionUpdate(): Observable<FundId> {
    return this.isResetActionApplied$.asObservable();
  }

  /**
   * Return the comparison limit update as observable.
   */
  getComparisonLimitUpdate(): Observable<boolean> {
    return this.isComparisonLimitReached$.asObservable();
  }

  /**
   * Publish reset event trigger to the subscribers.
   * Works for single fund de-select as well as for de-select for all funds.
   * @param fundId fund id of the selected fund
   */
  publishResetAction(fundId?: FundId): void {
    this.isResetActionApplied$.next(fundId);
  }

  /**
   * Publish comparison limit event trigger to the subscribers.
   * @param limitReached boolean flag, if limit reached or not
   */
  publishComparisonLimit(limitReached: boolean): void {
    this.isComparisonLimitReached$.next(limitReached);
  }

  // ******************** Local storage related implementations ********************

  /**
   * Update the local storage with the fund detail(s), on being selected or deselected.
   * @param fundCompareDetails fund detail(s) of the fund
   * @param operation type of operation requested, currently either 'SET' or 'REMOVE'
   */
  async updateFundDetailsToLocalStorage(
    fundCompareDetails: FundCompareDetail[],
    operation: StorageOperation
  ): Promise<void> {
    try {
      // First check if, storage key exist in local storage.
      if (await this.storageService.isSet(FUNDS_COMPARE_LOCAL_STORAGE_KEY)) {
        // If found, retrieve the list.
        const storedFundDetails: FundCompareDetail[] = await this.storageService.retrieve(
          FUNDS_COMPARE_LOCAL_STORAGE_KEY
        );

        // Check for the operation requested.
        // For both the operations, check if fund Id(s) doesn't already exist in the list.
        if (operation === StorageOperation.SET) {
          // If not, push the new fund id(s) into the list and store the final altered list it in local storage.
          fundCompareDetails.forEach((fundCompareDetail) => {
            if (
              storedFundDetails.findIndex(
                (fundDetail) => fundDetail.fundId === fundCompareDetail.fundId
              ) === -1
            ) {
              storedFundDetails.push(fundCompareDetail);
            }
          });

          this.storageService.store(
            FUNDS_COMPARE_LOCAL_STORAGE_KEY,
            storedFundDetails
          );
        } else {
          // Perform 'remove' only on fund id(s), existing in local storage.
          // And, save the new altered list again.
          fundCompareDetails.forEach((fundCompareDetail) => {
            // Here extract the index of the fund id already existing.
            const matchedFundDetailIndex: number = storedFundDetails?.findIndex(
              (storedFundDetail) =>
                storedFundDetail.fundId === fundCompareDetail.fundId
            );

            // Remove it from the list and store the new list in local storage.
            if (matchedFundDetailIndex !== -1) {
              storedFundDetails.splice(matchedFundDetailIndex, 1);
            }
          });

          this.storageService.store(
            FUNDS_COMPARE_LOCAL_STORAGE_KEY,
            storedFundDetails
          );
        }
      } else {
        // If key not found, store the fund id(s) in the local storage for 'SET' operation.
        // For, 'REMOVE' operation, nothing needed.
        if (operation === StorageOperation.SET) {
          this.storageService.store(
            FUNDS_COMPARE_LOCAL_STORAGE_KEY,
            fundCompareDetails
          );
        }
      }
    } catch (error) {
      logger.error('Error in communicating with local storage: ', error);
    }
  }

  /**
   * Clear the fund id list i.e., basically remove the compare key.
   */
  clearFundDetailListInLocalStorage(): void {
    this.storageService.remove(FUNDS_COMPARE_LOCAL_STORAGE_KEY);
  }

  /**
   * Get the fund id list stored in local storage.
   */
  async retrieveFundDetailListFromLocalStorage(): Promise<FundCompareDetail[]> {
    try {
      return await this.storageService.retrieve(
        FUNDS_COMPARE_LOCAL_STORAGE_KEY
      );
    } catch (error) {
      logger.error(
        'Error in retrieving fund id list from local stroage: ',
        error
      );

      return new Promise((resolve, reject) => {
        reject(`Error in retrieving fund id list from local stroage: ${error}`);
      });
    }
  }

  // ******************** Fund detail toast message related implementations ********************

  /**
   * Return toast message for comparison action as observable.
   */
  getToastMessageForCompareAction(): Observable<string> {
    return this.compareToastMessage$.asObservable();
  }

  /**
   * Handle toast message when compare action is requested.
   * Check if fund is already added, or compare limit is reached or is added now.
   * Create the message and return the success action accordingly.
   */
  handleCompareActionAndToastMessage(
    selectedFundCompareDetail: FundCompareDetail,
    storageOperation: StorageOperation
  ): Promise<FundCompareToastAction> {
    return new Promise(async (resolve, reject) => {
      try {
        if (storageOperation === StorageOperation.REMOVE) {
          // Remove the new detail, trigger toast message accordingly and resolve the promise.
          this.updateFundDetailsToLocalStorage(
            [selectedFundCompareDetail],
            StorageOperation.REMOVE
          );

          this.compareToastMessage$.next(
            this.translateService.instant('products.compare-fund-removed')
          );

          resolve({
            type: FundCompareToastActionType.REMOVED,
          });
        } else {
          // Handle each addition scenario with proper toast message & promise fulfillment.
          const fundCompareDetails: FundCompareDetail[] = await this.retrieveFundDetailListFromLocalStorage();

          if (fundCompareDetails) {
            // Check if new detail is already present in local storage.
            const isNewFundAlreadyExist: boolean =
              fundCompareDetails.findIndex(
                (fundCompareDetail) =>
                  fundCompareDetail.fundId === selectedFundCompareDetail.fundId
              ) !== -1;

            // Check if, compare limit has not been reached.
            if (fundCompareDetails.length !== 3 || isNewFundAlreadyExist) {
              // Further, check if, new fund detail doesn't already exist.
              if (isNewFundAlreadyExist) {
                // New detail already exist.
                // Trigger the toast message accordingly and reject the promise.
                this.compareToastMessage$.next(
                  this.translateService.instant(
                    'products.compare-fund-already-exist'
                  )
                );

                reject({
                  type: FundCompareToastActionType.ALREADY_EXIST,
                } as FundCompareToastAction);
              } else {
                // Save the new detail, trigger toast message accordingly and return success.
                this.updateFundDetailsToLocalStorage(
                  [selectedFundCompareDetail],
                  StorageOperation.SET
                );

                this.compareToastMessage$.next(
                  this.translateService.instant('products.compare-fund-added')
                );

                resolve({
                  type: FundCompareToastActionType.ADDED,
                });
              }
            } else {
              // Limit has been reached, trigger toast message accordingly and return reject.
              this.compareToastMessage$.next(
                this.translateService.instant(
                  'products.fund-listing-compare-limit-reached'
                )
              );

              reject({
                type: FundCompareToastActionType.LIMIT_REACHED,
              } as FundCompareToastAction);
            }
          } else {
            // No compare detail exist in local storage.
            // Hence, save the new detail, trigger toast message accordingly and return success.
            this.updateFundDetailsToLocalStorage(
              [selectedFundCompareDetail],
              StorageOperation.SET
            );

            this.compareToastMessage$.next(
              this.translateService.instant('products.compare-fund-added')
            );

            resolve({
              type: FundCompareToastActionType.ADDED,
            });
          }
        }
      } catch (error) {
        logger.error(
          'Error in fetching fund compare ids from local storage: ',
          error
        );

        reject({
          type: FundCompareToastActionType.ERROR,
          message: error,
        } as FundCompareToastAction);
      }
    });
  }

  // ******************** Compare funds related implementations ********************

  /**
   * Return fund compare state as observable.
   */
  getFundCompareState$(): Observable<FundCompareState> {
    return this.fundCompareState$.asObservable();
  }

  getFundManagerState$(): Observable<FundCompareData[]> {
    return this.fundManagerState$.asObservable();
  }

  /**
   * Fetch the full compare info for the funds available in local storage and initialize state.
   * @param selectedFundIds selected fund id list returned by add funds modal
   */
  async populate(selectedFundIds?: FundId[]): Promise<void> {
    // Re-initialize to avoid pushing of new data to existing one. This might be case when navigated from listing page.
    // This will also satisfy the condition for new funds added.
    // As, navigation will load 'populate' like an initial call without 'selectedFundIds'.
    // And, if new funds added, i.e., on same page with new data, then only a push event required instead of fresh bindings.
    if (!selectedFundIds) {
      this.state.fundsData = [];
    }

    try {
      const fundCompareDetails: FundCompareDetail[] = await this.retrieveFundDetailListFromLocalStorage();

      if (fundCompareDetails?.length || selectedFundIds) {
        let queryableFundIds: FundId[];

        // Fetch the fund ids to be queried for details from API calls.
        if (selectedFundIds) {
          queryableFundIds = this.handleNewFunds(
            fundCompareDetails?.length ? fundCompareDetails : [],
            selectedFundIds
          );
        } else {
          queryableFundIds = fundCompareDetails.map(
            (fundCompareDetail) => fundCompareDetail.fundId
          );
        }

        forkJoin([
          this.fetchPPSSDetailsFromPDS(),
          this.fetchFundDetailsFromPDS(queryableFundIds),
          this.fetchFundDetailsFromBR(queryableFundIds),
        ]).subscribe(
          (fundsMetaData) => {
            logger.debug('Fund details for comparison: ', fundsMetaData);

            this.mapState(fundCompareDetails, fundsMetaData, selectedFundIds);
          },
          (error) => {
            logger.error(
              'Error in fetching fund details for comparison: ',
              error
            );

            this.fundCompareState$.error(error);
          }
        );
      } else {
        // When no compare data present, only implement add funds modal data.
        this.fetchPPSSDetailsFromPDS().subscribe((ppssData) => {
          this.ppssData = ppssData;

          this.state.addFundsData = this.createAddFundsData([], ppssData);
          this.state.isLoaded = true;

          this.fundCompareState$.next(this.state);
        });
      }
    } catch (error) {
      logger.error(
        'Error in fetching fund compare ids from local storage: ',
        error
      );
    }
  }


  loadfunds(selectedFundIds) {
    forkJoin([
      this.fetchPPSSDetailsFromPDS(),
      this.fetchFundDetailsFromPDS(selectedFundIds),
      this.fetchFundDetailsFromBR(selectedFundIds),
    ]).subscribe(
      (fundsMetaData) => {
        logger.debug('Fund details for comparison: ', fundsMetaData);
        this.mapStateManagerList([], fundsMetaData, selectedFundIds);
      },
      (error) => {
        logger.error(
          'Error in fetching fund details for comparison: ',
          error
        );

        this.fundManagerState$.error(error);
      }
    );
  }

  /**
   * Extract new funds added from add fund modal.
   * Save the new fund list in local storage and return the same.
   * @param storedFundCompareDetails stored fund comparison details in local storage
   * @param selectedFundIds selected fund id list returned by add funds modal
   */
  private handleNewFunds(
    storedFundCompareDetails: FundCompareDetail[],
    selectedFundIds: FundId[]
  ): FundId[] {
    const storedFundIds: FundId[] = storedFundCompareDetails.map(
      (fundCompareDetail) => fundCompareDetail.fundId
    );
    const newFundIds: FundId[] = [];

    selectedFundIds.forEach((selectedFundId) => {
      if (!storedFundIds.includes(selectedFundId)) {
        newFundIds.push(selectedFundId);
      }
    });

    // Add the new fund(s) to local storage as well.
    this.updateFundDetailsToLocalStorage(
      newFundIds.map((newFundId) => {
        return {
          fundId: newFundId,
          fundName: this.ppssData.find(
            (ppssRecord) => ppssRecord.fundId === newFundId
          ).fundName,
        } as FundCompareDetail;
      }),
      StorageOperation.SET
    );

    return newFundIds;
  }

  /**
   * Fetch the full product listing for product type as mutual fund.
   * Return the cached listing, if present already.
   */
  private fetchPPSSDetailsFromPDS(): Observable<Product[]> {
    if (this.ppssData) {
      return of(this.ppssData);
    }

    return this.ppssFundDataService
      .fetchData(FundComparePPSSQuery, {
        productType: ProductTypeParameter.MUTUAL_FUNDS, // Intentionally done, as same is the only product type for India site.
      })
      .pipe(map((fundListing: FundListing) => fundListing.products));
  }

  /**
   * Fetch the product and portfolio data for all funds stored in local storage from PDS.
   * @param fundIds retrieved fund ids from local storage
   */
  private fetchFundDetailsFromPDS(
    fundIds: FundId[]
  ): Observable<[Product, Product][]> {
    return zip(
      ...fundIds.map((fundId) =>
        forkJoin([
          this.fundDetailService.register(FundCompareProductDetailsQuery, {
            fundId,
          }),
          this.fundPortfolioService.register(FundComparePortfolioQuery, {
            fundId,
          }),
        ])
      )
    );
  }

  /**
   * Fetch the content details for all funds stored in local storage from Bloomreach.
   * @param fundIds retrieved fund ids from local storage
   */
  private fetchFundDetailsFromBR(fundIds: FundId[]): Observable<Page[]> {
    return zip(
      ...fundIds.map((fundId) =>
        from(
          initialize({
            ...this.getBRPageConfiguration(
              this.siteConfigService.getFundLink(fundId)
            ),
          })
        )
      )
    );
  }

  /**
   * Constructs the configuration object along with target URL.
   * To be used for fetching Page object by initializing the returned object from this method.
   * @param resourceAPIURL target content URL
   */
  private getBRPageConfiguration(resourceAPIURL: string): Configuration {
    return {
      ...this.appState.getBrConfiguration(),
      request: {path: resourceAPIURL},
    };
  }

  /**
   * Extract the fund information and assign them to the state accordingly.
   * @param fundCompareDetails fund compare details received from local storage
   * @param fundsMetaData complete metadata retrieved from PDS and BR for each fund
   * @param selectedFundIds selected fund id list returned by add funds modal, if present
   */
  private mapState(
    fundCompareDetails: FundCompareDetail[],
    fundsMetaData: [Product[], [Product, Product][], Page[]],
    selectedFundIds?: FundId[]
  ): void {
    const [ppssData, pdsData, brPageData] = fundsMetaData;

    // Save the ppssData for future, if not cached already.
    // To be used in saving the fund in local storage when add fund action happens.
    if (!this.ppssData) {
      this.ppssData = ppssData;
    }

    // Create add funds data for modal.
    // If new fund added, i.e., not an initial call and modal data exist.
    // So, only save new fund id list returned.
    // Else, create the add fund data accordingly.
    if (selectedFundIds) {
      this.state.addFundsData.selectedFundIds = selectedFundIds;
    } else {
      this.state.addFundsData = this.createAddFundsData(
        fundCompareDetails,
        ppssData
      );
    }

    // Map PDS data to respective fields in state.
    if (pdsData?.length) {
      pdsData.forEach((fundData, index) => {
        const fundDetail: Product = fundData[0]; // First collection is product detail.
        const fundPortfolio: Product = fundData[1]; // Second collection is product portfolio.

        const primaryShareClass: ShareClass = fundDetail.shareClasses?.find(
          (shareClass) => shareClass.primaryShareClass
        );
        // For India site, 'performance' happens to be that 'monthly' record which has "calc name" as 'NET'.
        const fundPerformance: PerformanceDetail = primaryShareClass?.performance?.monthEnd?.find(
          (perfRecord) => perfRecord.calcTypeStd.toUpperCase() === 'NET'
        );
        // Fetch only those shareClasses's distribution, which have its data as non-empty.
        const shareClassesWithDividends: ShareClass[] = fundDetail.shareClasses?.filter(
          (shareClass) => shareClass.distribution?.length
        );
        // Fetch the page content data from 'contentRef' extracted from models of target component.
        // Declared 'FundCompareContentDataDTO' to satisfy properties extraction in state afterwards.
        let contentData: FundCompareContentDataDTO;
        try {
          contentData = brPageData[index]
            .getContent(
              brPageData[index]
                .getComponent('main-content')
                .getChildren()[0]
                ?.getModels().documents
            )
            .getData() as any; // Intentionally done 'any' as return type 'ContentModel' isn't exported in BR SDK.
          // And, moreover if declare it in local, then also, it will not satisfy property matching dynamically.
        } catch (error) {
          logger.error(`Error in fetching the content data: `, error);
        }
        // Extract the various information and assign & push the same in state accordingly.
        this.state.fundsData.push({
          fundId: fundDetail.fundId,
          fundName: fundDetail.fundName,
          fundLink: this.siteConfigService.getFundLink(
            fundDetail.fundId,
            TabName.OVERVIEW,
            fundDetail.fundName
          ),
          fundInceptionDate: fundDetail.fundInceptionDate,
          assetClass: fundDetail.assetClass,
          invfocus: fundDetail.investmentFocus,
          minimumInvestment: primaryShareClass?.minimumInvestment,
          additionalInvestment: primaryShareClass?.additionalInvestment,
          performanceData: fundPerformance
            ? {
              asOfDate: fundPerformance.performanceAsOfDate,
              averageAnnualReturnFor1Year:
              fundPerformance.avgAnnual1YearReturn,
              averageAnnualReturnFor3Year:
              fundPerformance.avgAnnual3YearReturn,
              averageAnnualReturnFor5Year:
              fundPerformance.avgAnnual5YearReturn,
              averageAnnualReturnFor10Year:
              fundPerformance.avgAnnual10YearReturn,
              averageAnnualReturnSinceInception:
              fundPerformance.avgAnnualSinceInceptionReturn,
            }
            : null,
          portfolioData: fundPortfolio
            ? {
              equityExposureData: fundPortfolio.portfolio.assetAllocation
                ? (fundPortfolio.portfolio
                  .assetAllocation as AssetAllocation[]).map(
                  (assetAllocation) => {
                    return {
                      name: assetAllocation.assetAllocationCategory,
                      valueAsPercentage: assetAllocation.breakdownPercent
                        ? `${assetAllocation.breakdownPercent}%`
                        : assetAllocation.breakdownPercent,
                    };
                  }
                )
                : null,
              topTenHoldings:fundPortfolio.portfolio.topTenHoldings,
              sectorAllocation:fundPortfolio.portfolio.sectorAllocation,
              turnoverRatio: fundPortfolio.portfolio.portfoliochars
                ? fundPortfolio.portfolio.portfoliochars.find(
                  // To-Do: Assign valid elementName for comparison once available in PDS.
                  (characteristics) => characteristics.elementName === ''
                )?.elementValueLocal
                : null,
            }
            : null,
          chargesData: primaryShareClass
            ? {
              expenseRatio: primaryShareClass.charges?.expenseRatioGross,
              expenseRatioDirect: primaryShareClass.charges?.expenseRatioNet,
            }
            : null,
          dividendData: shareClassesWithDividends.length
            ? shareClassesWithDividends.map((shareClass) => {
              // Fetched first record, since, only one record is to be displayed per distribution object for eligible share classes.
              const dividendData = shareClass.distribution[0];

              return {
                planName: shareClass.shareClassName,
                recordDate: dividendData.incomeDistributionRecordDate,
                distributionAmount: dividendData.incomeDistributionAmount,
                recordNav: dividendData.recordDateNavVal,
                exDividendDate: dividendData.incomeDistributionExDividendDate,
                exDividendNav: dividendData.exDividendNavValue,
              };
            })
            : null,
          aumData: fundDetail.aum
            ? {
              asOfDate: fundDetail.aum.asOfDate,
              totalNetAssets: fundDetail.aum.totalNetAssets,
            }
            : null,
          contentData: contentData
            ? {
              fundDescription: contentData.funddescription,
              riskType: contentData.fundrisktype,
              taxImplication: contentData.fundtaximplication,
              schemeType: contentData.typeofscheme,
              entryLoad: contentData.entryload,
              exitLoad: contentData.exitload,
              suitableFor: contentData.fundsuitablefor,
              productLabel: contentData.productlabel,
              uniqueInvestors: contentData.funduniqueinvestors,
            }
            : null,
          performancePeriodDropdownItems: this.fundListingService.createPPSSPeriodDropdownItems(
            fundPerformance
          ),
        });
      });
      // For all successful assignments, mark acknowledgment.
      this.state.isLoaded = true;
    } else {
      // For no assignments, mark it false;
      this.state.isLoaded = false;
    }

    // Finally, publish the state.
    this.fundCompareState$.next(this.state);
  }

  private mapStateManagerList(
    fundCompareDetails: FundCompareDetail[],
    fundsMetaData: [Product[], [Product, Product][], Page[]],
    selectedFundIds?: FundId[]
  ): void {
    const [ppssData, pdsData, brPageData] = fundsMetaData;
    this.state.fundsData = [];

    // Save the ppssData for future, if not cached already.
    // To be used in saving the fund in local storage when add fund action happens.
    if (!this.ppssData) {
      this.ppssData = ppssData;
    }

    // Map PDS data to respective fields in state.
    if (pdsData?.length) {
      pdsData.forEach((fundData, index) => {
        const fundDetail: Product = fundData[0]; // First collection is product detail.
        const fundPortfolio: Product = fundData[1]; // Second collection is product portfolio.

        const primaryShareClass: ShareClass = fundDetail.shareClasses?.find(
          (shareClass) => shareClass.primaryShareClass
        );
        // For India site, 'performance' happens to be that 'monthly' record which has "calc name" as 'NET'.
        const fundPerformance: PerformanceDetail = primaryShareClass?.performance?.monthEnd?.find(
          (perfRecord) => perfRecord.calcTypeStd.toUpperCase() === 'NET'
        );
        // Fetch only those shareClasses's distribution, which have its data as non-empty.
        const shareClassesWithDividends: ShareClass[] = fundDetail.shareClasses?.filter(
          (shareClass) => shareClass.distribution?.length
        );
        // Fetch the page content data from 'contentRef' extracted from models of target component.
        // Declared 'FundCompareContentDataDTO' to satisfy properties extraction in state afterwards.
        let contentData: FundCompareContentDataDTO;
        try {
          contentData = brPageData[index]
            .getContent(
              brPageData[index]
                .getComponent('main-content')
                .getChildren()[0]
                ?.getModels().documents
            )
            .getData() as any; // Intentionally done 'any' as return type 'ContentModel' isn't exported in BR SDK.
          // And, moreover if declare it in local, then also, it will not satisfy property matching dynamically.
        } catch (error) {
          logger.error(`Error in fetching the content data: `, error);
        }
        // Extract the various information and assign & push the same in state accordingly.
        this.state.fundsData.push({
          fundId: fundDetail.fundId,
          fundName: fundDetail.fundName,
          fundLink: this.siteConfigService.getFundLink(
            fundDetail.fundId,
            TabName.OVERVIEW,
            fundDetail.fundName
          ),
          fundInceptionDate: fundDetail.fundInceptionDate,
          assetClass: fundDetail.assetClass,
          invfocus: fundDetail.investmentFocus,
          minimumInvestment: primaryShareClass?.minimumInvestment,
          additionalInvestment: primaryShareClass?.additionalInvestment,
          performanceData: fundPerformance
            ? {
              asOfDate: fundPerformance.performanceAsOfDate,
              averageAnnualReturnFor1Year:
              fundPerformance.avgAnnual1YearReturn,
              averageAnnualReturnFor3Year:
              fundPerformance.avgAnnual3YearReturn,
              averageAnnualReturnFor5Year:
              fundPerformance.avgAnnual5YearReturn,
              averageAnnualReturnFor10Year:
              fundPerformance.avgAnnual10YearReturn,
              averageAnnualReturnSinceInception:
              fundPerformance.avgAnnualSinceInceptionReturn,
            }
            : null,
          portfolioData: fundPortfolio
            ? {
              equityExposureData: fundPortfolio.portfolio.assetAllocation
                ? (fundPortfolio.portfolio
                  .assetAllocation as AssetAllocation[]).map(
                  (assetAllocation) => {
                    return {
                      name: assetAllocation.assetAllocationCategory,
                      valueAsPercentage: assetAllocation.breakdownPercent
                        ? `${assetAllocation.breakdownPercent}%`
                        : assetAllocation.breakdownPercent,
                    };
                  }
                )
                : null,
              topTenHoldings:fundPortfolio.portfolio.topTenHoldings,
              sectorAllocation:fundPortfolio.portfolio.sectorAllocation,
              turnoverRatio: fundPortfolio.portfolio.portfoliochars
                ? fundPortfolio.portfolio.portfoliochars.find(
                  // To-Do: Assign valid elementName for comparison once available in PDS.
                  (characteristics) => characteristics.elementName === ''
                )?.elementValueLocal
                : null,
            }
            : null,
          chargesData: primaryShareClass
            ? {
              expenseRatio: primaryShareClass.charges?.expenseRatioGross,
              expenseRatioDirect: primaryShareClass.charges?.expenseRatioNet,
            }
            : null,
          dividendData: shareClassesWithDividends.length
            ? shareClassesWithDividends.map((shareClass) => {
              // Fetched first record, since, only one record is to be displayed per distribution object for eligible share classes.
              const dividendData = shareClass.distribution[0];

              return {
                planName: shareClass.shareClassName,
                recordDate: dividendData.incomeDistributionRecordDate,
                distributionAmount: dividendData.incomeDistributionAmount,
                recordNav: dividendData.recordDateNavVal,
                exDividendDate: dividendData.incomeDistributionExDividendDate,
                exDividendNav: dividendData.exDividendNavValue,
              };
            })
            : null,
          aumData: fundDetail.aum
            ? {
              asOfDate: fundDetail.aum.asOfDate,
              totalNetAssets: fundDetail.aum.totalNetAssets,
            }
            : null,
          contentData: contentData
            ? {
              fundDescription: contentData.funddescription,
              riskType: contentData.fundrisktype,
              taxImplication: contentData.fundtaximplication,
              schemeType: contentData.typeofscheme,
              entryLoad: contentData.entryload,
              exitLoad: contentData.exitload,
              suitableFor: contentData.fundsuitablefor,
              productLabel: contentData.productlabel,
              uniqueInvestors: contentData.funduniqueinvestors,
            }
            : null,
          performancePeriodDropdownItems: this.fundListingService.createPPSSPeriodDropdownItems(
            fundPerformance
          ),
        });
      });
    }
    this.fundManagerState$.next(this.state.fundsData);
  }

  /**
   * Extract details and create the add funds modal data.
   * @param fundCompareDetails fund for comparison from local storage
   * @param ppssData ppss data listing from PDS
   */
  private createAddFundsData(
    fundCompareDetails: FundCompareDetail[],
    ppssData: Product[]
  ): AddFundsModal {
    // Extract the stored fund id from local storage for comparison.
    const storedFundIds: string[] = fundCompareDetails.map(
      (fundCompareDetail) => fundCompareDetail.fundId
    );

    // Create the fund list mapping from ppss listing as required.
    const fundList: AddFundsList[] = ppssData.map((ppssRecord) => {
      return {
        fundId: ppssRecord.fundId,
        fundName: ppssRecord.fundName,
        assetClass: ppssRecord.assetClass,
      };
    });

    // Create the sorted distinct asset filter drop down items.
    // And, push the first option as 'All'.
    const assetClassFilterItems: DropdownItem[] = [
      {
        label: this.translateService.instant('products.ppss-filter-type-all'),
        value: 'all',
        selected: true,
      },
      ...(uniq(
        ppssData
          .map((ppssRecord) => ppssRecord.assetClass)
          .sort((a, b) => a.localeCompare(b))
      ) as string[]).map((assetClass) => {
        return {
          label: assetClass,
          value: assetClass,
        };
      }),
    ];

    // Finally, create the add funds data for a modal window.
    return {
      fundList,
      addFundsButtonlabel: this.translateService.instant(
        'products.add-fund-button-label'
      ),
      modalTitle: this.translateService.instant(
        'products.add-fund-modal-title'
      ),
      submitLabel: this.translateService.instant(
        'products.add-fund-submit-label'
      ),
      resetlabel: this.translateService.instant(
        'products.add-fund-reset-label'
      ),
      maxLimit: 3,
      maxLimitExceedMessage: this.translateService.instant(
        'products.add-fund-max-limit-message'
      ),
      selectedFundIds: storedFundIds,
      assetClassFilter: {
        filterLabel: this.translateService.instant(
          'products.add-fund-filter-label'
        ),
        filterName: this.translateService.instant(
          'products.add-fund-filter-label'
        ),
        filterItems: assetClassFilterItems,
      },
      searchInput: {
        label: this.translateService.instant(
          'products.add-fund-search-input-label'
        ),
        name: this.translateService.instant(
          'products.add-fund-search-input-label'
        ),
        placeholder: this.translateService.instant(
          'products.add-fund-search-input-placeholder'
        ),
      },
    };
  }

  /**
   * Handle subsequent action events in state and local storage, when fund is removed from comparison table.
   * @param fundIndex column index of the fund
   * @param fundCompareDetail fund detail of the fund removed
   */
  handleRemoveFundAction(
    fundIndex: number,
    fundCompareDetail: FundCompareDetail
  ): void {
    // Remove the fund from comparison list in state.
    this.state.fundsData.splice(fundIndex, 1);

    // Then remove it from local storage as well.
    this.updateFundDetailsToLocalStorage(
      [fundCompareDetail],
      StorageOperation.REMOVE
    );
  }


  addToCompareList(fund: FundCompareItem): void {
    const index = this.compareList.findIndex((item) => item.fundId === fund.fundId);

    if (index === -1) {
      // Add to compareList
      this.compareList.push(fund);
    } else {
      // Remove from compareList
      this.compareList.splice(index, 1);
    }
    this.updateLocalStorage();
    this.updateCompareListCount();
  }
  
  getCompareList(): any[] {
    return this.compareList;
  }

  private updateLocalStorage(): void {
    localStorage.setItem('compare-funds', JSON.stringify(this.compareList));
  }

  getCompareListCount(): number {
    return this.compareList.length;
  }

  private updateCompareListCount(): void {
    const count = this.getCompareListCount();
    this.compareListCountSubject.next(count);
  }
  isFundInCompareList(fund: any): boolean {
    return this.compareList.some((item) => item.fundId === fund.fundId);
  }

  private loadComparelistFromLocalStorage(): void {
    const storedCompareList = localStorage.getItem('compare-funds');
    if (storedCompareList) {
      this.compareList = JSON.parse(storedCompareList);
      this.updateCompareListCount();
    }
  }

}
