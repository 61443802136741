import { AddFundsList } from '@products/types/add-funds.type';
import {
  FundId,
  FundListingDataFormat,
  FundListingFilterDropdown,
  FundListingPPSSState,
} from '../types';

export interface WatchList {
  fundId?: FundId;
  fundName?: string;
}

export interface WatchListState {
  assetClassFilterDropdown?: FundListingFilterDropdown;
  ppssState?: FundListingPPSSState;
  watchlistData?: FundListingDataFormat[];
  addFundList: AddFundsList[];
  isLoaded?: boolean;
  selectedFundIds: string[];
}

export enum StorageOperation {
  'SET',
  'REMOVE',
}

export interface WatchListToastAction {
  type: WatchListActionType;
  message?: string;
}

export enum WatchListActionType {
  'ADDED',
  'ALREADY_EXIST',
  'REMOVED',
  'LIMIT_REACHED',
  'ERROR',
}

export interface WatchListItem {
  fundId: string;
  fundName: string;
  assetClass: string;
}